import {
  CART_ORDER,
  CLEAR_CART_ORDER,
  CART_FORM,
  CLEAR_CART_FORM,
} from '../../actions/cart/cart';

const initialState = {
  error: null,
  loaded: false,
  loading: false,
  formData: {},
};

export default function cart(state = initialState, action = {}) {
  switch (action.type) {
    case `${CART_ORDER}_PENDING`:
      return {
        ...state,
        error: null,
        loading: true,
        loaded: false,
      };
    case `${CART_ORDER}_SUCCESS`:
      return {
        ...state,
        error: null,
        loaded: true,
        loading: false,
      };
    case `${CART_ORDER}_FAIL`:
      return {
        ...state,
        error: action.error,
        loading: false,
        loaded: false,
      };
    case `${CLEAR_CART_ORDER}`:
      return {
        ...state,
        error: null,
        loaded: false,
        loading: false,
      };
    case `${CART_FORM}`:
      return {
        ...state,
        formData: { ...state.formData, ...action.formData },
      };
    case `${CLEAR_CART_FORM}`:
      return {
        ...state,
        formData: {},
      };
    default:
      return state;
  }
}
