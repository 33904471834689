import React from 'react';
import { injectLazyLibs } from '@plone/volto/helpers/Loadable/Loadable';
import { Icon } from '@plone/volto/components';

import downSVG from '@package/icons/triangle-down.svg';
import upSVG from '@package/icons/triangle-up.svg';
import checkSVG from '@plone/volto/icons/check.svg';

export const Option = injectLazyLibs('reactSelect')((props) => {
  const { Option } = props.reactSelect.components;
  return (
    <Option {...props}>
      <div>{props.label}</div>
      {props.isFocused && !props.isSelected && (
        <Icon name={checkSVG} size="18px" color="#BEBEBE" />
      )}
      {props.isSelected && <Icon name={checkSVG} size="18px" color="#BEBEBE" />}
    </Option>
  );
});

export const DropdownIndicator = injectLazyLibs('reactSelect')((props) => {
  const { DropdownIndicator } = props.reactSelect.components;
  return (
    <DropdownIndicator {...props}>
      {props.selectProps.menuIsOpen ? (
        <Icon name={upSVG} size="10px" color="#BEBEBE" />
      ) : (
        <Icon name={downSVG} size="10px" color="#BEBEBE" />
      )}
    </DropdownIndicator>
  );
});

export const Group = injectLazyLibs('reactSelect')((props) => {
  const { Group } = props.reactSelect.components;
  return <Group {...props}></Group>;
});

export const selectTheme = (theme) => ({
  ...theme,
  borderRadius: 0,
  colors: {
    ...theme.colors,
    primary25: 'hotpink',
    primary: '#b8c6c8',
  },
});

export const customSelectStyles = {
  control: (styles, state) => ({
    ...styles,
    border: 'none',
    boxShadow: 'none',
    // height: '80px',
    minHeight: '80px',
    backgroundColor: '#ebebeb',
    padding: '10px',
  }),
  container: (styles, state) => ({
    ...styles,
    width: '100%',
    marginRight: '3px',
  }),
  placeholder: (styles, state) => ({
    ...styles,
    color: '#555555',
    fontSize: '18px',
    lineHeight: '40px',
  }),
  menu: (styles, state) => ({
    ...styles,
    top: null,
    marginTop: 0,
    boxShadow: 'none',
    zIndex: 2,
  }),
  indicatorSeparator: (styles) => ({
    ...styles,
    width: null,
  }),
  valueContainer: (styles) => ({
    ...styles,
    paddingLeft: 0,
    overflowY: 'scroll',
    height: '100%',
    display: 'flex',
  }),
  dropdownIndicator: (styles) => ({
    paddingRight: 0,
    marginTop: '5px',
  }),
  clearIndicator: (styles) => ({
    marginTop: '9px',
    marginRight: '10px',
  }),
  option: (styles, state) => ({
    ...styles,
    backgroundColor: '#ebebeb',
    minHeight: '50px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '12px 12px',
    overflowX: 'hidden',
    overflowWrap: 'anywhere',
    color: state.isSelected
      ? '#007bc1'
      : state.isFocused
      ? '#4a4a4a'
      : 'inherit',
    ':active': {
      backgroundColor: null,
    },
    svg: {
      flex: '0 0 auto',
    },
  }),
  input: (styles, state) => ({
    ...styles,
    fontSize: '20px',
  }),
  multiValue: (styles, state) => ({
    ...styles,
    fontSize: '20px',
    backgroundColor: '#023d6b;',
    color: '#fff',
  }),
  multiValueLabel: (styles, state) => ({
    ...styles,
    fontSize: '14px',
    color: '#fff',
  }),
  menuList: (styles, state) => ({
    ...styles,
    paddingTop: 0,
  }),
};
