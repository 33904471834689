class CartController {
  items = [];

  constructor({ onLoad, onDump }) {
    this.onLoad = onLoad;
    this.onDump = onDump;
  }

  setItems(items) {
    this.items = items;
    return this;
  }

  changed() {
    return new CartController({
      onLoad: this.onLoad,
      onDump: this.onDump,
    }).setItems(this.items);
  }

  add(item) {
    this.items.push(item);
    this.dump();
    return this.changed();
  }

  del(id) {
    this.items = this.items.filter((item) => item.id !== id);
    this.dump();
    return this.changed();
  }

  set(newItem) {
    const index = this.items.findIndex((item) => item.id === newItem.id);
    if (index !== -1) {
      this.items[index] = newItem;
      this.dump();
      return this.changed();
    } else {
      return this;
    }
  }

  addDefault(id) {
    const index = this.items.findIndex((item) => item.id === id);
    if (index === -1) {
      return this.add({ id, amount: 1 });
    } else {
      return this;
    }
  }

  dump() {
    if (this.onDump) {
      this.onDump(this.items);
    }
  }

  load() {
    if (this.onLoad) {
      this.items = this.onLoad();
    } else {
      this.items = [];
    }
    return this;
  }
}

export default CartController;
