import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { Container } from 'semantic-ui-react';
import { flattenToAppURL } from '@plone/volto/helpers';
import { Icon } from '@plone/volto/components';

import memberSVG from '../../icons/fallback-avatar-darkblue.svg';
import globeSVG from '../../icons/globe.svg';
import awardSVG from '../../icons/award.svg';

const mapMonth = (month) =>
  ({
    ['01' || '1' || 'Januar']: 'January',
    ['02' || '2' || 'Februar']: 'February',
    ['03' || '3' || 'März']: 'March',
    ['04' || '4']: 'April',
    ['05' || '5' || 'Mai']: 'May',
    ['06' || '6' || 'Juni']: 'June',
    ['07' || '7' || 'Juli']: 'July',
    ['08' || '8']: 'August',
    ['09' || '9']: 'September',
    ['10' || 'Oktober']: 'October',
    '11': 'November',
    ['12' || 'Dezember']: 'December',
  }[month] || month);

const AwardView = (props) => {
  const { content, intl } = props;

  const AwardTopline = (item) => {
    const { award } = item;
    return (
      <div className="topline">
        {award?.location && <span className="location">{award.location},</span>}
        {award?.award_date_month && (
          <span className="month">
            <FormattedMessage id={mapMonth(award.award_date_month)} />
          </span>
        )}
        {award?.award_date_year && (
          <span className="year">{award.award_date_year}</span>
        )}
        {award?.award_type &&
          (award?.location ||
            award?.award_date_month ||
            award?.award_date_year) && <span className="divider">|</span>}
        {award?.award_type && (
          <span className="type">
            {intl.locale === 'de' && award.award_type.title === 'Misc'
              ? 'Sonstige'
              : award.award_type.title}
          </span>
        )}
      </div>
    );
  };

  return (
    <Container className="award">
      <div className="award-view">
        <div className="overlay">
          <div className="inner">
            <div className="icon-wrapper">
              <Icon name={awardSVG} size="143px" />
            </div>
            <AwardTopline award={content} />
            <h1>{content.title}</h1>
            <div className="member-container">
              {content.members.map((member) => (
                <div className="member">
                  <div className="icon-wrapper">
                    {member.image ? (
                      <img
                        src={flattenToAppURL(
                          `${member['@id']}/@@images/image/mini`,
                        )}
                        alt={`${member?.firstname} ${member?.name}`}
                      />
                    ) : (
                      <Icon name={memberSVG} size="80px" />
                    )}
                  </div>
                  <Link to={flattenToAppURL(member?.['@id'])} className="name">
                    {member?.firstname || member?.name
                      ? `${member?.academic ? member.academic + ' ' : ''} ${
                          member?.firstname ? member.firstname + ' ' : ''
                        } ${member?.name ? member.name : ''}`
                      : member?.title}
                  </Link>
                </div>
              ))}
            </div>

            <div className="description">
              <p>{content?.description}</p>
            </div>
            {content?.website_link && (
              <div className="website">
                <div className="icon-wrapper">
                  <a
                    href={flattenToAppURL(content?.website_link)}
                    aria-label="website"
                  >
                    <Icon name={globeSVG} size="30px" />
                  </a>
                </div>
                <a
                  className="visit"
                  href={flattenToAppURL(content?.website_link)}
                >
                  <FormattedMessage
                    id="Find more information here"
                    defaultMessage="Find more information here"
                  />
                </a>
              </div>
            )}
          </div>
        </div>
      </div>
    </Container>
  );
};

export default injectIntl(AwardView);
