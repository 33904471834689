import React from 'react';
import { SidebarPortal } from '@plone/volto/components';
import Sidebar from './Sidebbar';
import View from './View';

const TeaserTabEdit = (props) => {
  return (
    <>
      <View {...props} isEditMode />
      <SidebarPortal selected={props.selected}>
        <Sidebar {...props} />
      </SidebarPortal>
    </>
  );
};

export default TeaserTabEdit;
