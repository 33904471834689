import React from 'react';
import PropTypes from 'prop-types';
// import InlineForm from '@plone/volto/components/manage/Form/InlineForm';
import { SchemaRenderer } from '@kitconcept/volto-blocks/components';
import { useIntl } from 'react-intl';
import { TeaserIconSchema } from './schema';
import withObjectBrowser from '@plone/volto/components/manage/Sidebar/ObjectBrowser';

const TeaserIconSidebar = (props) => {
  const { schemaEnhancer } = props;

  const intl = useIntl();
  const schema = schemaEnhancer
    ? schemaEnhancer(TeaserIconSchema({ ...props, intl }), props)
    : TeaserIconSchema({ ...props, intl });

  return (
    <SchemaRenderer
      schema={schema}
      title={schema.title}
      onChangeField={(id, value) => {
        props.onChangeBlock(props.block, {
          ...props.data,
          [id]: value,
        });
      }}
      formData={props.data}
      block={props.block}
    />
  );
};

TeaserIconSidebar.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  block: PropTypes.string.isRequired,
  onChangeBlock: PropTypes.func.isRequired,
};

export default withObjectBrowser(TeaserIconSidebar);
