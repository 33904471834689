/**
 * Breadcrumbs reducer.
 * @module reducers/breadcrumbs/breadcrumbs
 */

import { GET_SEARCHSUGGESTIONS } from '@package/constants/ActionTypes';

const initialState = {
  error: null,
  items: [],
  loaded: false,
  loading: false,
};

/**
 * Breadcrumbs reducer.
 * @function breadcrumbs
 * @param {Object} state Current state.
 * @param {Object} action Action to be handled.
 * @returns {Object} New state.
 */
export default function searchSuggestions(state = initialState, action = {}) {
  switch (action.type) {
    case `${GET_SEARCHSUGGESTIONS}_PENDING`:
      return {
        ...state,
        error: null,
        loaded: false,
        loading: true,
      };
    case `${GET_SEARCHSUGGESTIONS}_SUCCESS`:
      return {
        ...state,
        error: null,
        items: action.result.suggestions,
        loaded: true,
        loading: false,
      };
    case `${GET_SEARCHSUGGESTIONS}_FAIL`:
      return {
        ...state,
        error: action.error,
        items: [],
        loaded: false,
        loading: false,
      };
    default:
      return state;
  }
}
