import React from 'react';
import { defineMessages, injectIntl } from 'react-intl';
import { getTeaserImageURL } from './utils';
import { flattenToAppURL, isInternalURL } from '@plone/volto/helpers';
import { MaybeWrap, UniversalLink } from '@plone/volto/components';
import teaserHeroTopTemplate from '@kitconcept/volto-blocks/components/TeaserHero/teaserhero-top-template.svg';
import { Message } from 'semantic-ui-react';

const messages = defineMessages({
  PleaseChooseContent: {
    id: 'Please choose an existing content as source for this element',
    defaultMessage:
      'Please choose an existing content as source for this element',
  },
  moreInfo: {
    id: 'moreInfo',
    defaultMessage: 'More info',
  },
});

const SliderBody = (props) => {
  const { data, isEditMode } = props;
  const href = data.href?.[0];
  const image = data.preview_image?.[0];

  return (
    <div className="ui container slider-body">
      {!href && isEditMode && (
        <Message>
          <div className="grid-teaser-item default">
            <img src={teaserHeroTopTemplate} alt="" />
            <p>{props.intl.formatMessage(messages.PleaseChooseContent)}</p>
          </div>
        </Message>
      )}
      {href && (
        <div>
          <div className="slider-container">
            <MaybeWrap
              condition={!isEditMode}
              as={UniversalLink}
              href={href ? href['@id'] : '#'}
              target={isInternalURL(href['@id']) ? null : '_blank'}
            >
              {(href?.hasPreviewImage || image) && (
                <div className="institut slider image">
                  <img
                    src={flattenToAppURL(getTeaserImageURL(href, image))}
                    alt=""
                    loading="lazy"
                  />
                </div>
              )}
              <div className="content">
                <div className="title">
                  {href?.head_title && (
                    <span className="supertitle">{href?.head_title}</span>
                  )}
                  {isInternalURL(href['@id']) ? (
                    <h3>{data?.nav_title || data?.title}</h3>
                  ) : (
                    <h3 className="after">{data?.nav_title || data?.title}</h3>
                  )}
                </div>
                <p>{data?.description}</p>
              </div>
            </MaybeWrap>
          </div>
        </div>
      )}
    </div>
  );
};

export default injectIntl(SliderBody);
