import React, { useState } from 'react';
import cx from 'classnames';
import { TextBlockView } from 'volto-slate/blocks/Text';

const View = (props) => {
  const { data } = props;
  const [currentTab, setCurrentTab] = useState(0);
  return (
    <div className="block tab">
      {data.headline && <h2 className="headline">{data.headline}</h2>}
      {data.columns?.length > 0 && (
        <div className="content">
          <div className="tabs-wrapper">
            {data.columns &&
              data.columns.map((item, index) => (
                <div
                  className={cx('tabulator', { active: currentTab === index })}
                  onClick={() => {
                    setCurrentTab(index);
                  }}
                  onKeyDown={() => {
                    setCurrentTab(index);
                  }}
                  role="button"
                  tabIndex={0}
                >
                  {item?.title}
                </div>
              ))}
          </div>
          {data.columns &&
            data.columns.map((item, index) => (
              <div
                className={cx('text-body', { active: currentTab === index })}
              >
                <TextBlockView
                  {...props}
                  data={{
                    ...props.data,
                    value:
                      props.data &&
                      props.data[`text-${index}`] &&
                      props.data[`text-${index}`].value,
                    plaintext:
                      props.data &&
                      props.data[`text-${index}`] &&
                      props.data[`text-${index}`].plaintext,
                  }}
                />
              </div>
            ))}
        </div>
      )}
    </div>
  );
};

export default View;
