import { defineMessages } from 'react-intl';
import { flattenToAppURL, isInternalURL } from '@plone/volto/helpers';
import clearSVG from '@plone/volto/icons/clear.svg';
import navTreeSVG from '@plone/volto/icons/nav.svg';

const messages = defineMessages({
  title: {
    id: 'Title',
    defaultMessage: 'Title',
  },
  description: {
    id: 'Description',
    defaultMessage: 'Description',
  },
  width: {
    id: 'Width',
    defaultMessage: 'Width',
  },
  height: {
    id: 'Height',
    defaultMessage: 'Height',
  },
  image: {
    id: 'Image',
    defaultMessage: 'Image',
  },
  small: {
    id: 'Small',
    defaultMessage: 'Small',
  },
  medium: {
    id: 'Medium',
    defaultMessage: 'Medium',
  },
  large: {
    id: 'Large',
    defaultMessage: 'Large',
  },
  default: {
    id: 'Default',
    defaultMessage: 'Default',
  },
  narrow: {
    id: 'Narrow',
    defaultMessage: 'Narrow',
  },
});

export function HighlightSchema(props) {
  const { intl } = props;
  const imageUrl = isInternalURL(props.data.url)
    ? flattenToAppURL(props.data.url)
    : props.data.url;

  return {
    block: 'highlightTeaserWithoutButton',
    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: ['imageUrl', 'title', 'description', 'width', 'height'],
      },
    ],
    properties: {
      imageUrl: {
        title: intl.formatMessage(messages.image),
        value: imageUrl,
        icon: props.data.url ? clearSVG : navTreeSVG,
        iconAction: props.data.url
          ? () => {
              props.resetSubmitUrl();
              props.onChangeBlock(props.block, {
                ...props.data,
                url: '',
              });
            }
          : () => props.openObjectBrowser(),
      },
      title: {
        title: intl.formatMessage(messages.title),
      },
      description: {
        title: intl.formatMessage(messages.description),
        widget: 'textarea',
      },
      width: {
        title: intl.formatMessage(messages.width),
        choices: [
          ['100%', '100%'],
          ['default', `${intl.formatMessage(messages.default)}`],
          ['narrow', `${intl.formatMessage(messages.narrow)}`],
        ],
        default: 'default',
      },
      height: {
        title: intl.formatMessage(messages.height),
        choices: [
          ['325px', `${intl.formatMessage(messages.small)}`],
          ['600px', `${intl.formatMessage(messages.medium)}`],
          ['800px', `${intl.formatMessage(messages.large)}`],
        ],
        default: '800px',
      },
    },
    required: [],
  };
}
