import React from 'react';
import { Container, Message } from 'semantic-ui-react';
import { defineMessages, useIntl } from 'react-intl';
import { getTeaserImageURL } from './utils';
import { flattenToAppURL } from '@plone/volto/helpers';
import { UniversalLink } from '@plone/volto/components';
import teaserHeroTopTemplate from '@kitconcept/volto-blocks/components/TeaserHero/teaserhero-top-template.svg';
import cx from 'classnames';

import config from '@plone/volto/registry';

const messages = defineMessages({
  PleaseChooseContent: {
    id: 'Please choose an existing content as source for this element',
    defaultMessage:
      'Please choose an existing content as source for this element',
  },
});

const HighlightTeaserView = (props) => {
  const { data } = props;
  const isHiERN = config.settings.isHiERNProject === 'true' ? true : false;
  const intl = useIntl();
  const href = data.href?.[0];
  const image = data.preview_image?.[0];

  let fullWidth = true;
  let viewPortWidth = false;
  if (data.width === 'default') {
    fullWidth = true;
  }
  if (data.width === 'narrow') {
    fullWidth = false;
  }
  if (data.width === '100%') {
    fullWidth = false;
    viewPortWidth = true;
  }

  return (
    <div
      className={cx('block highlightTeaser', {
        'ui container': viewPortWidth,
        hiern: isHiERN,
      })}
    >
      {!href && (
        <Message>
          <div className="teaser-item default">
            <img src={teaserHeroTopTemplate} alt="" />
            <p>{intl.formatMessage(messages.PleaseChooseContent)}</p>
          </div>
        </Message>
      )}
      {href && (
        <div
          className={cx('teaser-item top', {
            'full-width': fullWidth,
          })}
        >
          <div className="ear-wrapper">
            <div className="ear" />
          </div>
          <div className="highlight-image-wrapper">
            <img
              style={{ height: data.height }}
              className={cx({
                'full-viewport-width': viewPortWidth,
              })}
              src={flattenToAppURL(
                getTeaserImageURL(
                  href,
                  image,
                  `${viewPortWidth ? 'huge' : 'great'}`,
                ),
              )}
              alt=""
            />
          </div>
          <div
            className={cx('teaser-item-title-wrapper', {
              'fix-width-issue-full-width-hack-absolute-full-centered-pill': fullWidth,
            })}
          >
            {!isHiERN ? (
              <Container
                className={cx('teaser-item-title', {
                  'default-size': !fullWidth,
                })}
              >
                <div className="title">
                  <h3> {data?.title}</h3>
                </div>
                <div className="divider" />
                <p>
                  <span>{data?.description}</span>
                </p>
                <div className="more-button">
                  <UniversalLink
                    href={
                      data.link && data.link[0]
                        ? data.link[0]['@id']
                        : flattenToAppURL(href['@id'])
                    }
                  >
                    {data.linkTitle ?? 'Mehr Infos'}
                  </UniversalLink>
                </div>
              </Container>
            ) : (
              <div
                className={cx('teaser-item-title', {
                  'default-size': !fullWidth,
                })}
              >
                <div className="title">
                  <h3> {data?.title}</h3>
                </div>
                <div className="divider" />
                <p>
                  <span>{data?.description}</span>
                </p>
                <div className="more-button">
                  <UniversalLink
                    href={
                      data.link && data.link[0]
                        ? data.link[0]['@id']
                        : flattenToAppURL(href['@id'])
                    }
                  >
                    {data.linkTitle ?? 'Mehr Infos'}
                  </UniversalLink>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default HighlightTeaserView;
