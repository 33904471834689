import React from 'react';
import { SidebarPortal } from '@plone/volto/components';
import HighlightTeaserData from './Data';
import HighlightTeaserView from './View';

const HighlightTeaserEdit = (props) => {
  const { selected } = props;

  return (
    <div style={{ width: '100%' }}>
      <HighlightTeaserView {...props} />
      <SidebarPortal selected={selected}>
        <HighlightTeaserData {...props} />
      </SidebarPortal>
    </div>
  );
};

export default HighlightTeaserEdit;
