import { defineMessages } from 'react-intl';

const messages = defineMessages({
  title: {
    id: 'Title',
    defaultMessage: 'Title',
  },
  item: {
    id: 'Item',
    defaultMessage: 'Item',
  },
  items: {
    id: 'Items',
    defaultMessage: 'Items',
  },
  addItem: {
    id: 'Add item',
    defaultMessage: 'Add item',
  },
  tabBlock: {
    id: 'Tab Block',
    defaultMessage: 'Tab Block',
  },
  settings: {
    id: 'Settings',
    defaultMessage: 'Settings',
  },
  headline: {
    id: 'Headline',
    defaultMessage: 'Headline',
  },
});

const itemSchema = (props) => {
  const { intl } = props;

  return {
    title: intl.formatMessage(messages.item),
    addMessage: intl.formatMessage(messages.addItem),
    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: ['title'],
      },
    ],
    properties: {
      title: {
        title: intl.formatMessage(messages.title),
      },
    },
    required: [],
  };
};
export const TabSchema = (props) => ({
  title: props.intl.formatMessage(messages.tabBlock),
  block: 'tab',
  fieldsets: [
    {
      id: 'default',
      title: 'Default',
      fields: ['columns'],
    },
    {
      id: 'settings',
      title: props.intl.formatMessage(messages.settings),
      fields: ['headline'],
    },
  ],
  properties: {
    columns: {
      widget: 'object_list',
      title: props.intl.formatMessage(messages.items),
      schema: itemSchema,
    },
    headline: {
      title: props.intl.formatMessage(messages.headline),
    },
  },
  required: [],
});
