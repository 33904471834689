import { useEffect } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';

const CartNotFoundRoute = () => {
  const history = useHistory();
  const { path } = useRouteMatch();

  useEffect(() => {
    history.push(path);
  }, [history, path]);

  return null;
};

export default CartNotFoundRoute;
