import { defineMessages } from 'react-intl';

const messages = defineMessages({
  Source: {
    id: 'Icon Link',
    defaultMessage: 'Icon Link',
  },
  icon: {
    id: 'Icon',
    defaultMessage: 'Icon',
  },
  TeaserIcon: {
    id: 'Teaser Icon',
    defaultMessage: 'Teaser Icon',
  },
  title: {
    id: 'Title',
    defaultMessage: 'Title',
  },
  item: {
    id: 'Item',
    defaultMessage: 'Item',
  },
  items: {
    id: 'Items',
    defaultMessage: 'Items',
  },
  addItem: {
    id: 'Add item',
    defaultMessage: 'Add item',
  },
});

const itemSchema = (props) => {
  const { intl } = props;

  return {
    title: intl.formatMessage(messages.item),
    addMessage: intl.formatMessage(messages.addItem),
    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: ['icon', 'title', 'href'],
      },
    ],

    properties: {
      href: {
        title: intl.formatMessage(messages.Source),
        widget: 'object_browser',
        mode: 'link',
        selectedItemAttrs: ['Title'],
        allowExternals: true,
      },
      icon: {
        title: intl.formatMessage(messages.icon),
        widget: 'object_browser',
        mode: 'image',
        allowExternals: true,
      },
      title: {
        title: intl.formatMessage(messages.title),
      },
    },
    required: [],
  };
};
export const TeaserIconSchema = (props) => ({
  title: props.intl.formatMessage(messages.TeaserIcon),
  block: 'teaserIcon',
  fieldsets: [
    {
      id: 'default',
      title: 'Default',
      fields: ['columns'],
    },
  ],
  properties: {
    columns: {
      widget: 'object_list',
      title: props.intl.formatMessage(messages.items),
      schema: itemSchema,
    },
  },
  required: [],
});
