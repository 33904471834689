import React from 'react';
import PropTypes from 'prop-types';
import { Message } from 'semantic-ui-react';
import { defineMessages, useIntl } from 'react-intl';
import teaserHeroTopTemplate from '@kitconcept/volto-blocks/components/TeaserHero/teaserhero-top-template.svg';
import { flattenToAppURL } from '@plone/volto/helpers';
import { getTeaserImageURL } from './utils';
import { Icon } from '@plone/volto/components';
import rightSVG from '@plone/volto/icons/right-key.svg';
import { UniversalLink } from '@plone/volto/components';

import cx from 'classnames';

const messages = defineMessages({
  PleaseChooseContent: {
    id: 'Please choose an existing content as source for this element',
    defaultMessage:
      'Please choose an existing content as source for this element',
  },
  more: {
    id: 'More',
    defaultMessage: 'More',
  },
});

const TeaserTabBody = ({
  data,
  dataBlock,
  isEditMode,
  mobileSize,
  ...props
}) => {
  const intl = useIntl();
  const href = data.href?.[0];
  const image = data.preview_image?.[0];
  return (
    <>
      {!href && isEditMode && (
        <Message>
          <div className="grid-teaser-item default">
            <img src={teaserHeroTopTemplate} alt="" />
            <p>{intl.formatMessage(messages.PleaseChooseContent)}</p>
          </div>
        </Message>
      )}
      {href && (
        <div className="teaser-tab grid-teaser-item top">
          <>
            {((href && href.hasPreviewImage) || image) && (
              <div
                className="grid-image-wrapper"
                style={{ marginBottom: '0px' }}
              >
                <img
                  src={flattenToAppURL(getTeaserImageURL(href, image))}
                  alt=""
                  loading="lazy"
                />
              </div>
            )}
            <div
              className={cx('title', {
                active: props.active,
                mobile: mobileSize,
              })}
            >
              {data?.title}
            </div>
            <div
              className={cx('description-container', { mobile: mobileSize })}
            >
              <>
                <p>{data.description}</p>
                <UniversalLink
                  href={href['@id'] ? flattenToAppURL(href['@id']) : ''}
                  target={data.openLinkInNewTab ? '_blank' : null}
                >
                  <div className="more-button">
                    <Icon
                      className="more-right-icon"
                      name={rightSVG}
                      size="24px"
                    />
                    {intl.formatMessage(messages.more)}
                  </div>
                </UniversalLink>
              </>
            </div>
          </>
        </div>
      )}
    </>
  );
};

TeaserTabBody.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  isEditMode: PropTypes.bool,
};

export default TeaserTabBody;
