import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Icon } from '@plone/volto/components';
import { Container, Image } from 'semantic-ui-react';
import { searchContent } from '@plone/volto/actions';
import { useDispatch, useSelector } from 'react-redux';
import { flattenToAppURL } from '@plone/volto/helpers';
import { defineMessages, useIntl } from 'react-intl';
import pdfSVG from '@package/icons/pdf.svg';

const messages = defineMessages({
  previewColumn: {
    id: 'previewColumn',
    defaultMessage: 'Preview', // Ansicht
  },
  descriptionColumn: {
    id: 'descriptionColumn',
    defaultMessage: 'Description', // Beschreibung
  },
  downloadColumn: {
    id: 'downloadColumn',
    defaultMessage: 'Download', // Download
  },
  orderInstructionsColumn: {
    id: 'orderInstructionsColumn',
    defaultMessage: 'Order Instructions', // Versandhinweise
  },
  addArticletoCart1: {
    id: 'addArticletoCart1',
    defaultMessage: 'Add article', // Artikel
  },
  addArticletoCart2: {
    id: 'addArticletoCart2',
    defaultMessage: 'to the cart', // in den Wahrenkorb legen
  },
  notAvailableForOrder: {
    id: 'notAvailableForOrder',
    defaultMessage: 'Not available for order', // Kein Versand möglich
  },
  publicationPdf: {
    id: 'publicationPdf',
    defaultMessage: 'Cart item in pdf format',
  },
});

const CartView = (props) => {
  const { content, location } = props;
  const { details } = content;
  const { pathname } = location;

  const items = useSelector(
    (state) => state.search.subrequests.cartitems?.items,
  );
  const dispatch = useDispatch();
  const intl = useIntl();

  useEffect(() => {
    dispatch(
      searchContent(
        pathname,
        {
          portal_type: ['CartItem'],
          sort_on: 'getObjPositionInParent',
          fullobjects: true,
          b_size: 100000000,
        },
        'cartitems',
      ),
    );
  }, [dispatch, pathname]);

  return (
    <Container id="page-document">
      <h1 className="documentFirstHeading">{content.title}</h1>
      <div dangerouslySetInnerHTML={{ __html: details?.data }}></div>
      <table className="ui celled table inverted cart-table">
        <thead>
          <tr>
            <th>{intl.formatMessage(messages.previewColumn)}</th>
            <th>{intl.formatMessage(messages.descriptionColumn)}</th>
            <th>{intl.formatMessage(messages.downloadColumn)}</th>
            <th>{intl.formatMessage(messages.orderInstructionsColumn)}</th>
          </tr>
        </thead>
        <tbody className="">
          {items &&
            items.map((item) => (
              <tr key={item['@id']}>
                <td>
                  <Image
                    src={flattenToAppURL(
                      item.preview_image?.scales?.preview?.download,
                    )}
                    size="small"
                    floated="right"
                    alt={item.image_caption}
                    avatar
                  />
                </td>
                <td dangerouslySetInnerHTML={{ __html: item.text?.data }}></td>
                <td>
                  {item.file && (
                    <a
                      href={flattenToAppURL(item.file.download)}
                      className="publication-pdf"
                    >
                      <Icon className="fileSVG" size="30px" name={pdfSVG} />{' '}
                      {intl.formatMessage(messages.publicationPdf)}
                    </a>
                  )}
                </td>
                <td>
                  {item.is_orderable ? (
                    <Link to={`${item['@id']}/@cart`}>
                      {intl.formatMessage(messages.addArticletoCart1)} "
                      {item.title}"{' '}
                      {intl.formatMessage(messages.addArticletoCart2)}
                    </Link>
                  ) : (
                    intl.formatMessage(messages.notAvailableForOrder)
                  )}
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </Container>
  );
};

export default CartView;
