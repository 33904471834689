import React from 'react';
import { Container } from 'semantic-ui-react';
import { defineMessages, useIntl } from 'react-intl';
import { flattenToAppURL } from '@plone/volto/helpers';
import { Link } from 'react-router-dom';

const messages = defineMessages({
  Location: {
    id: 'Place of work',
    defaultMessage: 'Place of work',
  },
  JobID: {
    id: 'Job ID',
    defaultMessage: 'Job ID',
  },
  JobTitle: {
    id: 'Job title',
    defaultMessage: 'Job title',
  },
  Institut: {
    id: 'Institut',
    defaultMessage: 'Institute',
  },
});

const JobOfferListing = ({ items }) => {
  const intl = useIntl();

  return (
    <Container className="modified-container">
      <div className="job-list">
        <div className="jobs-grid">
          <div className="job-list-row job-list-header mobile hidden">
            <div className="job-list-column id">
              <h2>{intl.formatMessage(messages.JobID)}</h2>
            </div>
            <div className="job-list-column title">
              <h2>{intl.formatMessage(messages.JobTitle)}</h2>
            </div>
            <div className="job-list-column institute">
              <h2>{intl.formatMessage(messages.Institut)}</h2>
            </div>
            <div className="job-list-column location">
              <h2>{intl.formatMessage(messages.Location)}</h2>
            </div>
          </div>
          {items?.map((item) => (
            <div className="job-list-row">
              <div className="job-list-column id">{item.id}</div>
              <div className="job-list-column title">
                <Link to={flattenToAppURL(item['@id'])}>{item.title}</Link>
              </div>
              <div className="job-list-column institute">
                {item.job_offer_institute_title}
              </div>
              <div className="job-list-column location">
                {item.job_offer_location_title}
              </div>
            </div>
          ))}
        </div>
      </div>
    </Container>
  );
};

export default JobOfferListing;
