import { flattenToAppURL } from '@plone/volto/helpers';
import config from '@plone/volto/registry';

/**
 * Return the API prefix path
 * @method apiPrefix
 * @returns {string} API prefix path
 */
export function apiPrefix() {
  const { settings } = config;
  let apiPrefix = '';
  if (settings.internalApiPath && __SERVER__) {
    apiPrefix = settings.internalApiPath;
  } else if (__DEVELOPMENT__ && settings.devProxyToApiPath) {
    apiPrefix = settings.devProxyToApiPath;
  } else {
    apiPrefix = settings.apiPath;
  }
  return apiPrefix;
}

/**
 * Return the base api path, including the ++api++ traversal, if needed
 * @method baseAPIPath
 * @returns {string} API url path
 */
export function baseAPIPath() {
  const { settings } = config;
  const APISUFIX = settings.legacyTraverse ? '' : '/++api++';

  return `${apiPrefix()}${APISUFIX}`;
}

/**
 * Given an url, return its API path
 * @method apiURLForContent
 * @param {string} url URL of the content
 * @returns {string} New URL with
 */
export function apiURLForContent(url) {
  url = flattenToAppURL(url);
  return `${baseAPIPath()}${url}`;
}
