import React from 'react';
import { Container, Segment } from 'semantic-ui-react';
import { MobileNavigation } from '@package/components';

import { LanguageSelector, Logo, Navigation } from '@plone/volto/components';

const HiERNHeader = (props) => {
  const { pathname } = props;

  return (
    <Segment basic className="header-wrapper" role="banner">
      <Container>
        <div className="header">
          <div className="logo-nav-wrapper">
            <div className="logo">
              <Logo />
            </div>
            <MobileNavigation />
            <Navigation pathname={pathname} />
            {/* <Link to={pathname.replace(/\/$/, '') + '/@@search?allow_local=true&local=true'} className="search" title="Search">
              <Button
                className="institut-search"
                basic
                icon
                aria-label={intl.formatMessage(messages.search)}
              >
                <Icon name={zoomSVG} size="36px" />
              </Button>
            </Link> */}
            <LanguageSelector />
          </div>
        </div>
      </Container>
    </Segment>
  );
};

export default HiERNHeader;
