import React from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { getContent } from '@plone/volto/actions';
import { SchemaRenderer } from '@kitconcept/volto-blocks/components';
import { flattenToAppURL } from '@plone/volto/helpers';
import { AktuellesSchema } from './schema';

const AktuellesData = (props) => {
  const { block, data, onChangeBlock } = props;
  const intl = useIntl();

  const href = data.href?.[0];
  const thref = data.thref?.[0];

  const dispatch = useDispatch();

  const { schemaEnhancer } = props;

  const schema = schemaEnhancer
    ? schemaEnhancer(AktuellesSchema({ ...props, intl }), props)
    : AktuellesSchema({ ...props, intl });

  React.useEffect(() => {
    if (href && !data.title && !data.description) {
      dispatch(getContent(flattenToAppURL(href['@id']), null, block)).then(
        (resp) => {
          onChangeBlock(block, {
            ...data,
            ...(!data.title && { title: resp.title }),
            ...(!data.description && { description: resp.description }),
            ...(!data.head_title && { head_title: resp.head_title }),
          });
        },
      );
    }
    if (!href) {
      onChangeBlock(block, {
        ...data,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [href]);
  React.useEffect(() => {
    if (thref && !data.ttitle && !data.tdescription) {
      dispatch(getContent(flattenToAppURL(thref['@id']), null, block)).then(
        (resp) => {
          onChangeBlock(block, {
            ...data,
            ...(!data.ttitle && { ttitle: resp.title }),
            ...(!data.tdescription && { tdescription: resp.description }),
            ...(!data.thead_title && { thead_title: resp.head_title }),
          });
        },
      );
    }
    if (!thref) {
      onChangeBlock(block, {
        ...data,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [thref]);

  return (
    <SchemaRenderer
      schema={schema}
      title={schema.title}
      onChangeField={(id, value) => {
        onChangeBlock(block, {
          ...data,
          [id]: value,
        });
      }}
      formData={data}
      fieldIndex={data.index}
    />
  );
};

export default AktuellesData;
