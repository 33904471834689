import React from 'react';
import { DefaultView } from '@plone/volto/components';
import { MathJax, MathJaxContext } from 'better-react-mathjax';
import { useSelector } from 'react-redux';

const config = {
  tex: {
    inlineMath: [
      ['$', '$'],
      ['\\(', '\\)'],
    ],
  },
  // Repeat update if content has changed.
  dynamic: true,
};

const EmbeddedPageView = (props) => {
  const isCounting = useSelector((state) => state.loadProtector.isCounting);
  if (isCounting) {
    // cooperation with ghost prevention - do not do any
    // conversion until the content has been loaded.
    return <DefaultView {...props} />;
  }
  return (
    <MathJaxContext
      config={config}
      version={3}
      src="https://download.fz-juelich.de/mathjax/tex-mml-chtml.js"
    >
      <MathJax>
        <DefaultView {...props} />
      </MathJax>
    </MathJaxContext>
  );
};

export default EmbeddedPageView;
