/**
 * Navigation reducer.
 * @module reducers/navigation/navigation
 */

import { GET_CONTENT } from '@plone/volto/constants/ActionTypes';

const initialState = {
  error: null,
  settings: {},
  loaded: false,
  loading: false,
};

export default function fzjsettings(state = initialState, action = {}) {
  switch (action.type) {
    case `${GET_CONTENT}_PENDING`:
      return {
        ...state,
        error: null,
        loaded: false,
        loading: true,
      };
    case `${GET_CONTENT}_SUCCESS`:
      const fzjsettings =
        { ...action.result['@components']?.fzjsettings } || {};
      delete fzjsettings['@id'];
      return {
        ...state,
        error: null,
        settings: fzjsettings,
        loaded: true,
        loading: false,
      };
    case `${GET_CONTENT}_FAIL`:
      return {
        ...state,
        error: action.error,
        loaded: false,
        loading: false,
      };
    default:
      return state;
  }
}
