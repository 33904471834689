import React from 'react';
// import React, { useState } from 'react';
import { flattenToAppURL } from '@plone/volto/helpers';
import UniversalLink from '@plone/volto/components/manage/UniversalLink/UniversalLink';
import { Icon } from '@plone/volto/components';
import { defineMessages, useIntl } from 'react-intl';
import cx from 'classnames';
// import { ContactForm } from '@package/components';
import { MailTo } from '@package/helpers';

import mailSVG from '../../../icons/mail-blue.svg';
import phoneSVG from '../../../icons/phone.svg';
import mapSVG from '../../../icons/map.svg';
import avatarPlaceholderPNG from '../../../icons/dummy.jpg';

const messages = defineMessages({
  showMembers: {
    id: 'Show All Members',
    defaultMessage: 'Show All Members',
  },
  hideMembers: {
    id: 'Hide Members',
    defaultMessage: 'Hide Members',
  },
  room: {
    id: 'Room',
    defaultMessage: 'Room',
  },
  building: {
    id: 'Building',
    defaultMessage: 'Building',
  },
  pleaseEnter: {
    id: 'Please enter',
    defaultMessage: 'Please enter',
  },
  visit: {
    id: 'visit',
    defaultMessage: 'Visit',
  },
  profile: {
    id: 'Profile',
    defaultMessage: 'Profile',
  },
});

const MemberBody = (props) => {
  const item = props.data.href?.length > 0 ? props.data.href?.[0] : props.data;
  const intl = useIntl();
  // const [showContactForm, setShowContactForm] = useState(false);
  const hideImage =
    props.variation === 'personsWithoutImage' ||
    props.variation === 'personsWithoutInstitutsOrImages';
  const hideInstituts =
    props.variation === 'personsWithoutInstitutsOrImages' ||
    props.variation === 'personsWithoutInstitutes';

  return (
    <>
      {item ? (
        <>
          {/* {showContactForm && (
            <ContactForm
              data={item}
              onClose={() => setShowContactForm(false)}
              memberURL={flattenToAppURL(item['@id'])}
              hideImage={hideImage}
            />
          )} */}
          <div className="member-list-body">
            <div className={cx('image-container', { hide: hideImage })}>
              <div
                className="grid-image-wrapper"
                style={{ marginBottom: '0px' }}
              >
                {item && (
                  <UniversalLink
                    href={item['@id']}
                    aria-label={`${intl.formatMessage(messages.visit)} ${
                      item.firstname
                    } ${intl.formatMessage(messages.profile)} `}
                  >
                    <img
                      src={
                        item?.image_field
                          ? flattenToAppURL(
                              `${item['@id']}/@@images/${item.image_field}/preview`,
                            )
                          : item.image
                          ? flattenToAppURL(item.image.scales.preview.download)
                          : avatarPlaceholderPNG
                      }
                      alt=""
                    />
                  </UniversalLink>
                )}
              </div>
            </div>
            <div
              className={cx('middle-container', { 'hide-image': hideImage })}
            >
              <div className="name-container">
                <UniversalLink href={item['@id']}>
                  <h2>{`${
                    item.academic
                      ? item.academic !== 'None'
                        ? item.academic
                        : ''
                      : ''
                  } ${item.firstname ?? ''} ${item.name ?? ''}`}</h2>
                </UniversalLink>
                <p>{item.positions ?? ''}</p>
              </div>
              <div
                className={cx('instituts-container', { hide: hideInstituts })}
              >
                <ul>
                  {item.institutes &&
                    item.institutes.length > 0 &&
                    item.institutes.map((item, index) => (
                      <li key={index}>
                        {typeof item === 'object'
                          ? item.title
                          : typeof item === 'string'
                          ? item
                          : null}
                      </li>
                    ))}

                  {item.institutsbereiche &&
                    item.institutsbereiche.length > 0 &&
                    item.institutsbereiche.map((item, index) => (
                      <li key={index}>
                        {typeof item === 'object'
                          ? item.title
                          : typeof item === 'string'
                          ? item
                          : null}
                      </li>
                    ))}
                  {item.administrational_units &&
                    item.administrational_units.length > 0 &&
                    item.administrational_units.map((item, index) => (
                      <li key={index}>
                        {typeof item === 'object'
                          ? item.title
                          : typeof item === 'string'
                          ? item
                          : null}
                      </li>
                    ))}
                </ul>
              </div>
            </div>
            <div
              className={cx('contact-container', {
                'hide-image': hideImage,
                'hide-instituts': hideInstituts,
              })}
            >
              <div className="building">
                {(item.building || item.room) && (
                  <Icon name={mapSVG} size="24px" color="#023d6b"></Icon>
                )}
                <span>
                  {item.building &&
                    `${intl.formatMessage(messages.building)} ${
                      item.building
                    } ${item.room ? '/ ' : ''}`}
                  {item.building && <br />}
                  {item.room &&
                    `${intl.formatMessage(messages.room)} ${item.room}`}
                </span>
              </div>
              {item?.phone && (
                <div className="phone">
                  <Icon name={phoneSVG} color="#023d6b" size="20px"></Icon>
                  <span className="phone">{item.phone}</span>
                </div>
              )}
              {item?.email && (
                <div className="mail">
                  <Icon name={mailSVG} size="14px"></Icon>
                  <MailTo email={item.email} className="mail" />
                  {/* <span
                  onClick={() => {
                    setShowContactForm(true);
                  }}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      setShowContactForm(true);
                    }
                  }}
                  className="mail"
                  role="button"
                  tabIndex={0}
                >
                  E-Mail
                </span> */}
                </div>
              )}
            </div>
          </div>
        </>
      ) : (
        ''
      )}
    </>
  );
};

export default MemberBody;
