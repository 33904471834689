import React from 'react';
import config from '@plone/volto/registry';
import { Grid, Image } from 'semantic-ui-react';
import { flattenToAppURL } from '@plone/volto/helpers';
import ImageZoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';

const ImageListingTemplate = ({ items, ...rest }) => {
  const renderItems = items.filter((content) =>
    config.settings.imageObjects.includes(content['@type']),
  );
  return (
    <Grid
      className="image-listing-template"
      style={{ marginLeft: '0px' }}
      columns={4}
      divided="vertically"
    >
      <Grid.Row>
        {renderItems.map((item) => (
          <Grid.Column className="column-container">
            <div className="image-container">
              <ImageZoom zoomMargin={150}>
                <Image
                  className="image-tag"
                  src={`${flattenToAppURL(item['@id'])}/@@images/image/teaser`}
                  alt=""
                />
              </ImageZoom>
            </div>
            <div className="title">{item.title}</div>
          </Grid.Column>
        ))}
      </Grid.Row>
    </Grid>
  );
};

export default ImageListingTemplate;
