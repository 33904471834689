import React, { useState, useEffect } from 'react';
import SlateEditor from 'volto-slate/editor/SlateEditor';
import { createEmptyParagraph } from 'volto-slate/utils/blocks';
import { useIntl, defineMessages } from 'react-intl';
import { handleKeyDetached } from 'volto-slate/blocks/Text/keyboard';

const messages = defineMessages({
  TypeText: {
    id: 'Type text...',
    defaultMessage: 'Type text...',
  },
});

const SimpleSlate = (props) => {
  const intl = useIntl();

  const {
    className,
    id,
    onChangeBlock,
    onSelectBlock,
    placeholder = intl.formatMessage(messages.TypeText),
    block,
    focus,
    data,
    dataKey,
  } = props;

  const [selected, setSelected] = useState(focus);

  const getDefaultValue = () => {
    return [
      {
        type: 'p',
        children: [{ text: '' }],
      },
    ];
  };

  useEffect(() => {
    if (!data[dataKey]) data[dataKey] = getDefaultValue();
  });

  const value = data?.[dataKey]?.value || getDefaultValue();

  const onChange = (name, value) => {
    onChangeBlock(block, {
      ...data,
      [dataKey]: {
        ...(data?.[dataKey] || {}),
        value,
      },
    });
  };

  return (
    <div
      className={className}
      role="textbox"
      tabIndex="-1"
      style={{ boxSizing: 'initial' }}
      onClick={() => {
        setSelected(true);
      }}
      onKeyDown={(e) => e.stopPropagation()}
    >
      <SlateEditor
        name={id}
        renderExtensions={[]}
        value={
          typeof value === 'undefined' ||
          typeof value?.data !==
            'undefined' /* previously this was a Draft block */
            ? [createEmptyParagraph()]
            : value
        }
        onFocus={() => {
          if (!selected) {
            onSelectBlock(block);
          }
        }}
        onChange={(newValue) => {
          onChange(id, newValue);
        }}
        block={block}
        selected={selected}
        placeholder={placeholder}
        onKeyDown={handleKeyDetached}
      />
    </div>
  );
};
export default SimpleSlate;
