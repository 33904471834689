import React from 'react';
import { useSelector } from 'react-redux';
import config from '@plone/volto/registry';
import { BodyClass } from '@plone/volto/helpers';

const InlineProjectCustomCSS = () => {
  const { isFZJProject } = config.settings;
  const fzjsettings = useSelector((state) => state.fzjsettings.settings);
  const isHiERNProject =
    config.settings.isHiERNProject === 'true' ? true : false;

  // Configure DSGVO Banner and Matomo depending on FZJ Site Settings
  if (config.settings.isFZJProject || config.settings.isHiERNProject) {
    config.settings.DSGVOBanner = fzjsettings.matomo_id
      ? {
          ...config.settings.DSGVOBanner,
          tracker: {
            type: 'matomo',
            id: fzjsettings.matomo_id,
            urlBase: 'https://apps.fz-juelich.de/piwik/',
          },
          showBanner: true,
          modules: ['tracking', 'youtube', 'facebook'],
          privacy_url: { de: '/de/datenschutz', en: '/en/privacy-policy' },
        }
      : {
          ...config.settings.DSGVOBanner,
          tracker: {
            type: 'none',
          },
          showBanner: false,
          modules: [],
          privacy_url: { de: '/de/datenschutz', en: '/en/privacy-policy' },
        };
  }
  return (
    <>
      <BodyClass className={fzjsettings.additional_body_class || null} />

      {isFZJProject ? (
        <>
          <BodyClass className={isFZJProject ? 'is-project' : null}></BodyClass>
          {(fzjsettings.main_bg_color || fzjsettings?.bg_image?.filename) && (
            <style>{`.content-area .ui.container { background-color: white; padding: 20px}`}</style>
          )}
          {/* {fzjsettings.main_bg_color && (
            <style>{`body {background-color: ${fzjsettings.main_bg_color}}`}</style>
          )} */}
          {fzjsettings.main_font && fzjsettings.main_font !== 'default' && (
            <style>{`:root {--custom-main-font: ${
              config.settings.fontCombinationMap[fzjsettings.main_font].main
            }; --custom-headers-font: ${
              config.settings.fontCombinationMap[fzjsettings.main_font].headers
            }}`}</style>
          )}
          {/* {fzjsettings?.bg_image?.filename && (
            <style>{`body {background-image: url(${apiPath}/@@project-bg-image/${fzjsettings.bg_image.filename});     background-repeat: no-repeat;
            background-position: center 0;
            background-size: auto;} .content-area .ui.container {padding: 20px}`}</style>
          )} */}
          {fzjsettings.nav_font_color && (
            <style>{`.navigation .ui.secondary.pointing.menu a.item {color: ${fzjsettings.nav_font_color} !important}`}</style>
          )}
          {fzjsettings.nav_active_color && (
            <style>{`.navigation .ui.secondary.pointing.menu .item:hover, .navigation .ui.secondary.pointing.menu .item.active {border-color: ${fzjsettings.nav_active_color} !important}
            .ui.basic.segment.header-wrapper .ui.menu a.item.activeMenu::after, .ui.basic.segment.header-wrapper .ui.menu a.item:hover::after, .ui.basic.segment.header-wrapper .ui.menu a.item.active::after{ background-color: ${fzjsettings.nav_font_color}}
            .navigation ul.desktop-menu .submenu { background-color: ${fzjsettings.nav_font_color}}`}</style>
          )}
        </>
      ) : isHiERNProject ? (
        <>
          <BodyClass className={isHiERNProject ? 'is-hiern' : null}></BodyClass>
          {(fzjsettings.main_bg_color || fzjsettings?.bg_image?.filename) && (
            <style>{`.content-area .ui.container { background-color: white; padding: 20px}`}</style>
          )}
          {/* {fzjsettings.main_bg_color && (
          <style>{`body {background-color: ${fzjsettings.main_bg_color}}`}</style>
        )} */}
          {fzjsettings.main_font && fzjsettings.main_font !== 'default' && (
            <style>{`:root {--custom-main-font: ${
              config.settings.fontCombinationMap[fzjsettings.main_font].main
            }; --custom-headers-font: ${
              config.settings.fontCombinationMap[fzjsettings.main_font].headers
            }}`}</style>
          )}
          {/* {fzjsettings?.bg_image?.filename && (
          <style>{`body {background-image: url(${apiPath}/@@project-bg-image/${fzjsettings.bg_image.filename});     background-repeat: no-repeat;
          background-position: center 0;
          background-size: auto;} .content-area .ui.container {padding: 20px}`}</style>
        )} */}
          {fzjsettings.nav_font_color && (
            <style>{`.navigation .ui.secondary.pointing.menu .item {color: ${fzjsettings.nav_font_color}}`}</style>
          )}
          {fzjsettings.nav_active_color && (
            <style>{`.navigation .ui.secondary.pointing.menu .item:hover, .navigation .ui.secondary.pointing.menu .item.active {border-color: ${fzjsettings.nav_active_color}}`}</style>
          )}
        </>
      ) : null}
    </>
  );
};

export default InlineProjectCustomCSS;
