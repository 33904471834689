/**
 * Language selector component.
 * @module components/LanguageSelector/LanguageSelector
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Link, useLocation } from 'react-router-dom';

import { useSelector } from 'react-redux';
import cx from 'classnames';
import { find, map } from 'lodash';

import { Helmet, langmap, flattenToAppURL } from '@plone/volto/helpers';

import config from '@plone/volto/registry';

import { defineMessages, useIntl } from 'react-intl';

const messages = defineMessages({
  switchLanguageTo: {
    id: 'Switch to',
    defaultMessage: 'Switch to',
  },
});

const LanguageSelector = (props) => {
  const intl = useIntl();
  const currentLang = useSelector((state) => state.intl.locale);
  const translations = useSelector(
    (state) => state.content.data?.['@components']?.translations?.items,
  );

  const root_translations = useSelector(
    (state) => state.content.data?.['@components']?.translations?.root,
  );
  const pathname = useLocation().pathname;

  const { settings } = config;
  const job_offer_root_paths = {
    de: '/de/karriere/stellenangebote',
    en: '/en/careers/jobs',
  };

  const contentType = useSelector((state) => state.content.data?.['@type']);

  const getTargetLocation = (
    location,
    translation,
    lang,
    root_translations,
    contentType,
  ) => {
    if (pathname.startsWith('/profile')) return pathname;
    else if (lang === currentLang) return false;
    else if (translation) return flattenToAppURL(translation['@id']);
    else if (contentType === 'Joboffer' && !translation) {
      if (lang === 'de') {
        return job_offer_root_paths['de'];
      } else {
        return job_offer_root_paths['en'];
      }
    } else {
      return flattenToAppURL(root_translations?.[lang]);
    }
  };

  return settings.isMultilingual && settings.supportedLanguages.length > 1 ? (
    <div className="language-selector">
      {map(settings.supportedLanguages, (lang) => {
        const translation = find(translations, { language: lang });
        const target = getTargetLocation(
          pathname,
          translation,
          lang,
          root_translations,
          contentType,
        );

        return target ? (
          <Link
            aria-label={`${intl.formatMessage(
              messages.switchLanguageTo,
            )} ${langmap[lang].nativeName.toLowerCase()}`}
            className={cx({ selected: lang === currentLang })}
            to={target}
            title={langmap[lang].nativeName}
            onClick={(e) => {
              props.onClickAction(e);
            }}
            key={`language-selector-${lang}`}
          >
            {lang}&nbsp;
          </Link>
        ) : (
          <span
            className={cx({ selected: lang === currentLang })}
            key={`language-selector-${lang}`}
          >
            {lang}&nbsp;
          </span>
        );
      })}
    </div>
  ) : (
    <Helmet>
      <html lang={settings.defaultLanguage} />
    </Helmet>
  );
};

LanguageSelector.propTypes = {
  onClickAction: PropTypes.func,
};

LanguageSelector.defaultProps = {
  onClickAction: () => {},
};

export default LanguageSelector;
