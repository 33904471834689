import React from 'react';
import { flattenToAppURL } from '@plone/volto/helpers';
import { Message } from 'semantic-ui-react';
import { defineMessages, useIntl } from 'react-intl';
import imageBlockSVG from '@plone/volto/components/manage/Blocks/Image/block-image.svg';

const messages = defineMessages({
  PleaseChooseIcon: {
    id: 'Please choose an icon as source for this element',
    defaultMessage: 'Please choose an icon as source for this element',
  },
});

const View = (props) => {
  const { data } = props;
  const intl = useIntl();
  const icon = data.icon?.[0];
  return (
    <div className="icon-block">
      {!icon && props.isEditMode && (
        <Message>
          <div className="grid-teaser-item default">
            <img src={imageBlockSVG} alt="" />
            <p>{intl.formatMessage(messages.PleaseChooseIcon)}</p>
          </div>
        </Message>
      )}
      {icon && (
        <img
          src={flattenToAppURL(`${icon['@id']}/@@images/image/teaser`)}
          alt="placeholder"
        />
      )}
      <h3 className="icon-heading headline">{data.heading}</h3>
      {data.description && data.description.data ? (
        <div
          className="icon-description"
          dangerouslySetInnerHTML={{ __html: data.description.data }}
        />
      ) : (
        // backwards compatibility for non-richtext data
        <p>{data.description}</p>
      )}
    </div>
  );
};

export default View;
