import { useState, useEffect } from 'react';
import { useHistory, useRouteMatch, Switch, Route } from 'react-router-dom';
import CartController from './CartController';
import { onLoad } from './cart-storage';
import { useDispatch, useSelector } from 'react-redux';
import { clearCartOrder, clearCartForm } from '../../actions';
import CartCheckoutForm from './CartCheckoutForm';
import CartCheckoutResult from './CartCheckoutResult';
import CartNotFoundRoute from './CartNotFoundRoute';
import countries from 'i18n-iso-countries';
import countriesLangsEn from 'i18n-iso-countries/langs/en.json';
import countriesLangsDe from 'i18n-iso-countries/langs/de.json';
countries.registerLocale(countriesLangsEn);
countries.registerLocale(countriesLangsDe);

const CartCheckout = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { path } = useRouteMatch();
  const resultPath = `${path}/result`;
  const [controller, updateController] = useState(
    new CartController({ onLoad }),
  );
  const [firstCartId, updateFirstCartId] = useState();

  useEffect(() => {
    dispatch(clearCartOrder());
  }, [dispatch, path]);

  useEffect(() => {
    // Any other context than an actual item just shows the cart anyway
    updateController((controller) => controller.load());
  }, []);

  useEffect(() => {
    // cart is needed for the back button of the result
    if (controller.items?.length > 0) {
      // consider first cart item as back location
      const first = controller.items[0].id;
      const index = first.lastIndexOf('/');
      if (index !== -1) {
        // go back from the cart item from t
        updateFirstCartId(first.substr(0, index));
      }
    }
    dispatch(clearCartForm());
  }, [dispatch, controller]);

  const hasResult = useSelector(
    ({ cart: { loaded, error } }) => loaded || error,
  );

  useEffect(() => {
    if (hasResult) {
      history.push(resultPath);
    }
  }, [history, hasResult, resultPath]);

  return (
    <Switch>
      <Route exact path={path}>
        <CartCheckoutForm />
      </Route>
      <Route path={resultPath}>
        <CartCheckoutResult firstCartId={firstCartId} />
      </Route>
      <Route>
        <CartNotFoundRoute />
      </Route>
    </Switch>
  );
};

export default CartCheckout;
