import React, { useState, useCallback, useEffect } from 'react';
import teaserHeroTopTemplate from '@kitconcept/volto-blocks/components/TeaserHero/teaserhero-top-template.svg';
import { Message, Grid } from 'semantic-ui-react';
import { flattenToAppURL } from '@plone/volto/helpers';
import Body from './TeaserTabBody';
import { defineMessages, useIntl } from 'react-intl';
import { UniversalLink } from '@plone/volto/components';
import rightSVG from '@plone/volto/icons/right-key.svg';
import { Icon } from '@plone/volto/components';
import cx from 'classnames';

const messages = defineMessages({
  PleaseChooseContent: {
    id: 'Please choose an existing content as source for this element',
    defaultMessage:
      'Please choose an existing content as source for this element',
  },
  more: {
    id: 'More',
    defaultMessage: 'More',
  },
});

const TeaserTabView = (props) => {
  const { data, isEditMode } = props;
  const intl = useIntl();
  const [activeTab, setTab] = useState(0);
  const href = data?.columns?.[activeTab]
    ? data.columns[activeTab].href
      ? data.columns[activeTab].href?.[0]
      : null
    : null;
  const [mobileSize, setmobileSize] = useState(false);

  const updateSize = useCallback(() => {
    if (window.innerWidth < 768) {
      setmobileSize(true);
    } else {
      setmobileSize(false);
    }
  }, []);

  useEffect(() => {
    window.addEventListener('resize', updateSize);
    return () => window.removeEventListener('resize', updateSize);
  }, [updateSize]);

  useEffect(() => {
    updateSize();
  }, [updateSize]);

  return (
    <div className="teaser-tab-block">
      {(data.columns?.length === 0 || !data.columns) && isEditMode && (
        <Message>
          <div className="teaser-item default">
            <img src={teaserHeroTopTemplate} alt="" />
            <p>{intl.formatMessage(messages.PleaseChooseContent)}</p>
          </div>
        </Message>
      )}
      {data.columns?.length > 0 && (
        <div>
          {data.headline && <h2 className="headline">{data.headline}</h2>}
          <Grid stackable columns={data.columns.length}>
            <Grid.Row style={{ padding: '0px' }}>
              {data.columns &&
                data.columns.map((item, index) => (
                  <Grid.Column
                    onClick={() => {
                      setTab(index);
                    }}
                    style={{ padding: '0px' }}
                  >
                    <Body
                      key={item.id}
                      data={item}
                      isEditMode={isEditMode}
                      dataBlock={data}
                      active={mobileSize ? mobileSize : activeTab === index}
                      mobileSize={mobileSize}
                    />
                  </Grid.Column>
                ))}
            </Grid.Row>
          </Grid>
          <div className={cx('description-container', { mobile: mobileSize })}>
            {href && (
              <>
                <p>{data.columns[activeTab].description}</p>
                <UniversalLink
                  href={
                    href['@id']
                      ? flattenToAppURL(
                          data.columns[activeTab]?.href[0]?.['@id'],
                        )
                      : ''
                  }
                  target={
                    data.columns[activeTab].openLinkInNewTab ? '_blank' : null
                  }
                >
                  <div className="more-button">
                    <Icon
                      className="more-right-icon"
                      name={rightSVG}
                      size="24px"
                    />
                    {intl.formatMessage(messages.more)}
                  </div>
                </UniversalLink>
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default TeaserTabView;
