import React from 'react';
import { useSelector } from 'react-redux';
import { Container } from 'semantic-ui-react';
import { flattenToAppURL } from '@plone/volto/helpers';
import { UniversalLink } from '@plone/volto/components';
import config from '@plone/volto/registry';

const ProjectFooter = () => {
  const fzjsettings = useSelector((state) => state.fzjsettings.settings);
  const lang = useSelector((state) => state.intl.locale);
  const hasPreFooterItems = fzjsettings.pre_footer?.items?.length > 0;
  const hasFooterItems = fzjsettings.footer?.items?.length > 0;
  const { apiPath } = config.settings;

  return (
    <>
      {hasPreFooterItems && (
        <>
          <Container className="project-pre-footer">
            {fzjsettings.pre_footer.items.map((logo) => (
              <div key={logo['@id']} className="project-pre-footer-logo">
                {logo.href?.[0] && logo?.logo?.length > 0 && (
                  <UniversalLink
                    item={logo.href[0]}
                    openLinkInNewTab={logo.openLinkInNewTab}
                  >
                    <img
                      src={`${flattenToAppURL(
                        logo.logo[0]?.['@id'],
                      )}/@@images/image`}
                      title={logo.title}
                      alt={logo.title}
                    />
                  </UniversalLink>
                )}
              </div>
            ))}
          </Container>
        </>
      )}
      {hasFooterItems && (
        <>
          <Container className="project-footer">
            <ul>
              {fzjsettings.footer.items.map((item) => {
                // Support multilingual footer links
                const link = lang === 'de' ? item.href?.[0] : item.hrefEn?.[0];
                const title = lang === 'de' ? item.title : item.titleEn;
                return (
                  <React.Fragment key={item['@id']}>
                    {link && (
                      <li>
                        <UniversalLink
                          item={link}
                          openLinkInNewTab={item.openLinkInNewTab}
                        >
                          {title}
                        </UniversalLink>
                      </li>
                    )}
                  </React.Fragment>
                );
              })}
            </ul>
            {fzjsettings.footer_logo && (
              <UniversalLink
                href={fzjsettings.footer_logo_link}
                openLinkInNewTab
              >
                <img
                  src={`${apiPath}/@@project-footer-logo/${fzjsettings.footer_logo.filename}`}
                  alt="Footer main logo"
                />
              </UniversalLink>
            )}
          </Container>
        </>
      )}
    </>
  );
};

export default ProjectFooter;
