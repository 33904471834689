import React from 'react';
import { Container, Segment, Button } from 'semantic-ui-react';
import { defineMessages, useIntl } from 'react-intl';
import { Icon } from '@plone/volto/components';
import { Doormat, MobileNavigation } from '@package/components';
import { useHistory } from 'react-router-dom';

import clearSVG from '@plone/volto/icons/clear.svg';
import zoomSVG from '@package/icons/search-icon.svg';

import { LanguageSelector, Logo, Navigation } from '@plone/volto/components';

const messages = defineMessages({
  search: {
    id: 'Search',
    defaultMessage: 'Search',
  },
  searchSite: {
    id: 'Search Site',
    defaultMessage: 'Search Site',
  },
});

const ProjectHeader = (props) => {
  const { pathname } = props;
  const [showSitemap, setShowSitemap] = React.useState(false);
  const intl = useIntl();
  const history = useHistory();

  const modalHandler = () => {
    setShowSitemap(!showSitemap);
  };

  return (
    <Segment basic className="header-wrapper" role="banner">
      <Container>
        <div className="header">
          <div className="header-top mobile hidden tablet hidden">
            <LanguageSelector />
          </div>
          {showSitemap && (
            <div>
              <button onClick={modalHandler} className="close-icon">
                <Icon name={clearSVG} size="32px" color="#fff" />
              </button>
              <div
                role="button"
                className="modal"
                onKeyDown={modalHandler}
                onClick={modalHandler}
                tabIndex={0}
              >
                <Doormat columns="3" />
              </div>
            </div>
          )}
          {/* <div className="tools-search-wrapper">
        {!token && (
          <div className="tools">
            <Anontools />
          </div>
        )}
      </div> */}

          <div className="logo-nav-wrapper">
            <div className="logo">
              <Logo />
            </div>
            <MobileNavigation />
            <Navigation pathname={pathname} />
            <Button
              className="institut-search"
              basic
              icon
              aria-label={intl.formatMessage(messages.search)}
              onClick={() =>
                history.push(
                  pathname.replace(/\/$/, '') +
                    '/@@search?allow_local=true&local=true',
                )
              }
            >
              <Icon name={zoomSVG} size="36px" />
            </Button>
          </div>
        </div>
      </Container>
    </Segment>
  );
};

export default ProjectHeader;
