import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getContent } from '@plone/volto/actions';
import { SchemaRenderer } from '@kitconcept/volto-blocks/components';
import { IntroductionSchema } from './schema';
import { useIntl, defineMessages } from 'react-intl';

const messages = defineMessages({
  IntroductionBlock: {
    id: 'IntroductionBlock',
    defaultMessage: 'Introduction Block',
  },
});

const IntroductionData = (props) => {
  const { data, block, onChangeBlock, schemaEnhancer } = props;
  const intl = useIntl();
  const schema = schemaEnhancer
    ? schemaEnhancer(IntroductionSchema({ ...props, intl }), props)
    : IntroductionSchema({ ...props, intl });

  const dispatch = useDispatch();
  const href = data.href?.[0];

  useEffect(() => {
    if (href) {
      dispatch(getContent(href['@id'], null, block)).then((resp) => {
        onChangeBlock(block, {
          ...data,
          ...(!data.name && { name: resp.name }),
          ...(!data.firstname && { firstname: resp.firstname }),
          ...(!data.email && { email: resp.email }),
          ...(!data.mobile && { mobile: resp.mobile }),
          ...(!data.phone && { phone: resp.phone }),
          ...(!data.salutation && { salutation: resp.salutation }),
          ...(!data.academic && { academic: resp.academic }),
          ...(!data.institute && { institute: resp.institute }),
          ...(!data.room && { room: resp.room }),
          ...(!data.building && { building: resp.building }),
          ...(!data.image && { image: resp.image }),
          ...(!data.positions && { positions: resp.positions }),
        });
      });
    } else {
      onChangeBlock(block, {
        ...data,
        name: '',
        firstname: '',
        email: '',
        mobile: '',
        phone: '',
        salutation: '',
        academic: '',
        institute: '',
        room: '',
        building: '',
        image: '',
        positions: '',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [href]);

  return (
    <SchemaRenderer
      schema={schema}
      title={intl.formatMessage(messages.IntroductionBlock)}
      onChangeField={(id, value) => {
        onChangeBlock(block, {
          ...data,
          [id]: value,
        });
      }}
      formData={data}
      fieldIndex={data.index}
      basic
      unwrapped
      block={block}
    />
  );
};

export default IntroductionData;
