import { defineMessages } from 'react-intl';

const messages = defineMessages({
  socialMedia: {
    id: 'Social Media',
    defaultMessage: 'Social  Media',
  },
  blogsTitle: {
    id: 'Blog Title',
    defaultMessage: 'Blog Title',
  },
  description: {
    id: 'Description',
    defaultMessage: 'Description',
  },
  link: {
    id: 'Link URL',
    defaultMessage: 'Link URL',
  },
  imageUrl: {
    id: 'Image Url',
    defaultMessage: 'Image URL',
  },
  youtubeUrl: {
    id: 'Youtube Url',
    defaultMessage: 'Youtube URL',
  },
  termine: {
    id: 'Termine Link',
    defaultMessage: 'Termine Link',
  },
  mastodon: {
    id: 'Mastodon Link',
    defaultMessage: 'Mastodon Link',
  },
  twitter: {
    id: 'X Link',
    defaultMessage: 'X Link',
  },
  threads: {
    id: 'Threads Link',
    defaultMessage: 'Threads Link',
  },
  facebook: {
    id: 'Facebook Link',
    defaultMessage: 'Facebook Link',
  },
  linkedin: {
    id: 'LinkedIn Link',
    defaultMessage: 'LinkedIn Link',
  },
  instagram: {
    id: 'Instagram Link',
    defaultMessage: 'Instagram Link',
  },
  effzet: {
    id: 'Effzet Link',
    defaultMessage: 'Effzet Link',
  },
  newsletter: {
    id: 'Newsletter Link',
    defaultMessage: 'Newsletter Link',
  },
  headline: {
    id: 'Headline',
    defaultMessage: 'Headline',
  },
});

export const SocialMediaSchema = (props) => {
  const { intl } = props;
  return {
    title: intl.formatMessage(messages.socialMedia),
    block: 'socialMedia',
    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: ['headline'],
      },
      {
        id: 'Julich',
        title: 'Jülich Blogs',
        fields: ['description', 'link', 'bild'],
      },
      {
        id: 'YouTube',
        title: 'YouTube',
        fields: ['ydescription', 'ylink', 'ybild'],
      },
      {
        id: 'Social',
        title: 'Social Media Links',
        fields: [
          'termine',
          'mastodon',
          'linkedin',
          'instagram',
          'threads',
          'twitter',
          'effzet',
          'newsletter',
        ],
      },
    ],
    properties: {
      headline: {
        title: intl.formatMessage(messages.headline),
      },
      description: {
        title: intl.formatMessage(messages.description),
      },
      link: {
        title: intl.formatMessage(messages.link),
      },
      bild: {
        title: intl.formatMessage(messages.imageUrl),
        widget: 'object_browser',
        mode: 'image',
        allowExternals: true,
      },
      ydescription: {
        title: intl.formatMessage(messages.description),
      },
      ylink: {
        title: intl.formatMessage(messages.link),
      },
      ybild: {
        title: intl.formatMessage(messages.imageUrl),
        widget: 'object_browser',
        mode: 'image',
        allowExternals: true,
      },
      termine: {
        title: intl.formatMessage(messages.termine),
      },
      mastodon: {
        title: intl.formatMessage(messages.mastodon),
      },
      linkedin: {
        title: intl.formatMessage(messages.linkedin),
      },
      instagram: {
        title: intl.formatMessage(messages.instagram),
      },
      threads: {
        title: intl.formatMessage(messages.threads),
      },
      twitter: {
        title: intl.formatMessage(messages.twitter),
      },
      effzet: {
        title: intl.formatMessage(messages.effzet),
      },
      newsletter: {
        title: intl.formatMessage(messages.newsletter),
      },
    },
    required: [],
  };
};
