import React, { useState } from 'react';
import { Grid, Container, Button } from 'semantic-ui-react';
import { Icon, UniversalLink } from '@plone/volto/components';
import { useIntl, defineMessages } from 'react-intl';
import { ContactForm } from '@package/components';
import { flattenToAppURL } from '@plone/volto/helpers';

import fzjSVG from '@package/icons/logo_fzj.svg';
import locationSVG from '@package/icons/location.svg';
import newsletterSVG from '@package/icons/newsletter.svg';
import phoneSVG from '@package/icons/phone.svg';
import printSVG from '@package/icons/print.svg';
import mailSVG from '@package/icons/mail.svg';
import roadmapSVG from '@package/icons/roadmap.svg';

const messages = defineMessages({
  Directions: {
    id: 'Directions',
    defaultMessage: 'Directions',
  },
  RegisterNow: {
    id: 'Register Now',
    defaultMessage: 'Register Now',
  },
  MonthlyUpdate: {
    id: 'The monthly update from Forschungszentrum Jülich',
    defaultMessage: 'The monthly update from Forschungszentrum Jülich',
  },
});

const PortalFooter = ({ content }) => {
  const intl = useIntl();
  const [showContactForm, setShowContactForm] = useState(false);

  return (
    <>
      {showContactForm && (
        <ContactForm
          memberURL={content['@id']}
          onClose={() => setShowContactForm(false)}
        />
      )}
      <div className="doormat">
        <div className="portal-footer">
          <Container>
            <Grid stackable columns={3}>
              <Grid.Column className="left">
                <div className="icon-wrapper top logo">
                  <Icon name={fzjSVG} size="50px" className="fzj logo" />
                </div>
                <div className="text">
                  <p className="footer-header">{content?.footer_header}</p>
                  <p>
                    {content?.footer_text &&
                      content.footer_text.length > 0 &&
                      content.footer_text.split('\n').map((text, index) => {
                        return (
                          <React.Fragment key={index}>
                            {text}
                            <br />
                          </React.Fragment>
                        );
                      })}
                  </p>
                </div>
              </Grid.Column>
              <Grid.Column className="center">
                <div className="icon-wrapper top location">
                  <Icon name={locationSVG} size="68px" color="#97B9E2" />
                </div>
                <div className="details">
                  <p className="fzj">Forschungszentrum Jülich GmbH</p>
                  <span>Wilhelm-Johnen-Straße</span>
                  <span className="postcode">52428 Jülich</span>
                  <div className="phone">
                    <div className="icon-wrapper phone">
                      <Icon name={phoneSVG} size="20px" />
                    </div>
                    <span>02461 61-0</span>
                  </div>
                  <div className="fax">
                    <div className="icon-wrapper fax">
                      <Icon name={printSVG} size="20px" />
                    </div>
                    <span>02461 61-8100</span>
                  </div>
                  {content?.portal_footer_contact_mail && (
                    <div className="mail">
                      <div className="icon-wrapper mail">
                        <Icon name={mailSVG} size="13px" />
                      </div>
                      <span
                        onClick={() => setShowContactForm(true)}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter') {
                            setShowContactForm(true);
                          }
                        }}
                        className="mail"
                        role="button"
                        tabIndex={0}
                      >
                        E-Mail
                      </span>
                    </div>
                  )}
                  <div className="route">
                    <div className="icon-wrapper roadmap">
                      <Icon name={roadmapSVG} size="16px" />
                    </div>
                    <UniversalLink
                      href={
                        intl.locale === 'de' ? '/de/anfahrt' : '/en/directions'
                      }
                      alt="Anfahrt"
                    >
                      {intl.formatMessage(messages.Directions)}
                    </UniversalLink>
                  </div>
                </div>
              </Grid.Column>
              <Grid.Column className="right">
                <div className="icon-wrapper top news">
                  <Icon name={newsletterSVG} size="63px" color="#97B9E2" />
                </div>
                <p className="juelich-news">Jülich News</p>
                <p className="subheader">
                  {intl.formatMessage(messages.MonthlyUpdate)}
                </p>
                {content?.portal_footer_newsletter && (
                  <UniversalLink
                    href={flattenToAppURL(content.portal_footer_newsletter)}
                    alt=""
                  >
                    <Button>{intl.formatMessage(messages.RegisterNow)}</Button>
                  </UniversalLink>
                )}
              </Grid.Column>
            </Grid>
          </Container>
        </div>
      </div>
    </>
  );
};

export default PortalFooter;
