/**
 * Replaced by a notice message
 */
import React from 'react';
import { Segment } from 'semantic-ui-react';
import { useIntl } from 'react-intl';

const ShortcutListing = (props) => {
  const intl = useIntl();
  return (
    <Segment.Group raised>
      <Segment className="sidebar-metadata-container" secondary>
        {intl.formatMessage({
          id: 'This block does not have any configuration options.',
          defaultMessage: 'This block does not have any configuration options.',
        })}
      </Segment>
    </Segment.Group>
  );
};

export default ShortcutListing;
