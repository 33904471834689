/**
 * View image block.
 * @module components/manage/Blocks/Image/View
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import cx from 'classnames';

import { flattenToAppURL, isInternalURL } from '@plone/volto/helpers';
import config from '@plone/volto/registry';

/**
 * View image block class.
 * @class View
 * @extends Component
 */
const View = ({ data, detached }) => (
  <div
    className={cx(
      'block image align',
      {
        center: !Boolean(data.align),
        detached,
      },
      data.align,
    )}
  >
    {data.url && (
      <>
        {(() => {
          const image = (
            <figure
              className={cx(
                'figure',
                {
                  center: !Boolean(data.align),
                  detached,
                },
                data.align,
                {
                  'full-width': data.align === 'full',
                  large: data.size === 'l',
                  medium: data.size === 'm' || !data.size,
                  small: data.size === 's',
                },
              )}
            >
              <img
                loading="lazy"
                src={
                  isInternalURL(data.url)
                    ? // Backwards compat in the case that the block is storing the full server URL
                      (() => {
                        if (data.align === 'full') {
                          return `${flattenToAppURL(data.url)}/@@images/image`;
                        } else {
                          if (data.size === 'l') {
                            if (data.align === 'right' || data.align === 'left')
                              return `${flattenToAppURL(
                                data.url,
                              )}/@@images/image/teaser`;
                            else
                              return `${flattenToAppURL(
                                data.url,
                              )}/@@images/image/great`;
                          } else if (data.size === 'm') {
                            if (data.align === 'right' || data.align === 'left')
                              return `${flattenToAppURL(
                                data.url,
                              )}/@@images/image/preview`;
                            else
                              return `${flattenToAppURL(
                                data.url,
                              )}/@@images/image/teaser`;
                          } else if (data.size === 's') {
                            if (data.align === 'right' || data.align === 'left')
                              return `${flattenToAppURL(
                                data.url,
                              )}/@@images/image/mini`;
                            else
                              return `${flattenToAppURL(
                                data.url,
                              )}/@@images/image/preview`;
                          }

                          return `${flattenToAppURL(data.url)}/@@images/image`;
                        }
                      })()
                    : data.url
                }
                alt={data.alt || ''}
              />
              {data?.description && (
                <figcaption className="description">
                  {data?.description}
                </figcaption>
              )}
              {data?.rights && (
                <>
                  <figcaption
                    className={cx('copyright', {
                      nodescription: !data?.description,
                    })}
                  >
                    <em>Copyright: </em>
                  </figcaption>
                  <figcaption className="rights">
                    {config.settings.isHiERNProject === 'true' ? (
                      <em>{data?.rights}</em>
                    ) : (
                      <em>&mdash; {data?.rights}</em>
                    )}
                  </figcaption>
                </>
              )}
            </figure>
          );

          if (data.href) {
            if (!isInternalURL(data.href)) {
              return (
                <a
                  target={data.openLinkInNewTab ? '_blank' : null}
                  href={data.href}
                >
                  {image}
                </a>
              );
            } else {
              return (
                <Link
                  to={flattenToAppURL(data.href)}
                  target={data.openLinkInNewTab ? '_blank' : null}
                >
                  {image}
                </Link>
              );
            }
          } else {
            return image;
          }
        })()}
      </>
    )}
  </div>
  // <figure>
  //   <figcaption></figcaption>
  // </figure>
);

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
View.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default View;
