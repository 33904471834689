/**
 * OVERRIDE Volto store.js
 * REASON: Protect against ghost content loading
 * FILE: https://github.com/plone/volto/blob/master/src/store.js
 * FILE VERSION: Volto 14.6.0
 * PULL REQUEST: https://github.com/kitconcept/fzj-internet/pull/976
 * TICKET: https://jugit.fz-juelich.de/fzj-internet/dlr/-/issues/776
 * DATE: 2022-01-27
 * DEVELOPER: @reebalazs
 * CHANGELOG:
 *  - Protect against ghost content loading (#776) @reebalazs
 *
 * Every change is marked with a JSX comment at the beginning and end of the change:
 *
 *   START CUSTOMIZATION
 *   <CUSTOMIZATION>
 *   END CUSTOMIZATION
 */

import { combineReducers, createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { save, load } from 'redux-localstorage-simple';

import config from '@plone/volto/registry';
import reducers from '~/reducers';

import { api, crashReporter, blacklistRoutes } from '@plone/volto/middleware';
// START CUSTOMIZATION
import {
  protectLoadStart,
  protectLoadEnd,
  loadProtector,
} from './storeProtectLoadUtils';
// END CUSTOMIZATION

const configureStore = (initialState, history, apiHelper) => {
  let stack = [
    blacklistRoutes,
    // START CUSTOMIZATION
    protectLoadStart,
    // END CUSTOMIZATION
    routerMiddleware(history),
    crashReporter,
    thunk,
    ...(apiHelper ? [api(apiHelper)] : []),
    // START CUSTOMIZATION
    protectLoadEnd,
    // END CUSTOMIZATION
    ...(__CLIENT__
      ? [save({ states: config.settings.persistentReducers, debounce: 500 })]
      : []),
  ];
  stack = config.settings.storeExtenders.reduce(
    (acc, extender) => extender(acc),
    stack,
  );
  const middlewares = composeWithDevTools(applyMiddleware(...stack));
  const store = createStore(
    combineReducers({
      router: connectRouter(history),
      ...reducers,
      ...config.addonReducers,
      // START CUSTOMIZATION
      loadProtector,
      // END CUSTOMIZATION
    }),
    {
      ...initialState,
      ...(__CLIENT__
        ? load({ states: config.settings.persistentReducers })
        : {}),
    },
    middlewares,
  );

  return store;
};

export default configureStore;
