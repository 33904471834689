/**
 * Breadcrumbs components.
 * @module components/theme/Breadcrumbs/Breadcrumbs
 */

import { Link, useLocation } from 'react-router-dom';
import { Container } from 'semantic-ui-react';
import { defineMessages, FormattedMessage } from 'react-intl';

import { Icon } from '@plone/volto/components';

import clearSVG from '@plone/volto/icons/clear.svg';

const messages = defineMessages({
  closeSearch: {
    id: 'Close search',
    defaultMessage: 'Close search',
  },
});

const BackFromSearch = ({ pathname }) => {
  // pathname is resetted for non-content routes already,
  // so to test for a search page we need to reclaim the original.
  const unmodifiedPathname = useLocation().pathname;
  const isSearchPage = unmodifiedPathname.match(/(.*\/)(@@)+search$/);
  if (isSearchPage) {
    // Despite being a non-content route, we want the full url for search pages.
    pathname = isSearchPage[1];
  }
  return isSearchPage ? (
    <Container className="back-row">
      <Link to={pathname}>
        <span className="back">
          <i>
            <Icon name={clearSVG} size="20px" />
          </i>
          <FormattedMessage {...messages.closeSearch} />
        </span>
      </Link>
    </Container>
  ) : null;
};

export default BackFromSearch;
