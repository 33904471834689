import React from 'react';
import { useSelector } from 'react-redux';
import { format, parse } from 'date-fns';
import { FormattedMessage } from 'react-intl';

const HIDE_MODIFIED = ['LRF', 'Institut', 'Institutsbereich', 'Project'];

const ModifiedSlot = ({ modified, type }) => {
  const language = useSelector((state) => state.intl.locale);

  const locales = {
    de: require('date-fns/locale/de'),
    en: require('date-fns/locale/en'),
  };

  return !HIDE_MODIFIED.includes(type) ? (
    <div className="last-modified-slot">
      <FormattedMessage id="Last Modified:" defaultMessage="Last Modified:" />
      &nbsp;
      {format(parse(modified), 'DD.MM.YYYY', {
        locale: locales[language],
      })}
    </div>
  ) : null;
};

export default ModifiedSlot;
