import React from 'react';
// import React, { useState } from 'react';
import { Grid, Image } from 'semantic-ui-react';
import UniversalLink from '@plone/volto/components/manage/UniversalLink/UniversalLink';
import { flattenToAppURL } from '@plone/volto/helpers';
import { Icon } from '@plone/volto/components';
import cx from 'classnames';
import { defineMessages, useIntl } from 'react-intl';
// import { ContactForm } from '@package/components';
import { MailTo } from '@package/helpers';

import mailSVG from '../../../icons/mail-blue.svg';
import phoneSVG from '../../../icons/phone.svg';
import mapSVG from '../../../icons/map.svg';
import avatarPlaceholderPNG from '../../../icons/dummy.jpg';

const messages = defineMessages({
  showMembers: {
    id: 'Show All Members',
    defaultMessage: 'Show All Members',
  },
  hideMembers: {
    id: 'Hide Members',
    defaultMessage: 'Hide Members',
  },
  room: {
    id: 'Room',
    defaultMessage: 'Room',
  },
  building: {
    id: 'Building',
    defaultMessage: 'Building',
  },
  pleaseEnter: {
    id: 'Please enter',
    defaultMessage: 'Please enter',
  },

  visit: {
    id: 'visit',
    defaultMessage: 'Visit',
  },
  profile: {
    id: 'Profile',
    defaultMessage: 'Profile',
  },
});

const MemberListingGridBody = (props) => {
  const { variation } = props;
  const intl = useIntl();

  // const [showContactForm, setShowContactForm] = useState(false);
  // const [currentContact, setCurrentContact] = useState(false);

  let withoutImage = false;
  if (variation) {
    withoutImage = variation.id === 'personsGridWithoutImage';
  }

  return (
    <>
      {/* {showContactForm && (
        <ContactForm
          {...props}
          data={currentContact}
          onClose={() => setShowContactForm(false)}
          memberURL={flattenToAppURL(currentContact['@id'])}
          hideImage={withoutImage}
        />
      )} */}
      <div className="members-view">
        <div>
          {props.data.headline && (
            <h2
              className={cx('headline', {
                'without-image': withoutImage,
              })}
            >
              {props.data.headline}
            </h2>
          )}
        </div>
        <Grid stackable columns={3}>
          <Grid.Row>
            {props.data.columns &&
              props.data.columns.map((item) => {
                let member = (item.href && item.href[0]) || item;
                if (member) {
                  if (withoutImage) {
                    return (
                      <Grid.Column key={member['@id']} className="grid-column ">
                        <div className="without-image">
                          <div className="content">
                            <UniversalLink href={member['@id']}>
                              <span className="name">{`${
                                member.academic
                                  ? member.academic !== 'None'
                                    ? member.academic
                                    : ''
                                  : ''
                              } ${member.firstname ?? ''} ${
                                member.name ?? ''
                              }`}</span>
                            </UniversalLink>
                            <span className="description">{`${
                              member.positions ?? ''
                            }`}</span>
                            <div className="building">
                              {(member.building || member.room) && (
                                <Icon
                                  name={mapSVG}
                                  size="24px"
                                  color="#023d6b"
                                ></Icon>
                              )}

                              <span>
                                {member.building &&
                                  `${intl.formatMessage(messages.building)} ${
                                    member.building
                                  } ${member.room ? '/ ' : ''}`}
                                {member.room &&
                                  `${intl.formatMessage(messages.room)}  ${
                                    member.room
                                  }`}
                              </span>
                            </div>
                            {item?.phone && (
                              <div className="phone">
                                <Icon
                                  name={phoneSVG}
                                  color="#023d6b"
                                  size="20px"
                                ></Icon>
                                <span className="phone">{item.phone}</span>
                              </div>
                            )}
                            {member?.email && (
                              <div className="mail">
                                <Icon name={mailSVG} size="14px"></Icon>
                                <MailTo email={member.email} className="mail" />
                                {/* <span
                                onClick={() => {
                                  setShowContactForm(true);
                                  setCurrentContact(member);
                                }}
                                onKeyDown={(e) => {
                                  if (e.key === 'Enter') {
                                    setShowContactForm(true);
                                    setCurrentContact(item);
                                  }
                                }}
                                className="mail"
                                role="button"
                                tabIndex={0}
                              >
                                E-Mail
                              </span> */}
                              </div>
                            )}
                          </div>
                        </div>
                      </Grid.Column>
                    );
                  } else {
                    return (
                      <Grid.Column key={member['@id']} className="grid-column">
                        <div>
                          <div className="image-container">
                            <UniversalLink
                              href={member['@id']}
                              aria-label={`${intl.formatMessage(
                                messages.visit,
                              )} ${member.firstname} ${intl.formatMessage(
                                messages.profile,
                              )} `}
                            >
                              <Image
                                src={
                                  member?.image_field
                                    ? flattenToAppURL(
                                        `${member['@id']}/@@images/${member.image_field}/preview`,
                                      )
                                    : member.image
                                    ? flattenToAppURL(
                                        member.image.scales.preview.download,
                                      )
                                    : avatarPlaceholderPNG
                                }
                                alt=""
                                size="small"
                                avatar
                              />
                            </UniversalLink>
                          </div>
                          <div className="content">
                            <UniversalLink href={member['@id']}>
                              <span className="name">{`${
                                member.academic
                                  ? member.academic !== 'None'
                                    ? member.academic
                                    : ''
                                  : ''
                              }  ${member.firstname ?? ''} ${
                                member.name ?? ''
                              }`}</span>
                            </UniversalLink>
                            <span className="description">
                              {member.positions ?? ''}
                            </span>
                            <span className="building">
                              {member.building &&
                                `${intl.formatMessage(messages.building)} ${
                                  member.building
                                } ${member.room ? '/ ' : ''}`}
                              {member.room &&
                                `${intl.formatMessage(messages.room)}  ${
                                  member.room
                                }`}
                            </span>
                            <span className="phone">
                              {member.phone ?? '+49 2461/61-9004'}
                            </span>
                            {/* <span
                              onClick={() => {
                                setShowContactForm(true);
                                setCurrentContact(member);
                              }}
                              onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                  setShowContactForm(true);
                                  setCurrentContact(item);
                                }
                              }}
                              className="mail"
                              role="button"
                              tabIndex={0}
                            >
                              E-Mail
                            </span> */}
                          </div>
                        </div>
                      </Grid.Column>
                    );
                  }
                }
                return null;
              })}
          </Grid.Row>
        </Grid>
      </div>
    </>
  );
};
export default MemberListingGridBody;
