import React from 'react';
import { Container, Segment, Button } from 'semantic-ui-react';
import { Icon } from '@plone/volto/components';
import { MobileNavigation } from '@package/components';
import IconHeader from '@package/components/Header/IconHeader';

import { defineMessages, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

import zoomSVG from '@package/icons/search-icon.svg';

import { Logo, Navigation } from '@plone/volto/components';

const messages = defineMessages({
  search: {
    id: 'Search',
    defaultMessage: 'Search',
  },
  searchSite: {
    id: 'Search Site',
    defaultMessage: 'Search Site',
  },
});

const MainHeader = (props) => {
  const { pathname } = props;
  const intl = useIntl();

  return (
    <Segment basic className="header-wrapper" role="banner">
      <Container>
        <div className="header">
          <IconHeader />
          {/* <div className="tools-search-wrapper">
        {!token && (
          <div className="tools">
            <Anontools />
          </div>
        )}
      </div> */}

          <div className="logo-nav-wrapper">
            <div className="logo">
              <Logo />
            </div>
            <MobileNavigation />
            <Navigation pathname={pathname} />
            <Link
              to={pathname.replace(/\/$/, '') + '/@@search'}
              className="search"
              title="Search"
            >
              <Button
                className="institut-search"
                basic
                icon
                aria-label={intl.formatMessage(messages.search)}
              >
                <Icon name={zoomSVG} size="36px" />
              </Button>
            </Link>
          </div>
        </div>
      </Container>
    </Segment>
  );
};

export default MainHeader;
