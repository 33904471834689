/**
 * OVERRIDE Volto Search.jsx
 * REASON: Add Solr term highlighting
 * FILE: https://github.com/plone/volto/blob/master/src/components/theme/Search/Search.jsx
 * FILE VERSION: Volto 14.0.0-alpha.40
 * PULL REQUEST: https://github.com/kitconcept/fzj-internet/pull/789
 * TICKET: https://jugit.fz-juelich.de/fzj-internet/dlr/-/issues/10
 * DATE: 2021-12-10
 * DEVELOPER: @tisto
 * CHANGELOG:
 *  - Add Solr term highlighting (2021-12-10) @tisto
 *
 * Every change is marked with a JSX comment at the beginning and end of the change:
 *
 *   START CUSTOMIZATION
 *   <CUSTOMIZATION>
 *   END CUSTOMIZATION
 */

/**
 * OVERRIDE Volto Search.jsx
 * REASON: Add Solr search page
 * FILE: https://github.com/plone/volto/blob/master/src/components/theme/Search/Search.jsx
 * FILE VERSION: Volto 14.0.0-alpha.40
 * PULL REQUEST: https://github.com/kitconcept/fzj-internet/pull/869
 * TICKET: https://jugit.fz-juelich.de/fzj-internet/dlr/-/issues/20
 * DATE: 2021-12-27
 * DEVELOPER: @reebalazs
 * CHANGELOG:
 *  - Add solr route, fix search page issues, add content type customizations #869 @reebalazs
 *
 * Every change is marked with a JSX comment at the beginning and end of the change:
 *
 *   START CUSTOMIZATION
 *   <CUSTOMIZATION>
 *   END CUSTOMIZATION
 */

/**
 * Search component.
 * @module components/theme/Search/Search
 */

// START CUSTOMIZATION
import React, { Component, createElement, createRef, forwardRef } from 'react';
// END CUSTOMIZATION
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
// START CUSTOMIZATION
// END CUSTOMIZATION
import { asyncConnect } from '@plone/volto/helpers';
import { FormattedMessage } from 'react-intl';
import { Portal } from 'react-portal';
// START CUSTOMIZATION
import {
  Container,
  Pagination,
  Button,
  Header,
  Dimmer,
  Loader,
  Checkbox,
} from 'semantic-ui-react';
import { injectIntl, useIntl, defineMessages } from 'react-intl';
// END CUSTOMIZATION
import qs from 'query-string';
import classNames from 'classnames';

import config from '@plone/volto/registry';
import { Helmet } from '@plone/volto/helpers';
import { SearchTags, Toolbar, Icon } from '@plone/volto/components';
import { SearchAutosuggest } from '@plone/volto/components/theme/SearchWidget/SearchWidget';

import paginationLeftSVG from '@plone/volto/icons/left-key.svg';
import paginationRightSVG from '@plone/volto/icons/right-key.svg';

// START CUSTOMIZATION
// These imports and the legacySearchProps are only for the legacy search
import { searchContent } from '@plone/volto/actions';
import { DefaultResultItem } from './resultItems';

import solrConfig from '@package/solr.json';

const messages = defineMessages({
  TypeSearchWords: {
    id: 'Search...',
    defaultMessage: 'Search...',
  },
  results: {
    // Ergebnisse
    id: 'results',
    defaultMessage: 'results',
  },
  searchLocalizedLabel: {
    // Suchergebnisse anzeigen für:
    id: 'searchLocalizedLabel',
    defaultMessage: 'Show results for:',
  },
  searchGlobalized: {
    // Alle webauftritte (global)
    id: 'searchGlobalized',
    defaultMessage: 'All content (global)',
  },
  searchLocalized: {
    // Nur dieser webauftritt
    id: 'searchLocalized',
    defaultMessage: 'This subfolder only',
  },
});

// XXX for some reason formatMessage is missing from this.props.intl.
// Until we figure this out, just acquire it directly from hook.
// This should not be necessary.. @reebalazs
const TranslatedInput = forwardRef(
  ({ forwardRef, placeholder, className, value, onChange, onSubmit }, ref) => {
    const intl = useIntl();
    return (
      <SearchInput
        ref={ref}
        placeholder={intl.formatMessage(placeholder)}
        className={className}
        value={value}
        onChange={onChange}
        onSubmit={onSubmit}
      />
    );
  },
);

const SearchInput = forwardRef(
  ({ forwardRef, placeholder, className, value, onChange, onSubmit }, ref) => {
    return (
      <SearchAutosuggest
        inputRef={ref}
        placeholder={placeholder}
        className={className}
        value={value || ''}
        onChange={onChange}
        onSubmit={onSubmit}
      />
    );
  },
);

// XXX The original input - left here for testing.
// const SearchInputDefault = forwardRef(
//   ({ forwardRef, placeholder, className, value, onChange }, ref) => {
//     return (
//       <input
//         ref={ref}
//         placeholder={placeholder}
//         className={className}
//         value={value}
//        onChange={onChange}
//       />
//     );
//   },
// );

const SearchTabs = ({ groupSelect, setGroupSelect, groupCounts }) => {
  const locale = useIntl().locale;
  groupCounts = groupCounts || [];
  return (
    <div className="searchTabs ui top attached tabular menu">
      {solrConfig.searchTabs.map((item, index) => {
        const isActive = index === groupSelect;
        const hasResults = groupCounts[index];
        return (
          <span
            onClick={() => hasResults && setGroupSelect(index)}
            onKeyDown={() => hasResults && setGroupSelect(index)}
            role="button"
            tabIndex={index}
            key={index}
            className={
              'searchTab item' +
              (isActive ? ' active' : ' inactive') +
              (hasResults ? ' results' : ' noresults')
            }
          >
            <span>
              {item.label[locale] || item.label['en']}
              <span
                className={
                  'searchCounter ui circular label ' +
                  (isActive ? ' blue' : 'white')
                }
              >
                {hasResults ? groupCounts[index] : '0'}
              </span>
            </span>
          </span>
        );
      })}
    </div>
  );
};

// Return the path prefix, or undefined, if we are on the root.
// We consider `/` and the language specific root folders `/en`, `/de` as roots
const getPathPrefix = (location) => {
  const pathPrefix = location.pathname.replace(/\/[^/]*$/, '') + '/';
  return pathPrefix.match(/^\/([^/]+\/)?$/) ? undefined : pathPrefix;
};

// XXX for some reason formatMessage is missing from this.props.intl.
// Until we figure this out, just acquire it directly from hook.
// This should not be necessary.. @reebalazs
const LocalCheckbox = ({ onChange, checked }) => {
  const intl = useIntl();
  return (
    <div className="search-localized">
      {intl.formatMessage(messages.searchLocalizedLabel)}
      <Checkbox
        radio
        label={intl.formatMessage(messages.searchGlobalized)}
        onChange={(e, data) => onChange(!data.checked)}
        checked={!checked}
      />
      <Checkbox
        radio
        label={intl.formatMessage(messages.searchLocalized)}
        onChange={(e, data) => onChange(data.checked)}
        checked={checked}
      />
    </div>
  );
};
// END CUSTOMIZATION

/**
 * Search class.
 * @class SearchComponent
 * @extends Component
 */
class Search extends Component {
  /**
   * Property types.
   * @property {Object} propTypes Property types.
   * @static
   */
  static propTypes = {
    searchContent: PropTypes.func.isRequired,
    // START CUSTOMIZATION
    searchAction: PropTypes.func,
    getSearchReducer: PropTypes.func,
    contentTypeSearchResultViews: PropTypes.object,
    contentTypeSearchResultDefaultView: PropTypes.func,
    // END CUSTOMIZATION
    searchableText: PropTypes.string,
    subject: PropTypes.string,
    path: PropTypes.string,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        '@id': PropTypes.string,
        '@type': PropTypes.string,
        title: PropTypes.string,
        description: PropTypes.string,
      }),
    ),
    // START CUSTOMIZATION
    loaded: PropTypes.bool,
    loading: PropTypes.bool,
    total: PropTypes.number,
    batching: PropTypes.shape({
      '@id': PropTypes.string,
      first: PropTypes.string,
      last: PropTypes.string,
      prev: PropTypes.string,
      next: PropTypes.string,
    }),
    pathname: PropTypes.string.isRequired,
    // END CUSTOMIZATION
  };

  /**
   * Default properties.
   * @property {Object} defaultProps Default properties.
   * @static
   */
  static defaultProps = {
    items: [],
    // START CUSTOMIZATION
    loaded: false,
    loading: false,
    total: 0,
    batching: null,
    // END CUSTOMIZATION
    searchableText: null,
    subject: null,
    path: null,
  };

  constructor(props) {
    super(props);
    // START CUSTOMIZATION
    this.state = {
      currentPage: 1,
      isClient: false,
      active: 'relevance',
      searchword: '',
      groupSelect: 0,
      allowLocal: false,
      local: false,
    };
    this.inputRef = createRef();
    // END CUSTOMIZATION
  }

  /**
   * Component did mount
   * @method componentDidMount
   * @returns {undefined}
   */
  componentDidMount() {
    this.doSearch();
    // START CUSTOMIZATION
    const location = this.props.history.location;
    const qoptions = qs.parse(location.search);
    this.setState({
      isClient: true,
      searchword: this.props.searchableText || '',
      active: qoptions.sort_on || 'relevance',
      groupSelect: parseInt(qoptions.group_select) || 0,
      allowLocal: (qoptions.allow_local || '').toLowerCase() === 'true',
      local: (qoptions.local || '').toLowerCase() === 'true',
    });
    // put focus to the search input field
    this.inputRef.current.focus();
    // END CUSTOMIZATION
  }

  /**
   * Component will receive props
   * @method componentWillReceiveProps
   * @param {Object} nextProps Next properties
   * @returns {undefined}
   */
  UNSAFE_componentWillReceiveProps = (nextProps) => {
    if (this.props.location.search !== nextProps.location.search) {
      this.doSearch();
    }
  };

  /**
   * Search based on the given searchableText, subject and path.
   * @method doSearch
   * @param {string} searchableText The searchable text string
   * @param {string} subject The subject (tag)
   * @param {string} path The path to restrict the search to
   * @returns {undefined}
   */

  doSearch = () => {
    // START CUSTOMIZATION
    const location = this.props.history.location;
    const qoptions = qs.parse(location.search);
    this.setState({ currentPage: 1 });
    const options = {
      ...qoptions,
      path_prefix: getPathPrefix(location),
      use_site_search_settings: 1,
      metadata_fields: ['effective', 'UID', 'start'],
      hl: 'true',
    };
    // END CUSTOMIZATION
    this.props.searchContent('', options);
  };

  handleQueryPaginationChange = (e, { activePage }) => {
    const { settings } = config;
    window.scrollTo(0, 0);
    // START CUSTOMIZATION
    const location = this.props.history.location;
    const qoptions = qs.parse(location.search);
    const options = {
      ...qoptions,
      path_prefix: getPathPrefix(location),
      use_site_search_settings: 1,
      metadata_fields: ['effective', 'UID', 'start'],
      hl: 'true',
    };
    // END CUSTOMIZATION

    this.setState({ currentPage: activePage }, () => {
      this.props.searchContent('', {
        ...options,
        b_start: (this.state.currentPage - 1) * settings.defaultPageSize,
      });
    });
  };

  onSortChange = (event, sort_order) => {
    // START CUSTOMIZATION
    const location = this.props.history.location;
    const qoptions = qs.parse(location.search);
    const options = {
      ...qoptions,
      path_prefix: getPathPrefix(location),
      use_site_search_settings: 1,
      metadata_fields: ['effective', 'UID', 'start'],
      hl: 'true',
    };
    // END CUSTOMIZATION
    options.sort_on = event.target.name;
    options.sort_order = sort_order || 'ascending';
    if (event.target.name === 'relevance') {
      delete options.sort_on;
      delete options.sort_order;
    }
    let searchParams = qs.stringify(options);
    this.setState({ currentPage: 1, active: event.target.name }, () => {
      // eslint-disable-next-line no-restricted-globals
      this.props.history.replace({
        search: searchParams,
      });
    });
  };

  // START CUSTOMIZATION
  setGroupSelect = (groupSelect) => {
    const location = this.props.history.location;
    const qoptions = qs.parse(location.search);
    const options = {
      ...qoptions,
      path_prefix: getPathPrefix(location),
      group_select: groupSelect,
      use_site_search_settings: 1,
      metadata_fields: ['effective', 'UID', 'start'],
      hl: 'true',
    };
    let searchParams = qs.stringify(options);
    this.setState({ currentPage: 1, groupSelect }, () => {
      // eslint-disable-next-line no-restricted-globals
      this.props.history.replace({
        search: searchParams,
      });
    });
  };

  setLocal = (local) => {
    const location = this.props.history.location;
    const qoptions = qs.parse(location.search);
    const options = {
      ...qoptions,
      path_prefix: getPathPrefix(location),
      local: local,
      use_site_search_settings: 1,
      metadata_fields: ['effective', 'UID', 'start'],
      hl: 'true',
    };
    let searchParams = qs.stringify(options);
    this.setState({ currentPage: 1, local }, () => {
      // eslint-disable-next-line no-restricted-globals
      this.props.history.replace({
        search: searchParams,
      });
    });
  };
  // END CUSTOMIZATION

  // START CUSTOMIZATION
  onSubmit = (event) => {
    this.props.history.push({
      pathname: this.props.pathname,
      search: qs.stringify({
        SearchableText: this.state.searchword,
        active: this.state.active,
        group_select: this.state.groupSelect,
        allow_local: this.state.allowLocal,
        local: this.state.local,
      }),
    });
    event.preventDefault();
  };
  // END CUSTOMIZATION

  /**
   * Render method.
   * @method render
   * @returns {string} Markup for the component.
   */
  render() {
    const { settings } = config;
    // START CUSTOMIZATION
    // The next line is only needed for the legacy search component.
    const resultTypeMapper = (contentType) =>
      this.props.contentTypeSearchResultViews[contentType] ||
      this.props.contentTypeSearchResultDefaultView;
    // END CUSTOMIZATION
    return (
      <Container id="page-search">
        <Helmet title="Search" />
        <div className="container">
          {/* START CUSTOMIZATION */}
          <div className="search-input">
            <form onSubmit={this.onSubmit}>
              <div>
                <TranslatedInput
                  ref={this.inputRef}
                  placeholder={messages.TypeSearchWords}
                  className="searchinput"
                  value={this.state.searchword}
                  onChange={(e) =>
                    this.setState({ searchword: e.target.value })
                  }
                  onSubmit={this.onSubmit}
                />
                <Button onClick={this.onSubmit}>
                  <FormattedMessage id="Search" defaultMessage="Search" />{' '}
                </Button>
              </div>
            </form>
          </div>
          {this.state.allowLocal &&
          getPathPrefix(this.props.history.location) !== undefined ? (
            <LocalCheckbox
              onChange={(checked) => this.setLocal(checked)}
              checked={this.state.local}
            />
          ) : null}
          <SearchTabs
            groupSelect={this.state.groupSelect}
            setGroupSelect={(groupSelect) => this.setGroupSelect(groupSelect)}
            groupCounts={this.props.groupCounts}
          />
          {/* END CUSTOMIZATION */}
          <article id="content">
            <header>
              <h1 className="documentFirstHeading">
                {this.props.searchableText ? (
                  <FormattedMessage
                    id="Search results for {term}"
                    defaultMessage="Search results for {term}"
                    values={{
                      term: <q>{this.props.searchableText}</q>,
                    }}
                  />
                ) : (
                  <FormattedMessage
                    id="Search results"
                    defaultMessage="Search results"
                  />
                )}
              </h1>

              <SearchTags />
              {/* START CUSTOMIZATION */}
              {this.props.total > 0 ? (
                <div className="items_total">
                  {this.props.total}{' '}
                  <FormattedMessage id="results" defaultMessage="results" />
                  {/* END CUSTOMIZATION */}
                  <Header>
                    <Header.Content className="header-content">
                      <div className="sort-by">
                        <FormattedMessage
                          id="Sort By:"
                          defaultMessage="Sort by:"
                        />
                      </div>
                      <Button
                        onClick={(event) => {
                          this.onSortChange(event);
                        }}
                        name="relevance"
                        size="tiny"
                        className={classNames('button-sort', {
                          'button-active': this.state.active === 'relevance',
                        })}
                      >
                        <FormattedMessage
                          id="Relevance"
                          defaultMessage="Relevance"
                        />
                      </Button>
                      <Button
                        onClick={(event) => {
                          this.onSortChange(event);
                        }}
                        name="sortable_title"
                        size="tiny"
                        className={classNames('button-sort', {
                          'button-active':
                            this.state.active === 'sortable_title',
                        })}
                      >
                        <FormattedMessage
                          id="Alphabetically"
                          defaultMessage="Alphabetically"
                        />
                      </Button>
                      <Button
                        onClick={(event) => {
                          this.onSortChange(event, 'reverse');
                        }}
                        name="effective"
                        size="tiny"
                        className={classNames('button-sort', {
                          'button-active': this.state.active === 'effective',
                        })}
                      >
                        <FormattedMessage
                          id="Date (newest first)"
                          defaultMessage="Date (newest first)"
                        />
                      </Button>
                    </Header.Content>
                  </Header>
                </div>
              ) : (
                <div>
                  <FormattedMessage
                    id="No results found"
                    defaultMessage="No results found"
                  />
                </div>
              )}
            </header>
            <section id="content-core">
              {/* START CUSTOMIZATION */}
              <div>
                <Dimmer active={this.props.loading} inverted>
                  <Loader indeterminate size="small">
                    <FormattedMessage id="loading" defaultMessage="Loading" />
                  </Loader>
                </Dimmer>
              </div>
              {this.props.items?.map((item, index) => (
                <div key={'' + index + '-' + item['@id']}>
                  {createElement(resultTypeMapper(item['@type']), {
                    key: item['@id'],
                    item,
                  })}
                </div>
              ))}
              {this.props.batching && (
                <div className="search-footer">
                  {/* END CUSTOMIZATION */}
                  <Pagination
                    activePage={this.state.currentPage}
                    totalPages={Math.ceil(
                      /* START CUSTOMIZATION */
                      this.props.total / settings.defaultPageSize,
                      /* END CUSTOMIZATION */
                    )}
                    onPageChange={this.handleQueryPaginationChange}
                    firstItem={null}
                    lastItem={null}
                    prevItem={{
                      content: <Icon name={paginationLeftSVG} size="18px" />,
                      icon: true,
                      /* START CUSTOMIZATION */
                      'aria-disabled': !this.props.batching.prev,
                      className: !this.props.batching.prev ? 'disabled' : null,
                      /* END CUSTOMIZATION */
                    }}
                    nextItem={{
                      content: <Icon name={paginationRightSVG} size="18px" />,
                      icon: true,
                      /* START CUSTOMIZATION */
                      'aria-disabled': !this.props.batching.next,
                      className: !this.props.batching.next ? 'disabled' : null,
                      /* END CUSTOMIZATION */
                    }}
                  />
                </div>
              )}
            </section>
          </article>
        </div>
        {this.state.isClient && (
          <Portal node={document.getElementById('toolbar')}>
            <Toolbar
              pathname={this.props.pathname}
              hideDefaultViewButtons
              inner={<span />}
            />
          </Portal>
        )}
      </Container>
    );
  }
}

// START CUSTOMIZATION
// The xxxWithDefault functions are only to support the legacy search component.
const searchActionWithDefault = (searchAction) =>
  searchAction !== undefined ? searchAction : searchContent;
const getSearchReducerWithDefault = (state, { getSearchReducer }) =>
  getSearchReducer !== undefined ? getSearchReducer(state) : state.search;
const contentTypeSearchResultViewsWithDefault = (
  contentTypeSearchResultViews,
) =>
  contentTypeSearchResultViews !== undefined
    ? contentTypeSearchResultViews
    : {};
const contentTypeSearchResultDefaultViewWithDefault = (
  contentTypeSearchResultDefaultView,
) =>
  contentTypeSearchResultDefaultView !== undefined
    ? contentTypeSearchResultDefaultView
    : DefaultResultItem;

export const __test__ = connect(
  (state, props) => {
    const {
      items,
      total,
      loaded,
      loading,
      batching,
    } = getSearchReducerWithDefault(state, props);
    return {
      items,
      total,
      loaded,
      loading,
      batching,
      intl: state.intl,
      searchableText: qs.parse(props.history.location.search).SearchableText,
      pathname: props.history.location.pathname,
      contentTypeSearchResultViews: contentTypeSearchResultViewsWithDefault(
        props.contentTypeSearchResultViews,
      ),
      contentTypeSearchResultDefaultView: contentTypeSearchResultDefaultViewWithDefault(
        props.contentTypeSearchResultDefaultView,
      ),
    };
  },
  (dispatch, { searchAction }) => ({
    searchContent: (...args) =>
      dispatch(searchActionWithDefault(searchAction)(...args)),
  }),
)(Search);

export default compose(
  injectIntl,
  connect(
    (state, props) => {
      const {
        items,
        groupCounts,
        total,
        loaded,
        loading,
        batching,
      } = getSearchReducerWithDefault(state, props);
      return {
        items,
        groupCounts,
        total,
        loaded,
        loading,
        batching,
        intl: state.intl,
        searchableText: qs.parse(props.history.location.search).SearchableText,
        pathname: props.history.location.pathname,
        contentTypeSearchResultViews: contentTypeSearchResultViewsWithDefault(
          props.contentTypeSearchResultViews,
        ),
        contentTypeSearchResultDefaultView: contentTypeSearchResultDefaultViewWithDefault(
          props.contentTypeSearchResultDefaultView,
        ),
      };
    },
    (dispatch, { searchAction }) => ({
      searchContent: (...args) =>
        dispatch(searchActionWithDefault(searchAction)(...args)),
    }),
  ),
  asyncConnect([
    {
      key: 'search',
      promise: ({ location, store: { dispatch }, searchAction }) =>
        dispatch(
          searchActionWithDefault(searchAction)('', {
            ...qs.parse(location.search),
            path_prefix: getPathPrefix(location),
            use_site_search_settings: 1,
            metadata_fields: ['effective', 'UID', 'start'],
            hl: 'true',
          }),
        ),
    },
  ]),
)(Search);
// END CUSTOMIZATION
