import React, { useState } from 'react';
import { Grid, Container } from 'semantic-ui-react';
import { Icon, UniversalLink } from '@plone/volto/components';
import { useIntl, defineMessages } from 'react-intl';
import { ContactForm } from '@package/components';
import { flattenToAppURL } from '@plone/volto/helpers';

import fzjSVG from '@package/icons/logo_fzj.svg';
import locationSVG from '@package/icons/location.svg';
import personSVG from '@package/icons/fallback-avatar.svg';
import phoneSVG from '@package/icons/phone.svg';
import mailSVG from '@package/icons/mail.svg';

const messages = defineMessages({
  Directions: {
    id: 'Directions',
    defaultMessage: 'Directions',
  },
  Contact: {
    id: 'Contact',
    defaultMessage: 'Contact',
  },
  Director: {
    id: 'Director',
    defaultMessage: 'Director',
  },
});

const InstitutsbereichFooter = ({ content, parent }) => {
  const intl = useIntl();
  const [showContactForm, setShowContactForm] = useState(false);

  return (
    <>
      {showContactForm && (
        <ContactForm
          memberURL={content['@id']}
          onClose={() => setShowContactForm(false)}
        />
      )}
      <div className="doormat">
        <div className="institutsbereich-footer">
          <Container>
            <Grid stackable columns={2}>
              <Grid.Column width={5}>
                <div className="icon-wrapper top logo">
                  <Icon name={fzjSVG} size="50px" className="fzj logo" />
                </div>
                <div className="text">
                  <p className="footer-header">{content?.footer_header}</p>
                  <p>
                    {content?.footer_text &&
                      content.footer_text.length > 0 &&
                      content.footer_text.split('\n').map((text) => {
                        return (
                          <>
                            {text}
                            <br />
                          </>
                        );
                      })}
                  </p>
                </div>
              </Grid.Column>
              <Grid.Column width={7} className="right">
                <div className="icon-wrapper top location">
                  <Icon name={locationSVG} size="68px" color="#97B9E2" />
                </div>
                <p className="parent">{parent?.name}</p>
                <p className="institutsbereich">{content?.name}</p>
                <div className="bottom">
                  <div className="left">
                    {content?.footer_address &&
                    content.footer_address.length > 0 ? (
                      content.footer_address.split('\n').map((text) => {
                        return (
                          <>
                            <span>{text}</span>
                            {text === '' && <br />}
                          </>
                        );
                      })
                    ) : (
                      <>
                        <span>Forschungszentrum Jülich GmbH</span>
                        <span>Wilhelm-Johnen-Straße</span>
                        <span>52428 Jülich</span>
                      </>
                    )}
                    {content?.footer_button_link &&
                      content.footer_button_link.length > 4 && (
                        <UniversalLink
                          href={flattenToAppURL(content.footer_button_link)}
                          alt=""
                        >
                          <button className="directions">
                            {intl.formatMessage(messages.Directions)}
                          </button>
                        </UniversalLink>
                      )}
                  </div>
                  {(content?.footer_leader ||
                    content?.footer_contact_mail ||
                    content?.footer_contact_phone) && (
                    <div className="right">
                      {content?.footer_leader && (
                        <div className="leader">
                          <span className="above">
                            {' '}
                            {intl.formatMessage(messages.Director)}:
                          </span>
                          <div className="person">
                            <div className="icon-wrapper avatar">
                              <Icon name={personSVG} size="30px" />
                            </div>
                            <span>{content?.footer_leader}</span>
                          </div>
                        </div>
                      )}
                      {(content?.footer_contact_mail ||
                        content?.footer_contact_phone) && (
                        <div className="contact">
                          <span className="above">
                            {intl.formatMessage(messages.Contact)}:
                          </span>
                          {content?.footer_contact_phone && (
                            <div className="phone">
                              <div className="icon-wrapper">
                                <Icon name={phoneSVG} size="20px" />
                              </div>
                              <span>{content.footer_contact_phone}</span>
                            </div>
                          )}
                          {content?.footer_contact_mail && (
                            <div className="mail">
                              <div className="icon-wrapper">
                                <Icon name={mailSVG} size="13px" />
                              </div>
                              <span
                                onClick={() => setShowContactForm(true)}
                                onKeyDown={(e) => {
                                  if (e.key === 'Enter') {
                                    setShowContactForm(true);
                                  }
                                }}
                                className="mail"
                                role="button"
                                tabIndex={0}
                              >
                                E-Mail
                              </span>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </Grid.Column>
            </Grid>
          </Container>
        </div>
      </div>
    </>
  );
};

export default InstitutsbereichFooter;
