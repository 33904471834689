import React from 'react';
import { getParentUrl } from '@plone/volto/helpers';
import MemberView from './View';
import { SidebarPortal } from '@plone/volto/components';
import MembersData from './MembersData';

const MembersEdit = (props) => {
  const { data, onChangeBlock, block, selected } = props;

  return (
    <>
      <MemberView {...props} path={getParentUrl(props.pathname)} />
      <SidebarPortal selected={selected}>
        <MembersData data={data} block={block} onChangeBlock={onChangeBlock} />
      </SidebarPortal>
    </>
  );
};

export default MembersEdit;
