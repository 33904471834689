import { defineMessages } from 'react-intl';

const messages = defineMessages({
  AltmetricBlock: {
    id: 'AltmetricBlock',
    defaultMessage: 'Altmetric Block',
  },
  Badge: {
    id: 'Badge',
    defaultMessage: 'Badge Configuration',
  },
  idType: {
    id: 'idType',
    defaultMessage: 'Reference type',
  },
  idValue: {
    id: 'idValue',
    defaultMessage: 'Reference',
  },
  badgeType: {
    id: 'badgeType',
    defaultMessage: 'Badge type',
  },
  badgeDetails: {
    id: 'badgeDetails',
    defaultMessage: 'Details',
  },
  badgePopover: {
    id: 'badgePopover',
    defaultMessage: 'Popover',
  },
  condensed: {
    id: 'condensed',
    defaultMessage: 'Condensed style?',
  },
  hideNoMentions: {
    id: 'hideNoMentions',
    defaultMessage: 'Hide badge if no mentions',
  },
});

export const AltmetricSchema = (props) => ({
  title: props.intl.formatMessage(messages.AltmetricBlock),
  block: 'altmetric',
  fieldsets: [
    {
      id: 'default',
      title: 'Default',
      fields: ['idType', 'idValue'],
    },
    {
      id: 'badge',
      title: props.intl.formatMessage(messages.Badge),
      fields: ['badge-type', 'badge-details', 'hide-no-mentions', 'condensed'],
    },
  ],

  properties: {
    idType: {
      title: props.intl.formatMessage(messages.idType),
      default: 'doi',
      choices: [
        ['doi', 'DOI'],
        ['arxiv-id', 'arXiv ID'],
        ['pmid', 'PubMed ID'],
        ['handle', 'Handle'],
        ['isbn', 'ISBN'],
        ['uri', 'URI'],
        ['urn', 'URN'],
        ['nct-id', 'NCT ID'],
        ['altmetric-id', 'Altmetric ID'],
      ],
    },
    idValue: {
      title: props.intl.formatMessage(messages.idValue),
    },
    'badge-type': {
      title: props.intl.formatMessage(messages.badgeType),
      default: '4',
      choices: [
        ['4', 'Small Badge'],
        ['2', 'Medium Badge'],
        ['1', 'Large Badge'],
        ['donut', 'Small Donut'],
        ['medium-donut', 'Medium Donut'],
        ['large-donut', 'Large Donut'],
        ['bar', 'Small Bar'],
        ['medium-bar', 'Medium Bar'],
        ['large-bar', 'Large Bar'],
      ],
    },
    'badge-details': {
      title: props.intl.formatMessage(messages.badgeDetails),
      default: null,
      choices: [
        [null, 'No details'],
        ['right', 'Right'],
      ],
    },
    'badge-popover': {
      title: props.intl.formatMessage(messages.badgePopover),
      default: null,
      choices: [
        [null, 'No popover'],
        ['right', 'Right'],
      ],
    },
    'hide-no-mentions': {
      title: props.intl.formatMessage(messages.hideNoMentions),
      default: true,
      type: 'boolean',
    },
    condensed: {
      title: props.intl.formatMessage(messages.condensed),
      default: false,
      type: 'boolean',
    },
  },
  required: ['idType', 'idValue'],
});
