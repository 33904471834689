import { isInternalURL } from '@plone/volto/helpers';

export function getTeaserImageURL(image) {
  if (image) {
    if (isInternalURL(image['@id'])) {
      return `${image['@id']}/@@images/image/huge
      `;
    } else {
      return image['@id'];
    }
  } else {
    return '';
  }
}
