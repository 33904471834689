import React from 'react';
import { useSelector } from 'react-redux';
import { Grid } from 'semantic-ui-react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { flattenToAppURL } from '@plone/volto/helpers';
import { format, parse } from 'date-fns';

const FileListingVariation = ({ items, isEditMode }) => {
  const language = useSelector((state) => state.intl.locale);

  var deLocale = require('date-fns/locale/de');
  var enLocale = require('date-fns/locale/en');

  return (
    <div className="files">
      <Grid>
        <Grid.Row className="legend">
          <Grid.Column width={3}>
            {' '}
            <p>
              <FormattedMessage
                id="Title"
                defaultMessage="Title"
              ></FormattedMessage>
            </p>
          </Grid.Column>
          <Grid.Column width={4}>
            <p>
              <FormattedMessage
                id="Note"
                defaultMessage="Note"
              ></FormattedMessage>
            </p>
          </Grid.Column>
          <Grid.Column width={2}>
            <p>Link</p>
          </Grid.Column>
          <Grid.Column width={3}>
            <p>
              <FormattedMessage
                id="Publication Date"
                defaultMessage="Publication Date"
              ></FormattedMessage>
            </p>
          </Grid.Column>
        </Grid.Row>

        {items.map((item) => (
          <Grid.Row key={item.UID} className="items">
            <Grid.Column width={3} key={item['@id']}>
              {!isEditMode ? (
                <a className="title" href={item['@id']}>
                  <p>{item.title ? item.title : item.id}</p>
                </a>
              ) : (
                <p>{item.title ? item.title : item.id}</p>
              )}
            </Grid.Column>
            <Grid.Column width={4}>
              {item?.description && <span>{item.description}</span>}
            </Grid.Column>
            <Grid.Column width={2}>
              {item && item['@type'] === 'File' && (
                <a
                  href={`${flattenToAppURL(item['@id'])}/@@download/file`}
                  className="download"
                >
                  <span>Download </span>
                  <br></br>
                  <span>
                    (
                    {(() => {
                      switch (item?.mime_type) {
                        case 'image/jpeg':
                          return 'JPEG';
                        case 'image/png':
                          return 'PNG';
                        case 'image/svg+xml':
                          return 'SVG';
                        case 'image/gif':
                          return 'GIF';
                        case 'application/pdf':
                          return 'PDF';
                        case 'application/msexcel':
                          return 'XLS';
                        case 'application/vnd.ms-excel':
                          return 'XLS';
                        case 'application/msword':
                          return 'DOC';
                        case 'application/mspowerpoint':
                          return 'PPT';
                        case 'audio/mp4':
                          return 'MP4';
                        case 'application/zip':
                          return 'ZIP';
                        case 'video/webm':
                          return 'WEBM';
                        case 'video/x-msvideo':
                          return 'AVI';
                        case 'video/x-sgi-movie':
                          return 'MOVIE';
                        case 'text/xml':
                          return 'XML';
                        case 'text/plain':
                          return 'TXT';
                        case 'text/calendar':
                          return 'ICS';
                        case 'image/x-icon':
                          return 'ICO';
                        case 'image/bmp':
                          return 'BMP';
                        case 'audio/mpeg':
                          return 'MP3';
                        case 'audio/wav':
                          return 'WAV';
                        case 'application/json':
                          return 'JSON';
                        case 'application/postscript':
                          return 'PS';
                        case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
                          return 'XLSX';
                        case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
                          return 'DOCX';
                        case 'application/xml':
                          return 'XML';
                        case 'application/mshelp':
                          return 'HLP';
                        case 'application/gzip':
                          return 'GZ';
                        case 'application/x-zip-compressed':
                          return 'ZIP';
                        default:
                          return '';
                      }
                    })()}{' '}
                    / {item?.getObjSize && item.getObjSize})
                  </span>
                </a>
              )}
            </Grid.Column>
            <Grid.Column width={3}>
              {item?.CreationDate || item?.effective ? (
                <span className="day">
                  {language === 'de'
                    ? format(
                        parse(item?.effective || item?.CreationDate),
                        'DD. MMMM YYYY',
                        {
                          locale: deLocale,
                        },
                      )
                    : format(
                        parse(item?.effective || item?.CreationDate),
                        'Do MMMM YYYY',
                        {
                          locale: enLocale,
                        },
                      )}
                </span>
              ) : (
                <span className="day">No date</span>
              )}
            </Grid.Column>
          </Grid.Row>
        ))}
      </Grid>
    </div>
  );
};

export default injectIntl(FileListingVariation);
