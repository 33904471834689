import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { SchemaRenderer } from '@kitconcept/volto-blocks/components';
import { ParallaxSchema } from './schema';
import { Icon } from '@plone/volto/components';
import { Segment } from 'semantic-ui-react';
import imageSVG from '@plone/volto/icons/image.svg';

const ParallaxSidebar = (props) => {
  const { block, data, onChangeBlock } = props;
  const intl = useIntl();

  return (
    <>
      {props.data.url ? (
        <SchemaRenderer
          schema={ParallaxSchema({ ...props, intl })}
          title={ParallaxSchema({ ...props, intl }).title}
          onChangeField={(id, value) => {
            onChangeBlock(block, {
              ...data,
              [id]: value,
            });
          }}
          formData={data}
          block={block}
        />
      ) : (
        <>
          <Segment.Group raised>
            <header className="header pulled">
              <h2>
                {intl.formatMessage({
                  id: 'Parallax Block',
                  defaultMessage: 'Parallax Block',
                })}
              </h2>
            </header>
            <Segment className="sidebar-metadata-container" secondary>
              {intl.formatMessage({
                id: 'No image selected',
                defaultMessage: 'No image selected',
              })}

              <Icon name={imageSVG} size="100px" color="#b8c6c8" />
            </Segment>
          </Segment.Group>
        </>
      )}
    </>
  );
};

ParallaxSidebar.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  block: PropTypes.string.isRequired,
  onChangeBlock: PropTypes.func.isRequired,
};

export default ParallaxSidebar;
