import React from 'react';
import { Link } from 'react-router-dom';
import phoneSVG from '@package/icons/phone.svg';
import { Icon } from '@plone/volto/components';
import fallbackAvatarSVG from '../icons/fallback-avatar.svg';
import locationSVG from '../icons/location.svg';
import enterpriseSVG from '@plone/volto/icons/enterprise.svg';
import config from '@plone/volto/registry';
import { flattenToAppURL } from '@plone/volto/helpers';
import MailTo from './helpers/MailTo';

const MemberResultItem = ({ item }) => (
  <article className="tileItem itemWithTwoColumns memberResultItem">
    <div className="itemContentTypeColumn">
      <Link to={item['@id']}>
        <Icon
          size="64px"
          name={fallbackAvatarSVG}
          color={config.settings.baseColor}
        />
        <img
          src={flattenToAppURL(`${item['@id']}/@@images/image/teaser`)}
          alt={item.title}
          className="profileImage"
          width="64"
          height="64"
        />
      </Link>
    </div>
    <div className="itemMainColumn">
      <h2 className="tileHeadline">
        <Link to={item['@id']} className="summary url" title={item['@type']}>
          {item.title}
        </Link>
      </h2>
      <p className="url">{item['@id']}</p>
      <div className="itemWrapper">
        <div className="itemContent">
          {item.description}
          <div className="itemPhoneEmailBar">
            <span className="itemPhone">
              <Icon className="itemIcon" size="18px" name={phoneSVG} />
              {item.extras.phone}
            </span>
            {item.extras.email && (
              <MailTo
                email={item.extras.email}
                size="18px"
                className="itemEmail"
              />
            )}
            {item.extras.institute && (
              <span className="itemInstitute">
                <Icon className="itemIcon" size="18px" name={enterpriseSVG} />
                {item.extras.institute}
              </span>
            )}
            {(item.extras.building || item.extras.room) && (
              <span className="itemLocation">
                <Icon className="itemIcon" size="18px" name={locationSVG} />
                {[
                  item.extras.building && `Geb.${item.extras.building}`,
                  item.extras.room && `R.${item.extras.room}`,
                ]
                  .filter((t) => t)
                  .join('/')}
              </span>
            )}
          </div>
        </div>
      </div>
      <div className="visualClear" />
    </div>
  </article>
);

export default MemberResultItem;
