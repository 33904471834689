import React from 'react';
import { Grid, Image } from 'semantic-ui-react';
import { Icon } from '@plone/volto/components';
import {
  useIntl,
  defineMessages,
  injectIntl,
  FormattedMessage,
} from 'react-intl';
import { SimpleSlate } from '@package/helpers';
import { SidebarPortal } from '@plone/volto/components';
import IntroductionSidebar from './Sidebar';
import { flattenToAppURL } from '@plone/volto/helpers';

import emailSVG from '@plone/volto/icons/email.svg';
import mapSVG from '../../../icons/map.svg';
import phoneSVG from '../../../icons/phone.svg';
import avatarPlaceholderSVG from '../../../icons/fallback-avatar.svg';

const messages = defineMessages({
  room: {
    id: 'Room',
    defaultMessage: 'Room',
  },
  building: {
    id: 'Building',
    defaultMessage: 'Building',
  },
});

const Edit = (props) => {
  const { data, block, onChangeBlock, selected } = props;

  const intl = useIntl();
  let academic = data.academic ? data.academic + ' ' : '';
  let name =
    data.title || data.firstname
      ? academic +
        (data.firstname && data.name
          ? data.firstname + ' ' + data.name
          : data.title)
      : undefined;

  let building = data.building
    ? data?.building?.includes('building' || 'Building' || 'Gebäude')
      ? data.building
      : intl.formatMessage(messages.building) + ' ' + data.building
    : null;

  let room = data.room
    ? data?.room?.includes('Room' || 'room' || 'Raum')
      ? data.room
      : intl.formatMessage(messages.room) + ' ' + data.room
    : null;

  return (
    <div className="block introduction">
      <div className="wrapper">
        <div className="headline-wrapper">
          <h2 className="blockFirstHeading">{data?.heading}</h2>
        </div>
        <Grid stackable>
          <Grid.Column width={8} className="left">
            <h3 className="bordered">
              <FormattedMessage id="about" defaultMessage="About" />
            </h3>
            <div className="text-body">
              <SimpleSlate {...props} properties={data} dataKey="about" />
            </div>
            <div className="topics">
              <h3 className="bordered">
                <FormattedMessage
                  id="research topics"
                  defaultMessage="Research Topics"
                />
              </h3>
              <SimpleSlate {...props} properties={data} dataKey="topics" />
            </div>
          </Grid.Column>
          <Grid.Column width={4}>
            <h3 className="bordered">
              <FormattedMessage id="contact" defaultMessage="Contact" />
            </h3>
            {data.href &&
              data.href.length > 0 &&
              (data.image ? (
                <div className="image-wrapper">
                  <Image
                    src={`${flattenToAppURL(
                      data?.href?.[0]?.['@id'],
                    )}/@@images/image/preview`}
                    className="leader image"
                  />
                </div>
              ) : (
                <div className="image-wrapper">
                  <Icon
                    name={avatarPlaceholderSVG}
                    size="220px"
                    className="placeholder"
                  ></Icon>
                </div>
              ))}
            {data.href && data.href.length > 0 && (
              <div className="leader details">
                {name && <p className="name">{name}</p>}
                {data.institute && (
                  <p className="institute">{data.institute}</p>
                )}
                <div className="additionals">
                  {(building || room) && (
                    <div className="building">
                      <div className="icon-wrapper building">
                        <Icon
                          name={mapSVG}
                          size="24px"
                          color={
                            data.bg_color === '#023d6b' ? '#fff' : '#023d6b'
                          }
                        ></Icon>
                      </div>
                      <p className="building">
                        {building} {room ? '/' : ''} {room}
                      </p>
                    </div>
                  )}
                  {(data.phone || data.mobile) && (
                    <div className="phone">
                      <div className="icon-wrapper phone">
                        <Icon
                          name={phoneSVG}
                          color={
                            data.bg_color === '#023d6b' ? '#fff' : '#023d6b'
                          }
                          size="20px"
                        ></Icon>
                      </div>
                      <p className="phone">
                        {data.phone ? data.phone : data.mobile}
                      </p>
                    </div>
                  )}
                  {data.email && (
                    <div className="mail">
                      <div className="icon-wrapper mail">
                        <Icon
                          name={emailSVG}
                          color={
                            data.bg_color === '#023d6b' ? '#fff' : '#023d6b'
                          }
                          size="24px"
                        ></Icon>
                      </div>
                      <a
                        href={`mailto:${data.email}`}
                        style={{
                          color: data.bg_color === '#023d6b' && '#fff',
                        }}
                        className="mail"
                      >
                        E-Mail
                      </a>
                    </div>
                  )}
                </div>
              </div>
            )}
          </Grid.Column>
        </Grid>
      </div>

      <SidebarPortal selected={selected}>
        <IntroductionSidebar
          {...props}
          data={data}
          block={block}
          onChangeBlock={onChangeBlock}
        />
      </SidebarPortal>
    </div>
  );
};

export default injectIntl(Edit);
