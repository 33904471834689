import React, { useState } from 'react';
import { SidebarPortal } from '@plone/volto/components';
import cx from 'classnames';
import { defineMessages, useIntl } from 'react-intl';
import Sidebar from './Sidebar';
import { DetachedTextBlockEditor } from 'volto-slate/blocks/Text/DetachedTextBlockEditor';

const messages = defineMessages({
  PleaseAddElements: {
    id: 'To add new Tabs please add elements in the right toolbar',
    defaultMessage: 'To add new Tabs please add elements in the right toolbar',
  },
});

const Edit = (props) => {
  const { data, onChangeBlock } = props;
  const intl = useIntl();
  const [currentTab, setCurrentTab] = useState(0);
  const onChange = (block, object, dataName) => {
    onChangeBlock(block, {
      ...object,
      [dataName]: { value: object.value, plaintext: object.plaintext },
    });
  };

  return (
    <>
      <div className="block tab">
        {(data.columns?.length === 0 || !data.columns) && (
          <p className="add-elements">
            {intl.formatMessage(messages.PleaseAddElements)}
          </p>
        )}
        {data.headline && <h2 className="headline">{data.headline}</h2>}
        {data.columns?.length > 0 && (
          <div className="content">
            <div className="tabs-wrapper">
              {data.columns &&
                data.columns.map((item, index) => (
                  <div
                    className={cx('tabulator', {
                      active: currentTab === index,
                    })}
                    onClick={() => {
                      setCurrentTab(index);
                    }}
                    onKeyDown={() => {
                      setCurrentTab(index);
                    }}
                    role="button"
                    tabIndex={0}
                  >
                    {item?.title}
                  </div>
                ))}
            </div>
            {data.columns &&
              data.columns.map((item, index) => (
                <div
                  className={cx('text-body', { active: currentTab === index })}
                >
                  <DetachedTextBlockEditor
                    {...props}
                    selected={currentTab === index}
                    data={{
                      ...props.data,
                      value:
                        props.data &&
                        props.data[`text-${index}`] &&
                        props.data[`text-${index}`].value,
                      plaintext:
                        props.data &&
                        props.data[`text-${index}`] &&
                        props.data[`text-${index}`].plaintext,
                    }}
                    onChangeBlock={(block, object) =>
                      onChange(block, object, `text-${index}`)
                    }
                  />
                </div>
              ))}
          </div>
        )}
      </div>
      <SidebarPortal selected={props.selected}>
        <Sidebar {...props} />
      </SidebarPortal>
    </>
  );
};
export default Edit;
