import React from 'react';
import useSlots from './useSlots';

const SlotRenderer = ({ name }) => {
  const [hasActiveSlots, active] = useSlots(name);
  if (!hasActiveSlots) return null;

  return active.map(({ component, id, props }) => {
    const Slot = component;
    return <Slot {...props} key={id} id={id} />;
  });
};

export default SlotRenderer;
