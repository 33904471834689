import { defineMessages } from 'react-intl';

const messages = defineMessages({
  iconBlock: {
    id: 'Icon',
    defaultMessage: 'Icon',
  },
  title: {
    id: 'title',
    defaultMessage: 'title',
  },
  description: {
    id: 'description',
    defaultMessage: 'description',
  },
  icon: {
    id: 'Icon',
    defaultMessage: 'Icon',
  },
  svgbest: {
    id: 'It is advised to use a svg graphic.',
    defaultMessage: 'It is advised to use a svg graphic.',
  },
});

export const iconBlockSchema = (props) => {
  return {
    title: props.intl.formatMessage(messages.iconBlock),
    block: 'icon',
    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: ['icon', 'heading', 'description'],
      },
    ],
    properties: {
      icon: {
        title: props.intl.formatMessage(messages.icon),
        widget: 'object_browser',
        mode: 'image',
        allowExternals: true,
        description: props.intl.formatMessage(messages.svgbest),
      },
      heading: {
        title: props.intl.formatMessage(messages.title),
      },
      description: {
        title: props.intl.formatMessage(messages.description),
        widget: 'richtext',
      },
    },
    required: ['icon'],
  };
};
