/**
 * JOB_OFFER Search reducer.
 * @module reducers/job-offer/job-offer-search
 */

import { map } from 'lodash';
import {
  RESET_JOB_OFFER_SEARCH_CONTENT,
  JOB_OFFER_SEARCH_CONTENT,
} from '../../actions/job-offer/job-offer-search';

const initialState = {
  error: null,
  items: [],
  total: 0,
  loaded: false,
  loading: false,
  batching: {},
};

const solrPathToPortalPath = (portal_path, path) => {
  if (path.startsWith(portal_path)) {
    path = path.substr(portal_path.length);
  } else {
    // eslint-disable-next-line
    console.warn(`solrsearch path with invalid prefix "${path}"`);
  }
  return path;
};

const mapSolrItem = (portal_path, item) => {
  const {
    path_string,
    id,
    Title,
    job_offer_institute_title,
    job_offer_location_title,
  } = item;
  return {
    '@id': solrPathToPortalPath(portal_path, path_string),
    id,
    title: Title,
    job_offer_institute_title,
    job_offer_location_title,
  };
};

const getBatching = (action) => {
  // Add this eventually (not sure if it matters)
  return {};
};

const concatItems = (items, start = 0, newItems) => {
  items = [...items];
  items.length = start;
  return items.concat(newItems);
};

/**
 * SOLR job offer search reducer.
 * @function jobOfferSearch
 * @param {Object} state Current state.
 * @param {Object} action Action to be handled.
 * @returns {Object} New state.
 */
export default function jobOfferSearch(state = initialState, action = {}) {
  switch (action.type) {
    case `${JOB_OFFER_SEARCH_CONTENT}_PENDING`:
      return {
        ...state,
        error: null,
        loading: true,
        loaded: false,
      };
    case `${JOB_OFFER_SEARCH_CONTENT}_SUCCESS`:
      return {
        ...state,
        error: null,
        items: concatItems(
          state.items,
          action.result.response.start,
          map(
            action.result.response.docs,
            mapSolrItem.bind(null, action.result.portal_path),
          ),
        ),
        total: action.result.response.numFound,
        loaded: true,
        loading: false,
        batching: getBatching(action),
      };
    case `${JOB_OFFER_SEARCH_CONTENT}_FAIL`:
      return {
        ...state,
        error: action.error,
        items: [],
        total: 0,
        loading: false,
        loaded: false,
        batching: {},
      };
    case RESET_JOB_OFFER_SEARCH_CONTENT:
      return {
        ...state,
        error: null,
        items: [],
        total: 0,
        loading: false,
        loaded: false,
        batching: {},
      };
    default:
      return state;
  }
}
