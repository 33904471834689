import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Container, Button } from 'semantic-ui-react';
import { useHistory, Link } from 'react-router-dom';
import { defineMessages, useIntl } from 'react-intl';
import { onLoad, onDump } from './cart-storage';
import CartSummary from './CartSummary';

const messages = defineMessages({
  errorHasOccurred: {
    id: 'errorHasOccurred',
    defaultMessage: 'An error has occurred',
  },
  back: {
    id: 'back',
    defaultMessage: 'Back',
  },
  thankYou: {
    id: 'thankYou',
    defaultMessage:
      'Thank you for your order. You will receive an acknowledgement email.',
  },
  emptyOrder: {
    id: 'emptyOrder',
    defaultMessage: 'The order is empty.',
  },
});

const CartCheckoutResult = ({ firstCartId }) => {
  const intl = useIntl();
  const history = useHistory();

  const [order, setOrder] = useState();

  useEffect(() => {
    setOrder(onLoad());
    onDump([]);
  }, []);

  const error = useSelector(({ cart: { error } }) => error);
  if (error) {
    const readableError = `${error.status}: ${error.message}`;
    let errorDetails = '';
    try {
      errorDetails = JSON.parse(error.response?.text).message;
    } catch (e) {}
    // eslint-disable-next-line
    console.error(readableError, errorDetails, error.response);
    return (
      <Container id="page-document">
        <h1>{intl.formatMessage(messages.errorHasOccurred)}</h1>
        <p>{readableError}</p>
        <p>{errorDetails}</p>
        <Button
          className="back-button"
          type="reset"
          onClick={() => history.goBack()}
        >
          {intl.formatMessage(messages.back)}
        </Button>
      </Container>
    );
  }

  return (
    <Container>
      {firstCartId ? (
        <>
          <h1>{intl.formatMessage(messages.thankYou)}</h1>
          <CartSummary order={order} />
          {firstCartId ? (
            <Button
              as={Link}
              className="back-button"
              primary
              type="reset"
              to={firstCartId}
            >
              {intl.formatMessage(messages.back)}
            </Button>
          ) : (
            <Button
              className="back-button"
              primary
              type="reset"
              onClick={() => history.goBack()}
            >
              {intl.formatMessage(messages.back)}
            </Button>
          )}
        </>
      ) : (
        <>
          <h1>{intl.formatMessage(messages.emptyOrder)}</h1>
          <Button
            className="back-button"
            primary
            type="reset"
            onClick={() => history.goBack()}
          >
            {intl.formatMessage(messages.back)}
          </Button>
        </>
      )}
    </Container>
  );
};

export default CartCheckoutResult;
