/**
 * Document view component.
 * @module components/theme/View/DefaultView
 */

import React from 'react';
import PropTypes from 'prop-types';
import { defineMessages, injectIntl } from 'react-intl';

import { Container, Image, Grid } from 'semantic-ui-react';
import { map } from 'lodash';
import config from '@plone/volto/registry';

import { Helmet } from '@plone/volto/helpers';

import {
  getBlocksFieldname,
  getBlocksLayoutFieldname,
  hasBlocksData,
  getBaseUrl,
} from '@plone/volto/helpers';

const messages = defineMessages({
  unknownBlock: {
    id: 'Unknown Block',
    defaultMessage: 'Unknown Block {block}',
  },
});

/**
 * Component to display the default view.
 * @function DefaultView
 * @param {Object} content Content object.
 * @returns {string} Markup of the component.
 */
const DefaultView = ({ content, intl, location }) => {
  const blocksFieldname = getBlocksFieldname(content);
  const blocksLayoutFieldname = getBlocksLayoutFieldname(content);

  let MeldungMetadata = {
    '@context': 'https://schema.org/',
    '@type': 'NewsArticle',
    title: content.title,
  };
  if (content.effective) {
    MeldungMetadata['datePublished'] = content.effective;
  }
  if (content.modified) {
    MeldungMetadata['dateModified'] = content.modified;
  }
  if (content.preview_image) {
    MeldungMetadata['image'] = [content.preview_image.download];
  }
  return hasBlocksData(content) ? (
    <Container id="page-document" className="meldung-view">
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify(MeldungMetadata)}
        </script>
      </Helmet>
      {map(content[blocksLayoutFieldname].items, (block) => {
        const Block =
          config.blocks.blocksConfig[
            content[blocksFieldname]?.[block]?.['@type']
          ]?.['view'] || null;
        return Block !== null ? (
          <Block
            key={block}
            id={block}
            properties={content}
            data={content[blocksFieldname][block]}
            path={getBaseUrl(location?.pathname || '')}
          />
        ) : (
          <div key={block}>
            {intl.formatMessage(messages.unknownBlock, {
              block: content[blocksFieldname]?.[block]?.['@type'],
            })}
          </div>
        );
      })}
      {content.relatedItems.length > 0 && (
        <>
          <div
            style={{ textAlign: 'center', fontSize: '20px', marginTop: '46px' }}
          >
            Verwandte Meldungen
          </div>

          <Grid
            className="related-items-container"
            stackable
            columns={content.relatedItems.length.toString()}
          >
            <Grid.Row>
              {content.relatedItems.map((item) => (
                <Grid.Column>
                  <div
                    className="grid-image-wrapper"
                    style={{ marginBottom: '0px' }}
                  >
                    <img
                      src={`${item['@id']}/@@images/preview_image/teaser`}
                      alt=""
                      loading="lazy"
                    />
                  </div>
                  <div className="content">
                    <h3>{item?.title}</h3>
                    <p>{item?.description}</p>
                  </div>
                </Grid.Column>
              ))}
            </Grid.Row>
          </Grid>
        </>
      )}
    </Container>
  ) : (
    <Container id="page-document">
      <h1 className="documentFirstHeading">{content.title}</h1>
      {content.description && (
        <p className="documentDescription">{content.description}</p>
      )}
      {content.image && (
        <Image
          className="document-image"
          src={content.image.scales.thumb.download}
          floated="right"
        />
      )}
      {content.remoteUrl && (
        <span>
          The link address is:
          <a href={content.remoteUrl}>{content.remoteUrl}</a>
        </span>
      )}
      {content.text && (
        <div
          dangerouslySetInnerHTML={{
            __html: content.text.data,
          }}
        />
      )}
    </Container>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
DefaultView.propTypes = {
  /**
   * Content of the object
   */
  content: PropTypes.shape({
    /**
     * Title of the object
     */
    title: PropTypes.string,
    /**
     * Description of the object
     */
    description: PropTypes.string,
    /**
     * Text of the object
     */
    text: PropTypes.shape({
      /**
       * Data of the text of the object
       */
      data: PropTypes.string,
    }),
  }).isRequired,
};

export default injectIntl(DefaultView);
