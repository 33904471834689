import React, { useState } from 'react';
import { Button } from 'semantic-ui-react';
import { Icon } from '@plone/volto/components';
import { Doormat } from '@package/components';
import { useSelector } from 'react-redux';

import { Link } from 'react-router-dom';

import weatherSVG from '@package/icons/noun-weather.svg';
import clearSVG from '@plone/volto/icons/clear.svg';
import presseSVG from '@package/icons/presse.svg';
import sitemapSVG from '@package/icons/sitemap.svg';
import signLanguageSVG from '@package/icons/noun-sign-language.svg';
import bookSVG from '@package/icons/noun-reading.svg';
import mailSVG from '@package/icons/mail.svg';
import lockSVG from '@package/icons/noun-lock.svg';

import { LanguageSelector } from '@plone/volto/components';

const IconHeader = ({ mobile = false, onClose = null }) => {
  const [showSitemap, setShowSitemap] = useState(false);
  const language = useSelector((state) => state.intl.locale);

  const modalHandler = () => {
    setShowSitemap(!showSitemap);
  };

  return (
    <>
      <div
        className={
          mobile
            ? 'header-top mobile-navicons'
            : 'header-top mobile hidden tablet hidden'
        }
      >
        <ul className="links">
          <li>
            {language === 'de' ? (
              <Link
                className="presse-button"
                to="/de/press"
                onClick={onClose}
                title="Presse"
              >
                Presse &nbsp;
                <Icon name={presseSVG} size="18px" />
              </Link>
            ) : (
              <Link
                className="presse-button"
                to="/en/press"
                onClick={onClose}
                title="Press"
              >
                Press &nbsp;
                <Icon name={presseSVG} size="18px" />
              </Link>
            )}
          </li>
          <li>
            <Button
              className="sitemap-button"
              title="Sitemap"
              onClick={modalHandler}
            >
              Sitemap &nbsp;
              <Icon name={sitemapSVG} size="18px" />
            </Button>
          </li>
          <li>
            {language === 'de' ? (
              <Link
                className="intranet-button"
                to="/de/intranet"
                onClick={onClose}
                title="Intranet"
              >
                Intranet &nbsp;
                <Icon name={lockSVG} size="18px" />
              </Link>
            ) : (
              <Link
                className="intranet-button"
                to="/en/intranet"
                onClick={onClose}
                title="Intranet"
              >
                Intranet &nbsp;
                <Icon name={lockSVG} size="18px" />
              </Link>
            )}
          </li>
        </ul>
        <ul className="links-tools">
          <li>
            <a
              href="https://go.fzj.de/wetterdaten"
              title={language === 'de' ? 'Wetterdaten' : 'Weather Information'}
              target="_blank"
              rel="noreferrer"
            >
              <Icon
                name={weatherSVG}
                size={!mobile ? '30px' : '34px'}
                className="weather-icon"
              />
            </a>
          </li>
          {language === 'de' && (
            <li>
              <Link
                to={'/de/ueber-uns/gebaerdensprache'}
                onClick={onClose}
                title="Gebärdensprache"
              >
                <Icon
                  name={signLanguageSVG}
                  size={!mobile ? '28px' : '35px'}
                  className="sign-language-icon"
                />
              </Link>
            </li>
          )}
          {language === 'de' && (
            <li>
              <Link
                to={'/de/ueber-uns/leichte-sprache'}
                onClick={onClose}
                title="Leichte Sprache"
              >
                <Icon
                  name={bookSVG}
                  size={!mobile ? '24px' : '28px'}
                  className="book-icon"
                />
              </Link>
            </li>
          )}
          <li>
            {language === 'de' ? (
              <Link
                to={'/de/ueber-uns/kontaktinformationen'}
                onClick={onClose}
                aria-label="mail icon"
                title="Kontakt"
              >
                <Icon
                  className="mail-icon"
                  name={mailSVG}
                  size={!mobile ? '17px' : '21px'}
                />
              </Link>
            ) : (
              <a
                href="https://go.fzj.de/contact"
                aria-label="mail icon"
                title="Contact"
                target="_blank"
                rel="noreferrer"
              >
                <Icon
                  className="mail-icon"
                  name={mailSVG}
                  size={!mobile ? '17px' : '21px'}
                />
              </a>
            )}
          </li>
        </ul>
        {!mobile && <LanguageSelector />}
      </div>
      {showSitemap && (
        <div>
          <button onClick={modalHandler} className="close-icon">
            <Icon name={clearSVG} size="32px" color="#fff" />
          </button>
          <div
            role="button"
            className="modal"
            onKeyDown={modalHandler}
            onClick={modalHandler}
            tabIndex={0}
          >
            <Doormat columns="3" />
          </div>
        </div>
      )}
    </>
  );
};

export default IconHeader;
