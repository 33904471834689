import React, { useEffect } from 'react';
import { FormattedMessage, defineMessages, injectIntl } from 'react-intl';
import {
  Container,
  Segment,
  Button,
  Grid,
  Form,
  Input,
} from 'semantic-ui-react';
import Cookies from 'universal-cookie';
import { useLocation, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { Icon, Toast } from '@plone/volto/components';
import { getContent } from '@plone/volto/actions';
import { Helmet, getBaseUrl } from '@plone/volto/helpers';
import { apiURLForContent } from '../../helpers';
import aheadSVG from '@plone/volto/icons/ahead.svg';

import { postUnlockForm } from '../../actions';

const messages = defineMessages({
  rfFormTitle: {
    id: 'rfFormTitle',
    defaultMessage: 'Restricted Content',
  },
  rfProvideToken: {
    id: 'rfProvideToken',
    defaultMessage: 'Please provide a token',
  },
  rfTokenPlaceholder: {
    id: 'rfTokenPlaceholder',
    defaultMessage: 'Inform the token',
  },
  rfTokenSubmit: {
    id: 'rfTokenSubmit',
    defaultMessage: 'Submit token',
  },
  rfInvalidToken: {
    id: 'rfInvalidToken',
    defaultMessage: 'Invalid token',
  },
  rfInvalidTokenContent: {
    id: 'rfInvalidTokenContent',
    defaultMessage: 'This token is not valid for the content',
  },
  rfValidToken: {
    id: 'rfValidToken',
    defaultMessage: 'Valid token',
  },
  rfValidTokenContent: {
    id: 'rfValidTokenContent',
    defaultMessage: 'You can now download the restricted content',
  },
});

function UnlockFormView({ intl }) {
  const cookies = new Cookies();
  const dispatch = useDispatch();
  const pathname = getBaseUrl(useLocation().pathname);
  const content = useSelector((state) => state.content.data);
  const formState = useSelector((state) => state.unlockform);
  const history = useHistory();

  useEffect(() => {
    dispatch(getContent(pathname));
  }, [dispatch, pathname]);

  useEffect(() => {
    if (formState.loaded) {
      // Save cookie
      cookies.set('__rf', formState.token, {
        path: '/',
      });
      toast.success(
        <Toast
          title={intl.formatMessage(messages.rfValidToken)}
          content={intl.formatMessage(messages.rfValidTokenContent)}
        />,
        { autoClose: true, toastId: 'validToken' },
      );
      history.push(pathname);
    }
  }, [intl, pathname, history, formState.loaded, cookies, formState.token]);

  useEffect(() => {
    if (formState.error && !toast.isActive('invalidToken')) {
      toast.error(
        <Toast
          error
          title={intl.formatMessage(messages.rfInvalidToken)}
          content={intl.formatMessage(messages.rfInvalidTokenContent)}
        />,
        { autoClose: false, toastId: 'invalidToken' },
      );
    } else if (!formState.error && toast.isActive('invalidToken')) {
      toast.dismiss('invalidToken');
    }
  }, [intl, pathname, formState.error]);

  const onSubmit = (event) => {
    const path = apiURLForContent(content['@id']);
    const token = document.getElementsByName('token')[0].value;
    dispatch(postUnlockForm(path, token));
    event.preventDefault();
  };

  return (
    <div id="page-login">
      <Helmet title={intl.formatMessage(messages.rfFormTitle)} />
      <Container text>
        <Form method="post" onSubmit={onSubmit}>
          <Segment.Group raised>
            <Segment className="primary">
              <FormattedMessage
                id="RestrictedContent"
                defaultMessage="Restricted Content"
              />
            </Segment>
            <Segment secondary>
              <FormattedMessage
                id="rfProvideToken"
                defaultMessage="Please provide a token"
              />
            </Segment>
            <Segment className="form">
              <Form.Field inline className="help">
                <Grid>
                  <Grid.Row stretched>
                    <Grid.Column width="4">
                      <div className="wrapper">
                        <label htmlFor="login">
                          <FormattedMessage
                            id="Token"
                            defaultMessage="Access token"
                          />
                        </label>
                      </div>
                    </Grid.Column>
                    <Grid.Column width="8">
                      {/* eslint-disable jsx-a11y/no-autofocus */}
                      <Input
                        id="token"
                        name="token"
                        placeholder={intl.formatMessage(
                          messages.rfTokenPlaceholder,
                        )}
                        autoFocus
                      />
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Form.Field>
            </Segment>
            <Segment className="actions" clearing>
              <Button
                basic
                primary
                floated="right"
                type="submit"
                id="token-form-submit"
                aria-label={intl.formatMessage(messages.rfTokenSubmit)}
                title={intl.formatMessage(messages.rfTokenSubmit)}
              >
                <Icon className="circled" name={aheadSVG} size="30px" />
              </Button>
            </Segment>
          </Segment.Group>
        </Form>
      </Container>
    </div>
  );
}

export default injectIntl(UnlockFormView);
