import React from 'react';
import { SchemaRenderer } from '@kitconcept/volto-blocks/components';
import { AltmetricSchema } from './schema';
import { useIntl, defineMessages } from 'react-intl';

const messages = defineMessages({
  AltmetricBlock: {
    id: 'AltmetricBlock',
    defaultMessage: 'Altmetric Block',
  },
});

const AltmetricData = (props) => {
  const { data, block, onChangeBlock, schemaEnhancer } = props;
  const intl = useIntl();
  const schema = schemaEnhancer
    ? schemaEnhancer(AltmetricSchema({ ...props, intl }), props)
    : AltmetricSchema({ ...props, intl });

  return (
    <SchemaRenderer
      schema={schema}
      title={intl.formatMessage(messages.AltmetricBlock)}
      onChangeField={(id, value) => {
        onChangeBlock(block, {
          ...data,
          [id]: value,
        });
      }}
      formData={data}
      fieldIndex={data.index}
      basic
      unwrapped
      block={block}
    />
  );
};

export default AltmetricData;
