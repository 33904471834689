/**
 * Header component.
 * @module components/theme/Header/Header
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import { getNearestInstitut } from '@package/actions';
import InstitutHeader from '@package/components/Header/InstitutHeader';
import MainHeader from '@package/components/Header/MainHeader';
import ProjectHeader from '@package/components/Header/ProjectHeader';
import HiERNHeader from '@package/components/Header/HiERNHeader';
import { InlineProjectCustomCSS } from '@package/components';
import config from '@plone/volto/registry';

/**
 * Header component class.
 * @class Header
 * @extends Component
 */
class Header extends Component {
  /**
   * Property types.
   * @property {Object} propTypes Property types.
   * @static
   */
  static propTypes = {
    token: PropTypes.string,
    pathname: PropTypes.string.isRequired,
  };

  /**
   * Default properties.
   * @property {Object} defaultProps Default properties.
   * @static
   */
  static defaultProps = {
    token: null,
  };

  // componentDidMount() {
  //   this.props.getNearestInstitut(this.props.pathname);
  // }

  // componentDidUpdate(prevProps) {
  //   // This is required because we want it to change in case that we edit and change something
  //   // Header is getting getBaseUrl, so it's not updating itself on edit :(
  //   if (prevProps.realpathname !== this.props.realpathname) {
  //     this.props.getNearestInstitut(this.props.realpathname);
  //   }
  // }

  /**
   * Render method.
   * @method render
   * @returns {string} Markup for the component.
   */
  render() {
    const { settings } = config;
    const isHiERNProject = settings.isHiERNProject === 'true' ? true : false;

    return (
      <>
        <InlineProjectCustomCSS />
        {isEmpty(this.props.institut) &&
          isEmpty(this.props.subsite) &&
          !settings.isFZJProject &&
          !isHiERNProject && <MainHeader {...this.props} />}
        {(!isEmpty(this.props.institut) || !isEmpty(this.props.subsite)) &&
          !settings.isFZJProject && <InstitutHeader {...this.props} />}
        {settings.isFZJProject && <ProjectHeader {...this.props} />}
        {isHiERNProject && <HiERNHeader {...this.props} />}
      </>
    );
  }
}

export default connect(
  (state, props) => ({
    realpathname: state.router.location.pathname,
    token: state.userSession.token,
    institut: state.nearestinstitut.institut,
    institutsbereich: state.nearestinstitut.institutsbereich,
    subsite: state.nearestinstitut.subsite,
    hideInstitut: state.nearestinstitut.hideInstitut,
  }),
  {
    getNearestInstitut,
  },
)(Header);
