import React from 'react';
import { SidebarPortal } from '@plone/volto/components';

import View from './View';
import JobFilterData from './JobFilterData';

const JobFilterBlockEdit = (props) => {
  const { data, selected, block, onChangeBlock } = props;
  return (
    <>
      <View {...props} />
      <SidebarPortal selected={selected}>
        <JobFilterData
          data={data}
          block={block}
          onChangeBlock={onChangeBlock}
        />
      </SidebarPortal>
    </>
  );
};

export default JobFilterBlockEdit;
