import React, { useState } from 'react';
import { injectIntl } from 'react-intl';
import cx from 'classnames';
import { Icon } from '@plone/volto/components';
import { AwardOverlay, AwardTopline } from '@package/components';

import memberSVG from '@package/icons/fallback-avatar-darkblue.svg';
import awardSVG from '@package/icons/award.svg';

const AwardsListing = ({ items, isEditMode }) => {
  const [overlay, setOverlay] = useState(null);

  return (
    <div className="awards">
      {items.map((item) => (
        <>
          {overlay === item['@id'] && (
            <AwardOverlay award={item} onClose={() => setOverlay(null)} />
          )}
          <div
            className={cx('award', { edit: isEditMode })}
            onClick={() => !isEditMode && setOverlay(item['@id'])}
            role="button"
            onKeyDown={() => !isEditMode && setOverlay(item['@id'])}
            tabIndex={0}
          >
            <div className="left content">
              <AwardTopline award={item} />
              <h2>{item.title}</h2>
              {item.member_fullnames &&
                item.member_fullnames.length > 0 &&
                item.member_fullnames.map((fullname) => (
                  <div className="member">
                    <div className="icon-wrapper">
                      <Icon name={memberSVG} size="30px" />
                    </div>
                    <span className="name">{fullname}</span>
                  </div>
                ))}
            </div>
            <div className="right symbol">
              <div className="icon-wrapper">
                <Icon name={awardSVG} size="105px" />
              </div>
            </div>
          </div>
        </>
      ))}
    </div>
  );
};

export default injectIntl(AwardsListing);
