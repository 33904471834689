import React from 'react';
import { Container, Segment, Button } from 'semantic-ui-react';
import { Icon } from '@plone/volto/components';
import { Doormat, StickyMenu, MobileNavigation } from '@package/components';
import { isEmpty } from 'lodash';
import { defineMessages, useIntl } from 'react-intl';
import { Link, useHistory } from 'react-router-dom';

import clearSVG from '@plone/volto/icons/clear.svg';
import zoomSVG from '@package/icons/search-icon.svg';

import { flattenToAppURL } from '@plone/volto/helpers';
import { Logo, Navigation, LanguageSelector } from '@plone/volto/components';

const messages = defineMessages({
  search: {
    id: 'Search',
    defaultMessage: 'Search',
  },
  searchSite: {
    id: 'Search Site',
    defaultMessage: 'Search Site',
  },
  institute: {
    id: 'Institute',
    defaultMessage: 'Institute',
  },
});

const MainHeader = (props) => {
  const { pathname, institut, institutsbereich, subsite } = props;
  const [showSitemap, setShowSitemap] = React.useState(false);
  const intl = useIntl();
  const history = useHistory();

  const rootPathname = flattenToAppURL(
    institutsbereich?.['@id'] || institut?.['@id'] || subsite?.['@id'],
  );
  const searchUrl =
    rootPathname.replace(/\/$/, '').replace(/\/$/, '') +
    '/@@search?allow_local=true&local=true';

  const modalHandler = () => {
    setShowSitemap(!showSitemap);
  };

  return (
    <Segment basic className="header-wrapper institut" role="banner">
      <Container>
        <div className="header">
          <div className="header-top">
            <LanguageSelector />

            {/* <div className="search">
              <SearchWidget pathname={pathname} />
            </div> */}
            {/* <ul>
              <li>
                <img src={wetterPNG} alt="wetter icon" />
                Wetter
              </li>
              <li className="mobile hidden">
                <LanguageSelector />
              </li>
              <li>Kontakt/Anfahrt</li>
              <li>
                <Button className="sitemap-button" onClick={modalHandler}>
                  Sitemap
                </Button>
              </li>
            </ul> */}
            {/* <div className="mobile only">
              <LanguageSelector />
            </div> */}
          </div>
          <div className="logo-nav-wrapper">
            <div className="logo">
              <Logo />
              {!isEmpty(institut) && (
                <div className="institut-title">
                  {institutsbereich.name ? (
                    <>
                      <Link to={flattenToAppURL(institut['@id'])}>
                        <h2>{institut.name}</h2>
                      </Link>
                      <Link to={flattenToAppURL(institutsbereich?.['@id'])}>
                        <h1>{institutsbereich.name}</h1>
                      </Link>
                    </>
                  ) : (
                    <>
                      {!props.institut.is_geschaeftsbereich_or_stabstelle && (
                        <h2>{intl.formatMessage(messages.institute)}</h2>
                      )}
                      <Link to={flattenToAppURL(institut['@id'])}>
                        <h1>{institut.name}</h1>
                      </Link>
                    </>
                  )}
                </div>
              )}
              {!isEmpty(subsite) && (
                <div className="institut-title">
                  <Link to={flattenToAppURL(subsite['@id'])}>
                    <h1>{subsite.name}</h1>
                  </Link>
                </div>
              )}
            </div>
            <MobileNavigation />
            <Link
              to={searchUrl}
              className="search mobile-nav mobile only tablet only"
              title="Search"
            >
              <Button
                className="institut-search"
                basic
                icon
                aria-label={intl.formatMessage(messages.search)}
              >
                <Icon name={zoomSVG} size="36px" />
              </Button>
            </Link>
          </div>
          <div className="institut-nav">
            <Navigation pathname={pathname} />
            <Button
              className="institut-search computer large screen widescreen only"
              basic
              icon
              aria-label={intl.formatMessage(messages.search)}
              onClick={() => history.push(searchUrl)}
            >
              <Icon name={zoomSVG} size="36px" />
            </Button>
          </div>
          {showSitemap && (
            <div>
              <button onClick={modalHandler} className="close-icon">
                <Icon name={clearSVG} size="32px" color="#fff" />
              </button>
              <div
                role="button"
                className="modal"
                onKeyDown={modalHandler}
                onClick={modalHandler}
                tabIndex={0}
              >
                <Doormat columns="3" />
              </div>
            </div>
          )}
          {/* <div className="tools-search-wrapper">
          {!token && (
            <div className="tools">
              <Anontools />
            </div>
          )}
        </div> */}
        </div>
        <StickyMenu
          institut={institut}
          institutsbereich={institutsbereich}
          subsite={subsite}
        />
      </Container>
    </Segment>
  );
};

export default MainHeader;
