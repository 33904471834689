import { useState, useEffect } from 'react';
import { Container, Modal, Image, Input, Button } from 'semantic-ui-react';
import CartController from './CartController';
import { searchContent } from '@plone/volto/actions';
import { useDispatch, useSelector } from 'react-redux';
import { flattenToAppURL } from '@plone/volto/helpers';
import deleteSVG from '@plone/volto/icons/delete.svg';
import { Icon } from '@plone/volto/components';
import { onLoad, onDump } from './cart-storage';
import { defineMessages, useIntl } from 'react-intl';
import { useHistory, Link } from 'react-router-dom';
import { clearCartOrder } from '../../actions';

const messages = defineMessages({
  amountColumn: {
    id: 'amountColumn',
    defaultMessage: 'Amount', // Menge
  },
  previewColumn: {
    id: 'previewColumn',
    defaultMessage: 'Preview', // Ansicht
  },
  descriptionColumn: {
    id: 'descriptionColumn',
    defaultMessage: 'Description', // Beschreibung
  },
  continueShopping: {
    id: 'continueShopping',
    defaultMessage: 'Continue shopping',
  },
  checkout: {
    id: 'checkout',
    defaultMessage: 'Checkout',
  },
  contentOfCart: {
    id: 'contentOfCart',
    defaultMessage: 'Content of you cart',
  },
});

const CartBasket = (props) => {
  const [controller, updateController] = useState(
    new CartController({ onLoad, onDump }),
  );
  const [currentId, setCurrentId] = useState();
  const [storeId, setStoreId] = useState();

  const items = useSelector((state) =>
    state.search.subrequests.cartitems?.items.filter(
      (item) => item.parent['@type'] === 'Cart',
    ),
  );
  const dispatch = useDispatch();
  const intl = useIntl();
  const history = useHistory();

  useEffect(() => {
    dispatch(clearCartOrder());
    dispatch(
      searchContent(
        '/',
        {
          portal_type: ['CartItem'],
          fullobjects: true,
          b_size: 100000000,
        },
        'cartitems',
      ),
    );
  }, [dispatch]);

  useEffect(() => {
    setCurrentId('/' + props.match?.params[0]);
  }, [props.match?.params]);

  useEffect(() => {
    // We only add the item to the cart if after loading we see that it's a cart item.
    if (items && items.find((item) => item['@id'] === currentId)) {
      updateController((controller) => controller.load().addDefault(currentId));
      // Find out the store id for a better redirection
      const lastSegment = currentId.lastIndexOf('/');
      if (lastSegment !== -1) {
        setStoreId(currentId.substr(0, lastSegment));
      }
      // Disable adding back the item if manually deleted by the user
      setCurrentId(null);
    } else {
      // Any other context than an actual item just shows the cart anyway
      updateController((controller) => controller.load());
    }
  }, [items, currentId]);

  return (
    <Container id="page-document">
      <Modal open={true}>
        <Modal.Header>
          {intl.formatMessage(messages.contentOfCart)}
        </Modal.Header>
        <Modal.Content>
          <table className="ui celled table inverted cart-table">
            <thead>
              <tr>
                <th>{intl.formatMessage(messages.amountColumn)}</th>
                <th>{intl.formatMessage(messages.previewColumn)}</th>
                <th>{intl.formatMessage(messages.descriptionColumn)}</th>
              </tr>
            </thead>
            <tbody>
              {controller.items ? (
                controller.items.map((chartItem) => {
                  const item = items
                    ? items.find((item) => item['@id'] === chartItem.id)
                    : undefined;
                  return (
                    <tr key={chartItem.id}>
                      <td className="amount-col">
                        <Input
                          className="amount-input"
                          type="number"
                          value={chartItem.amount}
                          onChange={(_, { value }) => {
                            const amount = value ? parseInt(value, 10) : 0;
                            if (amount >= 0) {
                              updateController((controller) =>
                                controller.set({
                                  ...chartItem,
                                  amount,
                                }),
                              );
                            } else {
                              return false;
                            }
                          }}
                        />
                        <Icon
                          name={deleteSVG}
                          className="amount-icon"
                          onClick={() =>
                            updateController((controller) =>
                              controller.del(chartItem.id),
                            )
                          }
                        />
                      </td>
                      <td>
                        <Image
                          src={flattenToAppURL(
                            item?.preview_image?.scales?.preview?.download,
                          )}
                          size="small"
                          floated="right"
                          alt={item?.image_caption}
                          avatar
                        />
                      </td>
                      <td
                        dangerouslySetInnerHTML={{ __html: item?.text?.data }}
                      ></td>
                    </tr>
                  );
                })
              ) : (
                <tr></tr>
              )}
            </tbody>
          </table>
        </Modal.Content>
        <Modal.Actions>
          {storeId ? (
            <Button as={Link} className="back-button" to={storeId}>
              {intl.formatMessage(messages.continueShopping)}
            </Button>
          ) : (
            <Button className="back-button" onClick={() => history.goBack()}>
              {intl.formatMessage(messages.continueShopping)}
            </Button>
          )}
          <Button
            as={Link}
            className="checkout-button"
            primary
            to="/@cart-checkout"
          >
            {intl.formatMessage(messages.checkout)}
          </Button>
        </Modal.Actions>
      </Modal>
    </Container>
  );
};

export default CartBasket;
