import { GET_SEARCHSUGGESTIONS } from '@package/constants/ActionTypes';

export function searchSuggestions(term) {
  return {
    type: GET_SEARCHSUGGESTIONS,
    request: {
      op: 'get',
      path: `/@solr-suggest?query=${term}`,
    },
  };
}
