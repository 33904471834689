import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { ObjectListWidget } from '@plone/volto/components';

const messages = defineMessages({
  Source: {
    id: 'Source',
    defaultMessage: 'Source',
  },
  title: {
    id: 'Title',
    defaultMessage: 'Title',
  },
  icon: {
    id: 'Icon',
    defaultMessage: 'Icon',
  },
  description: {
    id: 'Description',
    defaultMessage: 'Description',
  },
  imageOverride: {
    id: 'Image override',
    defaultMessage: 'Image override',
  },
  item: {
    id: 'Item',
    defaultMessage: 'Item',
  },
  items: {
    id: 'Items',
    defaultMessage: 'Items',
  },
  addItem: {
    id: 'Add item',
    defaultMessage: 'Add item',
  },
  headline: {
    id: 'Headline',
    defaultMessage: 'Headline',
  },
  itemsToShow: {
    id: 'Items to show',
    defaultMessage: 'Items to show',
  },
  hideDescription: {
    id: 'Hide description',
    defaultMessage: 'Hide description',
  },
  settings: {
    id: 'Settings',
    defaultMessage: 'Settings',
  },
});

const StickyMenuWidget = (props) => {
  const { value } = props;
  const intl = useIntl();

  function onChange(id, value) {
    props.onChange(id, { items: value });
  }

  function schema(props) {
    return {
      title: intl.formatMessage(messages.item),
      addMessage: intl.formatMessage(messages.addItem),
      fieldsets: [
        {
          id: 'default',
          title: 'Default',
          fields: ['title', 'icon', 'href'],
        },
      ],

      properties: {
        title: {
          title: intl.formatMessage(messages.title),
        },
        href: {
          title: intl.formatMessage(messages.Source),
          widget: 'object_browser',
          mode: 'link',
          selectedItemAttrs: ['Title', 'Description', 'hasPreviewImage'],
          allowExternals: true,
        },
        icon: {
          title: intl.formatMessage(messages.icon),
          choices: [
            ['anfahrt', 'Anfahrt'],
            ['ansprechpartner', 'Ansprechpartner'],
            ['az', 'AZ'],
            ['downloads', 'Downloads'],
            ['facebook', 'Facebook'],
            ['instagram', 'Instagram'],
            ['lexicon', 'Lexicon'],
            ['lupe', 'Lupe'],
            ['medienspiegel', 'Medienspiegel'],
            ['mitarbeiter', 'Mitarbeiter'],
            ['publikationen', 'Publikationen'],
            ['rss', 'RSS'],
            ['termine', 'Termine'],
            ['twitter', 'Twitter'],
            ['warenkorb', 'Warenkorb'],
            ['youtube', 'Youtube'],
          ],
          noValueOption: false,
        },
      },
      required: [],
    };
  }

  return (
    <ObjectListWidget
      {...props}
      value={value?.items}
      schema={schema}
      onChange={onChange}
    />
  );
};

export default StickyMenuWidget;
