/**
 * Footer component.
 * @module components/theme/Footer/Footer
 */

import React, { useEffect, useState } from 'react';
import { Container, Button, Grid, Segment } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
// import { Doormat } from '@package/components';
import { Icon } from '@plone/volto/components';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import {
  ProjectFooter,
  PortalFooter,
  InstitutsbereichFooter,
} from '@package/components';
import { ErrorBoundary } from 'react-error-boundary';
import config from '@plone/volto/registry';
import cx from 'classnames';

import printSVG from '@package/icons/print.svg';
import xSVG from '@package/icons/x.svg';
import mastodonSVG from '@package/icons/mastodon.svg';
import linkedinSVG from '@package/icons/linkedin.svg';
import instagramSVG from '@package/icons/instagram.svg';
import threadsSvg from '@package/icons/threads.svg';
import logoSVG from '@package/icons/helmholtz-logo.svg';
import upSVG from '@plone/volto/icons/collapse-up.svg';
import rssSVG from '@package/icons/rss.svg';

function ErrorFallback({ error }) {
  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <pre style={{ color: 'red' }}>{error.message}</pre>
    </div>
  );
}

/**
 * Component to display the footer.
 * @function Footer
 * @param {Object} intl Intl object
 * @returns {string} Markup of the component
 */
const Footer = (props) => {
  const { isFZJProject } = config.settings;
  const [data, setData] = useState({});
  const [parent, setParent] = useState({});
  const [footerType, setFooterType] = useState('Portal');
  const institutsBereichFooterTypes = [
    'Institut',
    'Institutsbereich',
    'Subsite',
  ];

  const nearestInstitut = useSelector((state) => state.nearestinstitut);
  const language = useSelector((state) => state.intl.locale);
  const content = useSelector((state) => state.content.data);
  const hideSocialMedia =
    Object.keys(nearestInstitut.institut).length > 0 ||
    Object.keys(nearestInstitut.institutsbereich).length > 0 ||
    Object.keys(nearestInstitut.subsite).length > 0;

  useEffect(() => {
    if (
      Object.keys(nearestInstitut.institut).length > 0 &&
      Object.keys(nearestInstitut.institutsbereich).length === 0 &&
      Object.keys(nearestInstitut.subsite).length === 0
    ) {
      setData(nearestInstitut.institut);
      setFooterType('Institut');
    } else if (Object.keys(nearestInstitut.subsite).length > 0) {
      setFooterType('Subsite');
      setData(nearestInstitut.subsite);
    } else if (Object.keys(nearestInstitut.institutsbereich).length > 0) {
      setFooterType('Institutsbereich');
      setData(nearestInstitut.institutsbereich);
      setParent(nearestInstitut.institut);
    } else {
      setData(nearestInstitut.lrf);
      setFooterType('Portal');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nearestInstitut]);

  return isFZJProject ? (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <ProjectFooter />
    </ErrorBoundary>
  ) : (
    <footer id="footer">
      <div className="footer-tools">
        <Container>
          <Grid stackable columns="3">
            <Grid.Column className="print">
              <Button basic icon onClick={() => window.print()}>
                <Icon name={printSVG} size="24px" />
                <FormattedMessage id="print" defaultMessage="print" />
              </Button>
            </Grid.Column>
            <Grid.Column className="scroll-to-top">
              <AnchorLink offset="0" href="#main">
                <Button basic icon>
                  <Icon name={upSVG} size="24px" />
                  <FormattedMessage
                    id="to the top"
                    defaultMessage="to the top"
                  />
                </Button>
              </AnchorLink>
            </Grid.Column>
            <Grid.Column
              className={cx('social-media', { hide: hideSocialMedia })}
            >
              <a
                aria-label="fz-juelich Mastodon"
                href="https://social.fz-juelich.de/@fzj"
                target="_blank"
                rel="noreferrer"
              >
                <Icon name={mastodonSVG} size="24px" />
              </a>
              <a
                aria-label="fz-juelich LinkedIn"
                href="http://www.linkedin.com/company/forschungszentrum-julich"
                target="_blank"
                rel="noreferrer"
              >
                <Icon name={linkedinSVG} size="24px" />
              </a>
              <a
                aria-label="fz-juelich Instagram"
                href="https://www.instagram.com/forschungszentrum_juelich/"
                target="_blank"
                rel="noreferrer"
              >
                <Icon name={instagramSVG} size="24px" />
              </a>
              <a
                aria-label="fz-juelich Threads"
                href="https://www.threads.net/@forschungszentrum_juelich"
                target="_blank"
                rel="noreferrer"
              >
                <Icon name={threadsSvg} size="24px" />
              </a>
              <a
                aria-label="fz-juelich X"
                href="https://twitter.com/fz_juelich "
                target="_blank"
                rel="noreferrer"
              >
                <Icon name={xSVG} size="24px" />
              </a>
              <a
                aria-label="fz-juelich RSS-Feed"
                href="https://go.fzj.de/rss-feed"
              >
                <Icon name={rssSVG} size="24px" />
              </a>
            </Grid.Column>
          </Grid>
        </Container>
      </div>
      {institutsBereichFooterTypes?.includes(footerType) ? (
        <InstitutsbereichFooter
          content={data}
          parent={content?.['@type'] === 'Institutsbereich' ? parent : null}
        />
      ) : content && content?.['@type'] === 'Member' ? (
        <></>
      ) : (
        <PortalFooter content={data} />
      )}
      {/* <Doormat columns="6" /> */}
      <div className="footer-bottom">
        {/* <Segment role="contentinfo" vertical padded textAlign="center"> */}
        <Segment vertical padded textAlign="center">
          <Container>
            <span className="copyright">© Forschungszentrum Jülich</span>
            <div className="left">
              <ul className="links">
                <li className="item">
                  {language === 'de' ? (
                    <Link to="/de/impressum">
                      <FormattedMessage
                        id="Legal Notice"
                        defaultMessage="Legal Notice"
                      />
                    </Link>
                  ) : (
                    <Link to="/en/legal-notice">
                      <FormattedMessage
                        id="Legal Notice"
                        defaultMessage="Legal Notice"
                      />
                    </Link>
                  )}
                </li>
                <li className="item bordered">
                  {language === 'de' ? (
                    <Link to="/de/datenschutz">
                      <FormattedMessage
                        id="Data Protection"
                        defaultMessage="Data Protection"
                      />
                    </Link>
                  ) : (
                    <Link to="/en/privacy-policy">
                      <FormattedMessage
                        id="Data Protection"
                        defaultMessage="Data Protection"
                      />
                    </Link>
                  )}
                </li>
                <li className="item">
                  {language === 'de' ? (
                    <Link to="/de/erklaerung-zur-barrierefreiheit">
                      <FormattedMessage
                        id="Accessibility"
                        defaultMessage="Accessibility"
                      />
                    </Link>
                  ) : (
                    <Link to="/en/declaration-of-accessibility">
                      <FormattedMessage
                        id="Accessibility"
                        defaultMessage="Accessibility"
                      />
                    </Link>
                  )}
                </li>
              </ul>
            </div>
            <div className="helmholtz-logo">
              <a
                href="https://www.helmholtz.de/"
                className="helmholtz"
                aria-label="Helmholtz-Logo"
              >
                <Icon name={logoSVG} size="32px" />
              </a>
            </div>
          </Container>
        </Segment>
      </div>
    </footer>
  );
};

export default injectIntl(Footer);
