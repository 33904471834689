/*
Reference: https://api.altmetric.com/embeds.html
*/
import React, { useEffect } from 'react';

const loadScript = (callback) => {
  const existingScript = document.getElementById('AltmetricsScript');
  if (!existingScript) {
    const script = document.createElement('script');
    script.src = 'https://d1bxh8uas1mnw7.cloudfront.net/assets/embed.js';
    script.id = 'AltmetricsScript';
    script.async = true;
    document.body.appendChild(script);
    script.onload = () => {
      if (callback) callback();
    };
  }
  if (existingScript && callback) callback();
};

const View = (props) => {
  const blockId = props['id'];
  const [loaded, setLoaded] = React.useState(false);
  React.useEffect(() => {
    loadScript(() => {
      setLoaded(true);
    });
  }, []);

  const { data } = props;
  const dataParams = {
    className: 'altmetric-embed',
    'data-badge-type': data['badge-type'],
    'data-condensed': data['condensed'],
    'data-link-target': '_blank',
    'data-hide-no-mentions': data['hide-no-mentions'] ? true : false,
  };
  dataParams[`data-${data['idType']}`] = data['idValue'];
  if (data['badge-details']) {
    dataParams['data-badge-details'] = data['badge-details'];
  }
  if (data['badge-popover']) {
    dataParams['data-badge-popover'] = data['badge-popover'];
  }
  useEffect(() => {
    if (__CLIENT__ && loaded && window._altmetric_embed_init !== undefined) {
      window._altmetric_embed_init();
    }
  }, [JSON.stringify(dataParams), blockId, loaded]); // eslint-disable-line
  return (
    <>
      {data && (
        <div className="block altmetric">
          <div {...dataParams}></div>
        </div>
      )}
    </>
  );
};

export default View;
