import React from 'react';
import { Container, Grid } from 'semantic-ui-react';
import { SlotRenderer } from '@package/components';
import { matchPath, useLocation } from 'react-router-dom';
import { isEmpty } from 'lodash';
import config from '@plone/volto/registry';
import { BodyClass } from '@plone/volto/helpers';
import { useSelector } from 'react-redux';

const LANDING_PAGES = ['LFR', 'Institut', 'Institutsbereich'];

const LegacyContainer = ({ hasRightSlot, content, children }) => (
  <>
    {!LANDING_PAGES.includes(content['@type']) &&
    content.show_navigation_portlet &&
    hasRightSlot ? (
      <Grid stackable as={Container} className="content-grid">
        <Grid.Column className="content-body" computer={8} tablet={12}>
          {children}
        </Grid.Column>
        {hasRightSlot && (
          <Grid.Column
            as="aside"
            className="aside-right-slot"
            computer={4}
            tablet={12}
          >
            <SlotRenderer name="asideRightSlot" />
          </Grid.Column>
        )}
      </Grid>
    ) : (
      <>{children}</>
    )}
  </>
);

const ContentContainer = ({ children, content }) => {
  const pathname = useLocation().pathname;
  const { slots } = config;

  const hasSlot = (name) => {
    if (!slots[name]) {
      return null;
    }
    return slots[name].filter((slot) =>
      matchPath(pathname, { path: slot.path, exact: slot.exact }),
    );
  };
  const hasRightSlot = !isEmpty(hasSlot('asideRightSlot'));

  const fzjsettings = useSelector((state) => state.fzjsettings.settings);

  return (
    <>
      {fzjsettings?.additional_body_class?.includes('has-new-layout') ||
      content['@type'] === 'Meldung' ? (
        <>
          {!LANDING_PAGES.includes(content['@type']) &&
            content.show_navigation_portlet &&
            hasRightSlot && (
              <>
                <BodyClass className="has-nav-portlet" />
                {hasRightSlot && (
                  <Container as="aside" className="aside-right-slot">
                    <SlotRenderer name="asideRightSlot" />
                  </Container>
                )}
              </>
            )}
          {children}
        </>
      ) : (
        <LegacyContainer hasRightSlot={hasRightSlot} content={content}>
          {children}
        </LegacyContainer>
      )}
    </>
  );
};

export default ContentContainer;
