import React, { useState } from 'react';
import PropTypes from 'prop-types';
import loadable from '@loadable/component';
import 'react-image-gallery/styles/css/image-gallery.css';
import config from '@plone/volto/registry';
import { flattenToAppURL } from '@plone/volto/helpers';
import { Button, Container } from 'semantic-ui-react';
import { Icon } from '@plone/volto/components';
import galleryLeftSVG from '@plone/volto/icons/left-key.svg';
import galleryRightSVG from '@plone/volto/icons/right-key.svg';
import galleryPlaySVG from '@plone/volto/icons/play.svg';
import galleryPauseSVG from '@plone/volto/icons/pause.svg';
import galleryFullScreenSVG from '@plone/volto/icons/fullscreen.svg';
import galleryBackDownSVG from '@plone/volto/icons/back-down.svg';

const ImageGallery = loadable(() => import('react-image-gallery'));

const ImageGalleryTemplate = ({ items, isFullscreen }) => {
  const renderLeftNav = (onClick, disabled) => {
    return (
      <Button
        className="image-gallery-icon image-gallery-left-nav primary basic"
        disabled={disabled}
        onClick={onClick}
        aria-label="Left Arrow"
      >
        <Icon name={galleryLeftSVG} size="48px" fill="black" />
      </Button>
    );
  };
  const renderRightNav = (onClick, disabled) => {
    return (
      <Button
        className="image-gallery-icon image-gallery-right-nav primary basic"
        disabled={disabled}
        onClick={onClick}
        aria-label="Right Arrow"
      >
        <Icon name={galleryRightSVG} size="48px" />
      </Button>
    );
  };

  const renderPlayPauseButton = (onClick, isPlaying) => (
    <Button
      type="button"
      className="image-gallery-icon image-gallery-play-button basic primary"
      onClick={onClick}
      aria-label="Play or Pause Slideshow"
    >
      {isPlaying ? (
        <Icon name={galleryPauseSVG} size="48px" />
      ) : (
        <Icon name={galleryPlaySVG} size="48px" />
      )}
    </Button>
  );

  const renderFullscreenButton = (onClick, isFullscreen) => {
    return (
      <Button
        type="button"
        className="image-gallery-icon image-gallery-fullscreen-button primary basic"
        onClick={() => {
          setFullscreen(!fullscreen);
          onClick();
        }}
        aria-label="Open Fullscreen"
      >
        {isFullscreen ? (
          <Icon name={galleryBackDownSVG} size="48px" />
        ) : (
          <Icon name={galleryFullScreenSVG} size="48px" />
        )}
      </Button>
    );
  };

  const [fullscreen, setFullscreen] = useState(false);
  const { settings } = config;
  const renderItems = items.filter((content) =>
    settings.imageObjects.includes(content['@type']),
  );
  const imagesInfo = renderItems.map((item) => {
    const renderImage = () => (
      <div className="image-gallery-image">
        <div className="image-wrapper">
          {fullscreen ? (
            <img
              src={`${flattenToAppURL(item['@id'])}/@@images/${
                item.image_field
              }/huge`}
              alt=""
            />
          ) : (
            <img
              src={`${flattenToAppURL(item['@id'])}/@@images/${
                item.image_field
              }/larger`}
              alt=""
            />
          )}
          {(item.description || item.rights) && (
            <div className="description-overlay">
              {item.description && <p>{item.description}</p>}
              {item.rights && <p className="rights">{item.rights}</p>}
            </div>
          )}
        </div>
      </div>
    );

    return {
      original: `${flattenToAppURL(item['@id'])}/@@images/${
        item.image_field
      }/huge`,
      thumbnail: `${flattenToAppURL(item['@id'])}/@@images/${
        item.image_field
      }/thumb`,
      renderItem: renderImage,
      originalAlt: '',
      thumbnailAlt: '',
    };
  });

  return (
    renderItems.length > 0 && (
      <Container>
        <ImageGallery
          items={imagesInfo}
          renderLeftNav={renderLeftNav}
          renderRightNav={renderRightNav}
          renderPlayPauseButton={renderPlayPauseButton}
          renderFullscreenButton={renderFullscreenButton}
          lazyLoad
          infinite
        />
      </Container>
    )
  );
};

ImageGalleryTemplate.propTypes = {
  items: PropTypes.arrayOf(PropTypes.any).isRequired,
};

export default ImageGalleryTemplate;
