/**
 * Add your config changes here.
 * @module config
 * @example
 * export const settings = {
 *   ...defaultSettings,
 *   port: 4300,
 *   listBlockTypes: {
 *     ...defaultSettings.listBlockTypes,
 *     'my-list-item',
 *   }
 * }
 */

import ContextNavigation from '@plone/volto/components/theme/Navigation/ContextNavigation';

import sliderSVG from '@plone/volto/icons/slider.svg';
import newsSVG from '@plone/volto/icons/news.svg';
import summarySVG from '@plone/volto/icons/summary.svg';
import imagesSVG from '@plone/volto/icons/images.svg';
import enterpriseSVG from '@plone/volto/icons/enterprise.svg';
import heroSVG from '@plone/volto/icons/hero.svg';
import blogSVG from '@plone/volto/icons/blog.svg';
import pageSVG from '@plone/volto/icons/page.svg';
import fileSVG from '@plone/volto/icons/file.svg';
import folderSVG from '@plone/volto/icons/folder.svg';
import linkSVG from '@plone/volto/icons/link.svg';
import imageSVG from '@plone/volto/icons/image.svg';
import bookSVG from '@plone/volto/icons/book.svg';
import eventSVG from '@plone/volto/icons/event.svg';

import parallaxSVG from '@package/icons/block_icn_parallax-block.svg';
import institutsSliderSVG from '@package/icons/block_icn_institutsslider.svg';
import karousselSVG from '@package/icons/block_icn_karoussel.svg';
import tabSVG from '@package/icons/block_icn_anreisser-tab.svg';
import tabBlockSVG from '@package/icons/block_icn_tab-block.svg';
import teaserTrennerSVG from '@package/icons/block_icn_teaser-trenner-bild.svg';
import teaserGridSVG from '@package/icons/block_icn_teaser-grid.svg';
import memberSVG from '@package/icons/block_icn_mitglieder.svg';
import researchGroupSVG from '@package/icons/block_icn_forschungsgruppe.svg';
import textPillWithStyleSVG from '@package/icons/block_icn_text-mit-auflistung.svg';
import headlineSVG from '@package/icons/block_icn_ueberschrift.svg';
import socialMediaSVG from '@package/icons/block_icn_portal-social-media.svg';
import gridSVG from '@package/icons/block_icn_grid.svg';
import accordionSVG from '@package/icons/block_icn_akkordeon.svg';
import buttonSVG from '@package/icons/block_icn_button.svg';
import textSVG from '@package/icons/block_icn_text.svg';
import tableSVG from '@package/icons/block_icn_tabelle.svg';
import tocSVG from '@package/icons/block_icn_inhaltsverzeichnis.svg';
import mapSVG from '@package/icons/block_icn_maps.svg';
import htmlSVG from '@package/icons/block_icn_html.svg';
import portalTargetGroupSVG from '@package/icons/block_icn_portal-zielgruppen.svg';
import listingSVG from '@package/icons/block_icn_auflistung.svg';
import freedomSVG from '@plone/volto/icons/freedom.svg';

import quoteSVG from '@plone/volto/icons/quote.svg';

import { withStyleWrapper } from '@kitconcept/volto-blocks/components';

import { solrSearchContent } from './actions';
import { Search } from '@plone/volto/components';

import {
  GridViewBlock,
  GridEditBlock,
} from '@kitconcept/volto-blocks-grid/components';

import HiERNMembersView from './addons/volto-hi-ern/src/components/Blocks/Members/View';
import HiERNMembersEdit from './addons/volto-hi-ern/src/components/Blocks/Members/Edit';
import HiERNAZListView from './addons/volto-hi-ern/src/components/Blocks/AZList/View';
import HiERNAZListEdit from './addons/volto-hi-ern/src/components/Blocks/AZList/Edit';

import {
  TeaserTransparent,
  TeaserTabView,
  TeaserTabEdit,
  FileListingVariation,
  TabBlockEdit,
  TabBlockView,
  ImageListingTemplate,
  NewsListingTemplate,
  NewsImageListingTemplate,
  EventListingTemplate,
  TeaserWithLinkEdit,
  TeaserWithLinkView,
  HeadlineBlockEdit,
  HeadlineBlockView,
  SocailMediaEdit,
  SocailMediaView,
  SliderNewEdit,
  SliderNewView,
  TeaserIconEdit,
  TeaserIconView,
  AktuellesEdit,
  AktuellesView,
  AktuellesListing,
  InstitutSliderEdit,
  InstitutSliderView,
  StickyMenuWidget,
  PreFooterWidget,
  FooterWidget,
  ColorChooserWidget,
  EventView,
  TeaserTransparentEdit,
  TeaserTransparentView,
  MeldungView,
  ResearchGroupView,
  ParallaxEdit,
  ParallaxView,
  MembersEdit,
  MembersView,
  IntroductionEdit,
  IntroductionView,
  TopSideFacets,
  GridListingTwoColumns,
  GridListingThreeColumns,
  GridListingFourColumns,
  AZListEdit,
  AZListView,
  MemberView,
  MemberListEdit,
  MemberListView,
  PersonsListingsAdapter,
  HighlightTeaserEdit,
  HighlightTeaserView,
  JobListingView,
  JobFilterBlockView,
  JobFilterBlockEdit,
  JobOfferView,
  EmbeddedPageView,
  HighlightView,
  HighlightEdit,
  AltmetricBlockEdit,
  AltmetricBlockView,
  AwardsListing,
  AwardView,
  ProjectView,
  ProjectListing,
  JobOfferListing,
  CartView,
  TenderView,
  ETrackerScript,
  IconBlockView,
  IconBlockEdit,
  HighlightTeaserParallaxEdit,
  HighlightTeaserParallaxView,
  QuoteBlockView,
  QuoteBlockEdit,
} from '@package/components';

import ImageGalleryListingBlockTemplate from '@plone/volto/components/manage/Blocks/Listing/ImageGallery';
import SummaryListingBlockTemplate from '@package/components/Blocks/ListingTemplate/SummaryTemplate';
import DefaultListingBlockTemplate from '@plone/volto/components/manage/Blocks/Listing/DefaultTemplate';
import { styleWrapperSchemaEnhancerV13 as styleWrapperSchemaEnhancer } from '@kitconcept/volto-blocks/components/withStyleWrapper/schema';
import { defineMessages } from 'react-intl';
import { renderLinkElement } from '@package/addons/volto-fzj/src/customizations/volto-slate/editor/render';

import configRegistry from '@plone/volto/registry';
import '@plone/volto/config';

import * as searchResultItems from '@package/addons/volto-fzj/src/customizations/volto/components/theme/Search/resultItems';

import AlignWidgetNext from './components/Widgets/AlignWidget';

const additionalExpressMiddlewareServerConfig =
  typeof __SERVER__ !== 'undefined' && __SERVER__
    ? require('./express-middleware').default
    : false;

defineMessages({
  Datenschutz: {
    id: 'Datenschutz',
    defaultMessage: 'Datenschutz',
  },
  member: {
    id: 'Member',
    defaultMessage: 'Member',
  },
  Barrierefreiheit: {
    id: 'Barrierefreiheit',
    defaultMessage: 'Barrierefreiheit',
  },
  Impressum: {
    id: 'Impressum',
    defaultMessage: 'Impressum',
  },
  Default: {
    id: 'Default',
    defaultMessage: 'Default',
  },
  root: {
    id: 'Root',
    defaultMessage: 'Root',
  },
  common: {
    id: 'common',
    defaultMessage: 'Common',
  },
  slateTable: {
    id: 'slateTable',
    defaultMessage: 'Table',
  },
  headline: {
    id: 'headline',
    defaultMessage: 'Headline',
  },
  slateSidebarDefaultMessage: {
    id: 'This block does not have any configuration options.',
    defaultMessage: 'This block does not have any configuration options.',
  },
  seeOverview: {
    id: 'see overview',
    defaultMessage: 'See Overview',
  },
  manageContent: {
    id: 'Manage_content',
    defaultMessage: 'Manage content...',
  },
  accordion: {
    id: 'Accordion',
    defaultMessage: 'Accordion',
  },
  members: {
    id: 'Members',
    defaultMessage: 'Members',
  },
  researchGroupIntroduction: {
    id: 'Research Group Introduction',
    defaultMessage: 'Research Group Introduction',
  },
  // To overcome the error messages in console until volto-slate is merged
  addLink: {
    id: 'Add link',
    defaultMessage: 'Add link',
  },
  researchGroup: {
    id: 'Research Group',
    defaultMessage: 'Research Group',
  },
  transparentGrid: {
    id: 'Teaser Transparent Grid',
    defaultMessage: 'Teaser Transparent Grid',
  },
  persons: {
    id: 'Persons',
    defaultMessage: 'Persons',
  },
  personsWithoutImage: {
    id: 'Persons without Image',
    defaultMessage: 'Persons without Image',
  },
  personsWithoutInstitutes: {
    id: 'Persons without Institutes',
    defaultMessage: 'Persons without Institutes',
  },
  personsWithoutInstitutsOrImages: {
    id: 'Persons without Instituts/Images',
    defaultMessage: 'Persons without Instituts/Images',
  },
  personsGrid: {
    id: 'Persons Grid',
    defaultMessage: 'Persons Grid',
  },
  personsGridWithoutImage: {
    id: 'Persons Grid without image',
    defaultMessage: 'Persons Grid without image',
  },
  summary: {
    id: 'Summary',
    defaultMessage: 'Summary',
  },
  imageListing: {
    id: 'Image gallery',
    defaultMessage: 'Image gallery',
  },
  newsListing: {
    id: 'News',
    defaultMessage: 'News',
  },
  newsImageListing: {
    id: 'News with image',
    defaultMessage: 'News with image',
  },
  eventListing: {
    id: 'Events',
    defaultMessage: 'Events',
  },
  fileListing: {
    id: 'Files/Downloads',
    defaultMessage: 'Files/Downloads',
  },
  imageGallery: {
    id: 'Image slider',
    defaultMessage: 'Image slider',
  },
  cardsListing: {
    id: 'Cards',
    defaultMessage: 'Cards',
  },
  GridListingTwoColumns: {
    id: 'Grid Listing (2 Columns)',
    defaultMessage: 'Grid Listing (2 Columns)',
  },
  GridListingThreeColumns: {
    id: 'Grid Listing (3 Columns)',
    defaultMessage: 'Grid Listing (3 Columns)',
  },
  GridListingFourColumns: {
    id: 'Grid Listing (4 Columns)',
    defaultMessage: 'Grid Listing (4 Columns)',
  },
  searchResults: {
    id: 'Search results',
    defaultMessage: 'Search results',
  },
  separator: {
    id: 'Seperator',
    defaultMessage: 'Seperator',
  },
  // TypeSearchWords is here because currently in customized Search.jsx
  TypeSearchWords: {
    id: 'Search...',
    defaultMessage: 'Search...',
  },
  EmbeddedPage: {
    id: 'Embedded Page',
    defaultMessage: 'Embedded Page',
  },
  Event: {
    id: 'Event',
    defaultMessage: 'Event',
  },
  Joboffer: {
    id: 'Joboffer',
    defaultmessage: 'Joboffer',
  },
  Frontpage: {
    id: 'Frontpage',
    defaultMessage: 'Frontpage',
  },
  January: {
    id: 'January',
    defaultMessage: 'January',
  },
  February: {
    id: 'February',
    defaultMessage: 'February',
  },
  March: {
    id: 'March',
    defaultMessage: 'March',
  },
  May: {
    id: 'May',
    defaultMessage: 'May',
  },
  June: {
    id: 'June',
    defaultMessage: 'June',
  },
  July: {
    id: 'July',
    defaultMessage: 'July',
  },
  October: {
    id: 'October',
    defaultMessage: 'October',
  },
  December: {
    id: 'December',
    defaultMessage: 'December',
  },
  print: {
    id: 'print',
    defaultMessage: 'print',
  },
  toTheTop: {
    id: 'to the top',
    defaultMessage: 'to the top',
  },
  results: {
    // Ergebnisse
    id: 'results',
    defaultMessage: 'results',
  },
  searchLocalizedLabel: {
    // Suchergebnisse anzeigen für:
    id: 'searchLocalizedLabel',
    defaultMessage: 'Show results for:',
  },
  searchGlobalized: {
    // Alle webauftritte (global)
    id: 'searchGlobalized',
    defaultMessage: 'All content (global)',
  },
  searchLocalized: {
    // Nur dieser webauftritt
    id: 'searchLocalized',
    defaultMessage: 'This subfolder only',
  },
  pageNotFound: {
    id: 'delete',
    defaultMessage: 'delete',
  },
  pageNotAvailable: {
    id:
      'The page you were looking for is unfortunately not available (anymore).',
    defaultMessage:
      'The page you were looking for is unfortunately not available (anymore).',
  },
  useSearchPrompt: {
    id:
      'Please use the search function or alternative entry points to reach the desired information.',
    defaultMessage:
      'Please use the search function or alternative entry points to reach the desired information.',
  },
  goToSearch: {
    id: 'Go to search',
    defaultMessage: 'Go to',
  },
  goToInstitutes: {
    id: 'Go to institutes',
    defaultMessage: 'Go to',
  },
  goToContact: {
    id: 'Go to contact',
    defaultMessage: 'Go to',
  },
  search: {
    id: 'Search',
    defaultMessage: 'Search',
  },
  institutes: {
    id: 'institutes',
    defaultMessage: 'institutes',
  },
  contactVisitor: {
    id: 'Contact & Visitor information',
    defaultMessage: 'Contact & Visitor information',
  },
  thankYou: {
    id: 'Thank you',
    defaultMessage: 'Thank you',
  },
  deleteConfirmSingleItem: {
    id: 'Delete this item?',
    defaultMessage: 'Delete this item?',
  },
  deleteConfirmMultipleItems: {
    id: 'Delete selected items?',
    defaultMessage: 'Delete selected items?',
  },
  showAllSearchResults: {
    id: 'Show all search results...',
    defaultMessage: 'Show all search results...',
  },
  closeSearch: {
    id: 'Close search',
    defaultMessage: 'Close search',
  },
  urlClipboardCopy: {
    id: 'Link copied to clipboard',
    defaultMessage: 'Anchor link copied to the clipboard',
  },
  DataProtection: {
    id: 'Data Protection',
    defaultMessage: 'Data Protection',
  },
});

export default function applyConfig(config) {
  config.settings.slate.toolbarButtons = config.settings.slate.toolbarButtons.filter(
    (item) => item !== 'table',
  );
  config.settings.slate.expandedToolbarButtons = config.settings.slate.expandedToolbarButtons.filter(
    (item) => item !== 'table',
  );
  config.settings.slate.toolbarButtons = [
    ...config.settings.slate.toolbarButtons,
    'sub',
    'sup',
  ];
  config.settings.slate.useLinkedHeadings = true;
  const contentIcons = {
    ...config.settings.contentIcons,
    Meldung: newsSVG,
    EmbeddedPage: summarySVG,
    Institut: enterpriseSVG,
    Institutsbereich: enterpriseSVG,
    Member: memberSVG,
    'Research Group': researchGroupSVG,
  };
  const teaserGridVariations = {
    default: { label: 'Default' },
    transparent: {
      label: 'Transparent',
      components: {
        view: TeaserTransparent,
      },
    },
  };

  config.settings = {
    ...config.settings,
    isMultilingual: true,
    hasWorkingCopySupport: true,
    listingPreviewImageField: 'preview_image',
    supportedLanguages: process.env.RAZZLE_FZJ_LANGUAGES
      ? process.env.RAZZLE_FZJ_LANGUAGES.split(',')
      : ['de', 'en'],
    defaultLanguage: process.env.RAZZLE_FZJ_DEFAULT_LANGUAGE || 'de',
    navDepth: 3,
    contentIcons,
    isFZJProject: process.env.RAZZLE_FZJ_PROJECT || false,
    isHiERNProject: process.env.RAZZLE_FZJ_HI_ERN || false,
  };

  config.settings.slate.elements = {
    ...config.settings.slate.elements,
    h1: renderLinkElement('h1'),
    h2: renderLinkElement('h2'),
    h3: renderLinkElement('h3'),
    h4: renderLinkElement('h4'),
  };

  config.widgets.widget.stickymenu = StickyMenuWidget;
  config.widgets.widget.prefooter = PreFooterWidget;
  config.widgets.widget.footer = FooterWidget;
  config.widgets.widget.colorChooser = ColorChooserWidget;

  config.widgets.widget.align_next = AlignWidgetNext;

  config.widgets.id.main_bg_color = ColorChooserWidget;
  config.views.contentTypesViews.Event = EventView;
  config.views.contentTypesViews.Meldung = MeldungView;
  config.views.contentTypesViews.Member = MemberView;
  config.views.contentTypesViews['Research Group'] = ResearchGroupView;
  config.views.contentTypesViews['JobOfferContainer'] = JobListingView;
  config.views.contentTypesViews['Joboffer'] = JobOfferView;
  config.views.contentTypesViews.EmbeddedPage = EmbeddedPageView;
  config.views.contentTypesViews.Project = ProjectView;
  config.views.contentTypesViews.Award = AwardView;
  config.views.contentTypesViews.Cart = CartView;
  config.views.contentTypesViews.Tender = TenderView;

  config.settings.apiExpanders = [
    { match: '', GET_CONTENT: ['fzjsettings,nearest-institut'] },
  ];

  // Do not show the sidebar in slate blocks
  config.blocks.blocksConfig.slate.sidebarTab = 0;
  config.blocks.blocksConfig.accordion.allowedBlocks = [
    'slate',
    'listing',
    'memberList',
    'image',
  ];

  // config.blocks.blocksConfig.search.restricted = true;
  // config.blocks.blocksConfig.form.restricted = true;

  config.blocks.blocksConfig.search.variations = [
    {
      id: 'facetsTopSide',
      title: 'Facets on top',
      view: TopSideFacets,
      isDefault: true,
    },
  ];

  const AVAILABLE_COLORS_DEFAULT = [
    '#ebebeb',
    '#023d6b',
    '#ddeeff',
    '#eb5f73',
    '#b9d25f',
  ];
  const AVAILABLE_COLORS_PROJECT = [
    '#ebebeb',
    '#023d6b',
    '#9c87be', // purple
    '#ddeeff',
    '#8dc3aa', // mint
    '#b9d25f',
    '#fdd06d', // orange
    '#eb5f73',
    '#cd7fb4', // lilac
  ];
  const AVAILABLE_COLORS_HIERN = [
    '#005b82', // blue
    '#64c1be', // blue-green
    '#bbcf00', // lightgreen
    '#009a3d', // dark green
    '#8c8c8c', // lightgrey
    '#dee1e3', // lightergrey
    '#575757', // grey
    '#3e4c54', // blackgrey
  ];
  const AVAILABLE_COLORS = config.settings.isFZJProject
    ? AVAILABLE_COLORS_PROJECT
    : config.settings.isHiERNProject
    ? AVAILABLE_COLORS_HIERN
    : AVAILABLE_COLORS_DEFAULT;

  config.blocks.blocksConfig.search.extensions.facetWidgets.rewriteOptions = (
    name,
    choices,
  ) => {
    switch (name) {
      case 'review_state':
        return choices.map((opt) => ({
          ...opt,
          label: opt.label.replace(/\[.+\]/, '').trim(),
        }));
      case 'year':
        return choices.reverse();
      default:
        return choices;
    }
  };

  config.blocks = {
    ...config.blocks,
    groupBlocksOrder: [
      ...config.blocks.groupBlocksOrder,
      { id: 'Research Group', title: 'Research Group' },
      { id: 'Career', title: 'Career' },
    ],
    requiredBlocks: [],
    blocksConfig: {
      ...config.blocks.blocksConfig,
      title: {
        ...config.blocks.blocksConfig.title,
      },
      leadimage: {
        ...config.blocks.blocksConfig.leadimage,
        restricted: true,
      },
      teaser: {
        ...config.blocks.blocksConfig.teaser,
        imageScale: 'teaser',
      },
      teaserHero: {
        ...config.blocks.blocksConfig.teaserHero,
        restricted: true,
      },
      hero: {
        ...config.blocks.blocksConfig.hero,
        restricted: true,
      },
      __grid: {
        ...config.blocks.blocksConfig.__grid,
        view: withStyleWrapper(GridViewBlock),
        edit: withStyleWrapper(GridEditBlock),
        icon: gridSVG,
        availableColors: AVAILABLE_COLORS,
        schemaEnhancer: styleWrapperSchemaEnhancer,
        gridAllowedBlocks: [
          'teaser',
          'image',
          'listing',
          'slate',
          'buttonBlock',
          'infoCard',
          'icon',
        ],
      },
      accordion: {
        ...config.blocks.blocksConfig.accordion,
        icon: accordionSVG,
      },
      buttonBlock: {
        ...config.blocks.blocksConfig.buttonBlock,
        view: config.blocks.blocksConfig.buttonBlock.view,
        edit: config.blocks.blocksConfig.buttonBlock.edit,
        availableColors: AVAILABLE_COLORS,
        icon: buttonSVG,
      },
      slate: {
        ...config.blocks.blocksConfig.slate,
        icon: textSVG,
      },
      slateTable: {
        ...config.blocks.blocksConfig.slateTable,
        icon: tableSVG,
      },
      toc: {
        ...config.blocks.blocksConfig.toc,
        icon: tocSVG,
      },
      maps: {
        ...config.blocks.blocksConfig.maps,
        icon: mapSVG,
      },
      html: {
        ...config.blocks.blocksConfig.html,
        icon: htmlSVG,
      },
      listingGrid: {
        ...config.blocks.blocksConfig.listingGrid,
        restricted: true,
      },
      listing: {
        ...config.blocks.blocksConfig.listing,
        icon: listingSVG,
        variations: [
          {
            id: 'default',
            title: 'Standard',
            isDefault: true,
            template: DefaultListingBlockTemplate,
          },
          {
            id: 'summary',
            title: 'Summary',
            template: SummaryListingBlockTemplate,
          },
          {
            id: 'imageListing',
            title: 'Image gallery',
            template: ImageListingTemplate,
          },
          {
            id: 'newsListing',
            title: 'News',
            template: NewsListingTemplate,
          },
          {
            id: 'newsImageListing',
            title: 'News with image',
            template: NewsImageListingTemplate,
          },
          {
            id: 'eventListing',
            title: 'Events',
            template: EventListingTemplate,
          },
          {
            id: 'fileListing',
            title: 'Files/Downloads',
            template: FileListingVariation,
          },
          {
            id: 'aktuelles',
            title: 'Aktuelles',
            template: AktuellesListing,
          },
          {
            id: 'imageGallery',
            title: 'Image slider',
            template: ImageGalleryListingBlockTemplate,
          },
          {
            id: 'GridListingTwoColumns',
            title: 'Grid Listing (2 Columns)',
            template: GridListingTwoColumns,
          },
          {
            id: 'GridListingThreeColumns',
            title: 'Grid Listing (3 Columns)',
            template: GridListingThreeColumns,
          },
          {
            id: 'cardsListing',
            title: 'Grid Listing (4 Columns)',
            template: GridListingFourColumns,
          },
          {
            id: 'persons',
            title: 'Persons',
            template: PersonsListingsAdapter,
            fullobjects: 1,
          },
          {
            id: 'personsWithoutImage',
            title: 'Persons without Image',
            template: PersonsListingsAdapter,
            fullobjects: 1,
          },
          {
            id: 'personsWithoutInstitutes',
            title: 'Persons without Institutes',
            template: PersonsListingsAdapter,
            fullobjects: 1,
          },
          {
            id: 'personsWithoutInstitutsOrImages',
            title: 'Persons without Instituts/Images',
            template: PersonsListingsAdapter,
            fullobjects: 1,
          },
          {
            id: 'personsGrid',
            title: 'Persons Grid',
            template: PersonsListingsAdapter,
            fullobjects: 1,
          },
          {
            id: 'personsGridWithoutImage',
            title: 'Persons Grid without image',
            template: PersonsListingsAdapter,
            fullobjects: 1,
          },
          {
            id: 'projectListing',
            title: 'Project Listing',
            template: ProjectListing,
          },
          {
            id: 'awardsListing',
            title: 'Awards Listing',
            template: AwardsListing,
            fullobjects: 1,
          },
          {
            id: 'jobOfferListing',
            title: 'JobOffer Listing',
            template: JobOfferListing,
          },
        ],
      },
      carousel: {
        availableColors: AVAILABLE_COLORS,
        ...config.blocks.blocksConfig.carousel,
        icon: karousselSVG,
        group: 'teasers',
        mostUsed: false,
      },
      highlightSlider: {
        ...config.blocks.blocksConfig.highlightSlider,
        restricted: true,
      },
      textPillWithStyle: {
        ...config.blocks.blocksConfig.textPillWithStyle,
        icon: textPillWithStyleSVG,
        availableColors: AVAILABLE_COLORS,
        defaultColor: '#fff',
      },
      imagesGrid: {
        ...config.blocks.blocksConfig.imagesGrid,
        icon: teaserGridSVG,
        availableColors: AVAILABLE_COLORS,
        restricted: true,
      },
      teaserGrid: {
        ...config.blocks.blocksConfig.teaserGrid,
        view: withStyleWrapper(GridViewBlock),
        edit: withStyleWrapper(GridEditBlock),
        variations: teaserGridVariations,
        restricted: true,
        icon: teaserGridSVG,
        availableColors: AVAILABLE_COLORS,
      },

      teaserTransparentGrid: {
        ...config.blocks.blocksConfig.teaserGrid,
        id: 'teaserTransparentGrid',
        title: 'Teaser Transparent Grid',
        view: withStyleWrapper(GridViewBlock),
        edit: withStyleWrapper(GridEditBlock),
        icon: teaserGridSVG,
        gridAllowedBlocks: ['teaserTransparent'],
        availableColors: AVAILABLE_COLORS,
      },

      teaserTransparent: {
        id: 'teaserTransparent',
        title: 'Teaser Transparent',
        icon: imagesSVG,
        group: 'teasers',
        view: TeaserTransparentView,
        edit: TeaserTransparentEdit,
        restricted: true,
        mostUsed: true,
        sidebarTab: 1,
        security: {
          addPermission: [],
          view: [],
        },
      },

      highlightTeaserParallax: {
        id: 'highlightTeaserParallax',
        title: 'Highlight Parallax',
        icon: imagesSVG,
        group: 'teasers',
        view: HighlightTeaserParallaxView,
        edit: HighlightTeaserParallaxEdit,
        restricted: false,
        mostUsed: true,
        sidebarTab: 1,
        security: {
          addPermission: [],
          view: [],
        },
      },
      highlightTeaser: {
        id: 'highlightTeaser',
        title: 'Highlight Teaser',
        icon: imagesSVG,
        group: 'teasers',
        view: HighlightTeaserView,
        edit: HighlightTeaserEdit,
        restricted: false,
        mostUsed: true,
        sidebarTab: 1,
        security: {
          addPermission: [],
          view: [],
        },
      },
      highlightTeaserWithoutButton: {
        id: 'highlightTeaserWithoutButton',
        title: 'Highlight',
        icon: imagesSVG,
        group: 'teasers',
        view: HighlightView,
        edit: HighlightEdit,
        restricted: false,
        mostUsed: true,
        sidebarTab: 1,
        security: {
          addPermission: [],
          view: [],
        },
      },
      members: {
        id: 'members',
        title: 'Members',
        icon: memberSVG,
        group: 'Research Group',
        view:
          config.settings.isHiERNProject === 'true'
            ? HiERNMembersView
            : MembersView,
        edit:
          config.settings.isHiERNProject === 'true'
            ? HiERNMembersEdit
            : MembersEdit,
        restricted: false,
        mostUsed: false,
        sidebarTab: 1,
        security: {
          addPermission: [],
          view: [],
        },
        variations: [
          {
            id: 'default',
            isDefault: true,
            title: 'Standard',
            template: MembersView,
          },
          {
            id: 'Without Image',
            title: 'Ohne Bild',
            template: MembersView,
          },
        ],
      },
      memberList: {
        id: 'memberList',
        title: 'Persons List',
        icon: listingSVG,
        group: 'common',
        view: MemberListView,
        edit: MemberListEdit,
        restricted: false,
        mostUsed: true,
        sidebarTab: 1,
        security: {
          addPermission: [],
          view: [],
        },
        variations: [
          {
            id: 'persons',
            title: 'Persons',
            isDefault: true,
            template: MemberListView,
          },
          {
            id: 'personsWithoutImage',
            title: 'Persons without Image',
            template: MemberListView,
          },
          {
            id: 'personsWithoutInstitutes',
            title: 'Persons without Institutes',
            template: MemberListView,
          },
          {
            id: 'personsWithoutInstitutsOrImages',
            title: 'Persons without Instituts/Images',
            template: MemberListView,
          },
          {
            id: 'personsGrid',
            title: 'Persons Grid',
            template: MemberListView,
          },
          {
            id: 'personsGridWithoutImage',
            title: 'Persons Grid without image',
            template: MemberListView,
          },
        ],
      },
      teaserTab: {
        id: 'teaserTab',
        title: 'Teaser Tab',
        icon: tabSVG,
        group: 'teasers',
        view: TeaserTabView,
        edit: TeaserTabEdit,
        restricted: false,
        mostUsed: true,
        sidebarTab: 1,
        security: {
          addPermission: [],
          view: [],
        },
      },
      tabBlock: {
        id: 'tabBlock',
        title: 'Tab Block',
        group: 'common',
        icon: tabBlockSVG,
        view: TabBlockView,
        edit: TabBlockEdit,
        restricted: false,
        mostUsed: false,
        sidebarTab: 1,
        security: {
          addPermission: [],
          view: [],
        },
      },
      parallaxBlock: {
        id: 'parallaxBlock',
        title: 'Parallax Block',
        group: 'teaser',
        icon: parallaxSVG,
        view: ParallaxView,
        edit: ParallaxEdit,
        restricted: false,
        mostUsed: true,
        sidebarTab: 1,
        security: {
          addPermission: [],
          view: [],
        },
        availableColors: ['#000', '#fff'],
      },
      sliderNew: {
        id: 'sliderNew',
        title: 'Portal Slider',
        group: 'common',
        icon: sliderSVG,
        view: SliderNewView,
        edit: SliderNewEdit,
        restricted: false,
        mostUsed: false,
        sidebarTab: 1,
        security: {
          addPermission: [],
          view: [],
        },
      },
      socialMedia: {
        id: 'socialMedia',
        title: 'Portal Social Media',
        icon: socialMediaSVG,
        group: 'common',
        view: SocailMediaView,
        edit: SocailMediaEdit,
        restricted: false,
        mostUsed: false,
        sidebarTab: 1,
        security: {
          addPermission: [],
          view: [],
        },
      },
      aktuelles: {
        id: 'aktuelles',
        title: 'Aktuelles',
        icon: sliderSVG,
        group: 'teasers',
        view: AktuellesView,
        edit: AktuellesEdit,
        restricted: false,
        mostUsed: true,
        sidebarTab: 1,
        security: {
          addPermission: [],
          view: [],
        },
      },
      institutslider: {
        id: 'institutslider',
        title: 'Institut Slider',
        group: 'teasers',
        icon: institutsSliderSVG,
        view: InstitutSliderView,
        edit: InstitutSliderEdit,
        restricted: false,
        mostUsed: true,
        sidebarTab: 1,
        security: {
          addPermission: [],
          view: [],
        },
      },
      teaserIcon: {
        id: 'teaserIcon',
        title: 'Portal Zielgruppen',
        icon: portalTargetGroupSVG,
        group: 'common',
        view: TeaserIconView,
        edit: TeaserIconEdit,
        restricted: false,
        mostUsed: false,
        sidebarTab: 1,
        security: {
          addPermission: [],
          view: [],
        },
      },
      teaserWithLink: {
        id: 'teaserWithLink',
        title: 'Teaser-Trenner-Bild',
        group: 'teasers',
        icon: teaserTrennerSVG,
        view: TeaserWithLinkView,
        edit: TeaserWithLinkEdit,
        restricted: false,
        mostUsed: true,
        sidebarTab: 1,
        security: {
          addPermission: [],
          view: [],
        },
      },
      headline: {
        id: 'headline',
        title: 'Headline',
        icon: headlineSVG,
        group: 'text',
        view: withStyleWrapper(HeadlineBlockView),
        edit: withStyleWrapper(HeadlineBlockEdit),
        restricted: false,
        mostUsed: false,
        sidebarTab: 1,
        security: {
          addPermission: [],
          view: [],
        },
        availableColors: AVAILABLE_COLORS,
      },
      introduction: {
        id: 'introduction',
        title: 'Research Group Introduction',
        group: 'Research Group',
        icon: researchGroupSVG,
        view: withStyleWrapper(IntroductionView),
        edit: withStyleWrapper(IntroductionEdit),
        restricted: false,
        mostUsed: false,
        sidebarTab: 1,
        security: {
          addPermission: [],
          view: [],
        },
        availableColors: AVAILABLE_COLORS,
      },
      azlist: {
        id: 'azlist',
        title: 'A-Z List',
        group: 'common',
        icon: listingSVG,
        view:
          config.settings.isHiERNProject === 'true'
            ? withStyleWrapper(HiERNAZListView)
            : withStyleWrapper(AZListView),
        edit:
          config.settings.isHiERNProject === 'true'
            ? withStyleWrapper(HiERNAZListEdit)
            : withStyleWrapper(AZListEdit),
        restricted: false,
        mostUsed: false,
        sidebarTab: 1,
        security: {
          addPermission: [],
          view: [],
        },
        availableColors: AVAILABLE_COLORS,
      },
      jofilter: {
        id: 'jofilter',
        title: 'Job Filter',
        group: 'Career',
        icon: heroSVG,
        view: JobFilterBlockView,
        edit: JobFilterBlockEdit,
        restricted: false,
        mostUsed: false,
        sidebarTab: 1,
        security: {
          addPermission: [],
          view: [],
        },
      },
      altmetric: {
        id: 'altmetric',
        title: 'Altmetric',
        group: 'common',
        icon: freedomSVG,
        view: AltmetricBlockView,
        edit: AltmetricBlockEdit,
        restricted: false,
        mostUsed: false,
        sidebarTab: 1,
        security: {
          addPermission: [],
          view: [],
        },
      },
      icon: {
        id: 'icon',
        title: 'Icon',
        group: 'common',
        icon: freedomSVG,
        view: IconBlockView,
        edit: IconBlockEdit,
        restricted: true,
        mostUsed: false,
        sidebarTab: 1,
        security: {
          addPermission: [],
          view: [],
        },
      },
      icons: {
        ...config.blocks.blocksConfig.teaserGrid,
        id: 'icons',
        title: 'Icons',
        group: 'common',
        view: withStyleWrapper(GridViewBlock),
        edit: withStyleWrapper(GridEditBlock),
        restricted: false,
        mostUsed: false,
        icon: teaserGridSVG,
        gridAllowedBlocks: ['icon'],
        schemaEnhancer: styleWrapperSchemaEnhancer,
        availableColors: AVAILABLE_COLORS,
      },
      quote: {
        id: 'quote',
        title: 'Quote',
        group: 'text',
        view: withStyleWrapper(QuoteBlockView),
        edit: withStyleWrapper(QuoteBlockEdit),
        restricted: false,
        mostUsed: false,
        icon: quoteSVG,
        schemaEnhancer: styleWrapperSchemaEnhancer,
        availableColors: AVAILABLE_COLORS,
        defaultColor: AVAILABLE_COLORS[0], // Use grey as default color
        // Enable image field
        showImageField: true,
        sidebarTab: 1,
      },
      __button: {
        ...config.blocks.blocksConfig.__button,
        restricted: true,
      },
    },
  };

  delete config.blocks.blocksConfig.listing.getAsyncData;

  if (additionalExpressMiddlewareServerConfig) {
    config.settings.expressMiddleware = [
      ...config.settings.expressMiddleware,
      ...additionalExpressMiddlewareServerConfig,
    ];
  }
  config.slots = {
    asideRightSlot: [],
  };

  config.settings.supportedLanguages.forEach((lang) => {
    config.slots.asideRightSlot.push({
      id: `slot-navigation-${lang}`,
      path: `/${lang}/**`,
      component: ContextNavigation,
      props: { params: { no_thumbs: true } },
    });
  });
  // This is because it's not in Volto yet. TODO: remove when slots are in core
  configRegistry.slots = config.slots;

  // --
  // View type mapping for the search results in support of Search.jsx
  // --

  // View mapper can be specified here. with the desired type rendererers.
  // The default view applies for all content types not in the array.
  // Or just use the built in mapper.
  config.views.contentTypeSearchResultViews = {
    Member: searchResultItems.MemberResultItem,
    Meldung: searchResultItems.MeldungResultItem,
    Event: searchResultItems.EventResultItem,
    // XXX Use default view for Joboffer for now. #DLR-38
    // Joboffer: searchResultItems.JobofferResultItem,
    Image: searchResultItems.ImageResultItem,
    Intro: searchResultItems.IntroResultItem,
  };
  config.views.contentTypeSearchResultDefaultView =
    searchResultItems.DefaultResultItem;

  // Icon types. This will be in effect with all supported
  // content type templates.
  config.settings.contentTypeSearchResultIcons = {
    ...config.settings.contentIcons,
    Document: pageSVG,
    File: fileSVG,
    Folder: folderSVG,
    Link: linkSVG,
    Image: imageSVG,
    Meldung: newsSVG,
    'FAQ Item': bookSVG,
    Event: eventSVG,
    OE: enterpriseSVG,
    Regelung: blogSVG,
  };
  config.settings.contentTypeSearchResultDefaultIcon = pageSVG;

  // Wrapper for a customized Solr Search component that can be used
  // directly as a route. On long term these parameters could be acquired from Search.jsx and
  // this wrapper could go away.
  config.widgets.SolrSearch = (props) =>
    Search({
      ...props,
      searchAction: solrSearchContent,
      getSearchReducer: (state) => state.solrsearch,
      contentTypeSearchResultViews: config.views.contentTypeSearchResultViews,
      contentTypeSearchResultDefaultView:
        config.views.contentTypeSearchResultDefaultView,
    });

  config.settings.fontCombinationMap = {
    default: { headers: 'Arial', main: 'Arial' },
    'impact-arialNarrow': { headers: 'Impact', main: 'Arial Narrow' },
    'georgia-lucidaSans': {
      headers: 'Georgia',
      main: 'Lucida Sans, Lucida Grande',
    },
    Metropolis: { headers: 'Metropolis', main: 'Lucida Sans' },
  };

  config.settings.nonContentRoutes = [
    ...config.settings.nonContentRoutes,
    /\/@@search$/,
    /\/unlock-form$/,
    /\/@cart(-checkout(\/.*)?)?$/,
    /.*\/@@search/,
    '/links-to-item',
  ];

  config.settings.DSGVOBanner = {
    ...config.settings.DSGVOBanner,
    tracker: {
      type: 'matomo',
      id: 58,
      urlBase: 'https://apps.fz-juelich.de/piwik/',
    },
    showBanner: true,
    modules: ['tracking', 'youtube', 'facebook', 'google'],
    privacy_url: { de: '/de/datenschutz', en: '/en/privacy-policy' },
  };

  // Initially disable banner until activated by fzjsettings (having a matomo_id)
  if (config.settings.isFZJProject || config.settings.isHiERNProject) {
    config.settings.DSGVOBanner = {
      ...config.settings.DSGVOBanner,
      showBanner: false,
    };
  }

  //Use etracker only on main site
  if (!config.settings.isHiERNProject && !config.settings.isFZJProject) {
    config.settings.DSGVOBanner = {
      ...config.settings.DSGVOBanner,
      modules: ['tracking', 'youtube', 'facebook', 'etracker', 'google'],
    };
    config.settings.appExtras = [
      ...config.settings.appExtras,
      {
        match: '/de/karriere',
        component: ETrackerScript,
      },
      {
        match: '/en/careers',
        component: ETrackerScript,
      },
    ];
  }
  return config;
}
