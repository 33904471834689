import { defineMessages } from 'react-intl';

const messages = defineMessages({
  Criteria: {
    id: 'Research Group',
    defaultMessage: 'Research Group',
  },
  title: {
    id: 'Members Block',
    defaultMessage: 'Members Block',
  },
  titleText: {
    id: 'Title',
    defaultMessage: 'Title',
  },
  reversedOrder: {
    id: 'Reversed order',
    defaultMessage: 'Reversed order',
  },
  variation: {
    id: 'Variation',
    defaultMessage: 'Variation',
  },
});

export const schemaMembers = (props) => {
  const { intl } = props;

  return {
    title: intl.formatMessage(messages.title),
    block: 'members',
    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: ['title', 'researchGroup', 'sort_order_boolean'],
      },
    ],

    properties: {
      title: {
        title: intl.formatMessage(messages.titleText),
      },
      researchGroup: {
        title: intl.formatMessage(messages.Criteria),
        vocabulary: {
          '@id': 'fzj.internet.vocabulary.researchgroups',
        },
        choices: false,
      },
      sort_order_boolean: {
        title: intl.formatMessage(messages.reversedOrder),
        type: 'boolean',
      },
    },
    required: [],
  };
};
