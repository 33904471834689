import { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { onLoad } from './cart-storage';
import { Container, Form, Button, Input } from 'semantic-ui-react';
import { defineMessages, useIntl } from 'react-intl';
import countries from 'i18n-iso-countries';
import { cartOrder, cartForm } from '../../actions';
import CartSummary from './CartSummary';

const messages = defineMessages({
  back: {
    id: 'back',
    defaultMessage: 'Back',
  },
  order: {
    id: 'order',
    defaultMessage: 'Order',
  },
  mr: {
    id: 'mr',
    defaultMessage: 'Mr',
  },
  ms: {
    id: 'ms',
    defaultMessage: 'Ms',
  },
  neutral: {
    id: 'neutral',
    defaultMessage: '-',
  },
  gender: {
    id: 'gender',
    defaultMessage: 'Gender',
  },
  firstName: {
    id: 'firstName',
    defaultMessage: 'First Name', // Vorname
  },
  lastName: {
    id: 'lastName',
    defaultMessage: 'Last Name', // Name
  },
  company: {
    id: 'company',
    defaultMessage: 'Company', // Firma
  },
  addressLine1: {
    id: 'addressLine1',
    defaultMessage: 'Address Line 1', // Straße
  },
  addressLine2: {
    id: 'addressLine2',
    defaultMessage: 'Address Line 2', // Straße 2
  },
  zipCode: {
    id: 'zipCode',
    defaultMessage: 'Zip Code', // PLZ
  },
  city: {
    id: 'city',
    defaultMessage: 'City', // Wohnort
  },
  country: {
    id: 'country',
    defaultMessage: 'Country', // Land
  },
  email: {
    id: 'email',
    defaultMessage: 'E-Mail', // Email
  },
  validateMandatory: {
    id: 'validateMandatory',
    defaultMessage: 'Mandatory value',
  },
  validateEmail: {
    id: 'valdateEmail',
    defaultMessage: 'Must be a valid email',
  },
  emptyOrder: {
    id: 'emptyOrder',
    defaultMessage: 'The order is empty.',
  },
  fillOutYourData: {
    id: 'fillOutYourData',
    defaultMessage: 'Your delivery address:',
  },
});

const CartCheckoutForm = () => {
  const history = useHistory();
  const intl = useIntl();
  const dispatch = useDispatch();

  const genderOptions = useMemo(
    () => [
      { key: 'm', text: intl.formatMessage(messages.mr), value: 'mr' },
      { key: 'f', text: intl.formatMessage(messages.ms), value: 'ms' },
      { key: 'o', text: intl.formatMessage(messages.neutral), value: 'other' },
    ],
    [intl],
  );

  const countryOptions = useMemo(
    () =>
      Object.keys(countries.getAlpha2Codes())
        .map((k) => ({
          key: k,
          text: countries.getName(k, intl.locale, { select: 'official' }),
          value: k,
        }))
        // sorting needed by the names in the given locale
        .sort((a, b) => {
          if (a.text < b.text) {
            return -1;
          }
          if (a.text > b.text) {
            return 1;
          } else {
            return 0;
          }
        }),
    [intl.locale],
  );

  const [order, setOrder] = useState();

  const validateMandatory = (txt) => {
    if (!txt) {
      return intl.formatMessage(messages.validateMandatory);
    }
  };

  const validateEmail = (email) => {
    if (
      !String(email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        )
    ) {
      return intl.formatMessage(messages.validateEmail);
    }
  };

  const formData = useSelector(({ cart: { formData } }) => formData);
  const [invalidGender, setInvalidGender] = useState(
    validateMandatory(formData.gender),
  );
  const [invalidGenderHelper, setInvalidGenderHelper] = useState('');
  const [invalidLastName, setInvalidLastName] = useState(
    validateMandatory(formData.lastName),
  );
  const [invalidLastNameHelper, setInvalidLastNameHelper] = useState('');
  const [invalidAddressLine1, setInvalidAddressLine1] = useState(
    validateMandatory(formData.addressLine1),
  );
  const [invalidAddressLine1Helper, setInvalidAddressLine1Helper] = useState(
    '',
  );
  const [invalidCountry, setInvalidCountry] = useState(
    validateMandatory(formData.country),
  );
  const [invalidCountryHelper, setInvalidCountryHelper] = useState('');
  const [invalidZipCode, setInvalidZipCode] = useState(
    validateMandatory(formData.zipCode),
  );
  const [invalidZipCodeHelper, setInvalidZipCodeHelper] = useState('');
  const [invalidEmail, setInvalidEmail] = useState(
    validateEmail(formData.email),
  );
  const [invalidEmailHelper, setInvalidEmailHelper] = useState('');
  const [invalidCity, setInvalidCity] = useState(
    validateMandatory(formData.city),
  );
  const [invalidCityHelper, setInvalidCityHelper] = useState('');

  const invalidForm = !!(
    invalidGender ||
    invalidLastName ||
    invalidAddressLine1 ||
    invalidCountry ||
    invalidZipCode ||
    invalidCity ||
    invalidEmail
  );

  useEffect(() => {
    setOrder(onLoad());
  }, []);

  const loading = useSelector(({ cart: { loading } }) => loading);

  const hasOrder = order && order.length > 0;
  const canSubmit = hasOrder && !loading && !invalidForm;

  const postOrder = () =>
    dispatch(
      cartOrder({
        ...formData,
        order,
        countryNameDe: countries.getName(formData['country'], 'de', {
          select: 'official',
        }),
        countryNameEn: countries.getName(formData['country'], 'en', {
          select: 'official',
        }),
        genderText: genderOptions.find(
          (option) => option.value === formData['gender'],
        ).text,
      }),
    );

  const ButtonRow = () => (
    <>
      <Button
        className="back-button"
        type="reset"
        onClick={() => history.goBack()}
      >
        {intl.formatMessage(messages.back)}
      </Button>
      <Button
        className="order-button"
        primary
        type="submit"
        disabled={!canSubmit}
        onClick={postOrder}
      >
        {intl.formatMessage(messages.order)}
      </Button>
    </>
  );

  return (
    <Container id="page-document">
      <Form
        onSubmit={() => {
          if (canSubmit) {
            postOrder();
          }
          return false;
        }}
      >
        {hasOrder ? (
          <>
            <h1 className="summary-of-order">
              {intl.formatMessage(messages.fillOutYourData)}
            </h1>

            <Form.Select
              required
              fluid
              name="gender"
              label={intl.formatMessage(messages.gender)}
              error={
                invalidGenderHelper
                  ? {
                      content: invalidGenderHelper,
                      pointing: 'above',
                    }
                  : null
              }
              options={genderOptions}
              placeholder={intl.formatMessage(messages.gender)}
              value={formData.gender || ''}
              onChange={(_, { value }) => {
                dispatch(cartForm({ gender: value }));
                const invalid = validateMandatory(value);
                setInvalidGender(invalid);
                if (!invalid) {
                  setInvalidGenderHelper(invalid);
                }
              }}
              onBlur={() => {
                setInvalidGenderHelper(invalidGender);
              }}
            />
            <Form.Field
              label={intl.formatMessage(messages.firstName)}
              control={Input}
              name="firstName"
              placeholder={intl.formatMessage(messages.firstName)}
              value={formData.firstName || ''}
              onChange={({ target: { value } }) => {
                dispatch(cartForm({ firstName: value }));
              }}
            />
            <Form.Field
              required
              error={
                invalidLastNameHelper
                  ? {
                      content: invalidLastNameHelper,
                      pointing: 'above',
                    }
                  : null
              }
              label={intl.formatMessage(messages.lastName)}
              control={Input}
              name="lastName"
              placeholder={intl.formatMessage(messages.lastName)}
              value={formData.lastName || ''}
              onChange={({ target: { value } }) => {
                dispatch(cartForm({ lastName: value }));
                const invalid = validateMandatory(value);
                setInvalidLastName(invalid);
                if (!invalid) {
                  setInvalidLastNameHelper(invalid);
                }
              }}
              onBlur={() => {
                setInvalidLastNameHelper(invalidLastName);
              }}
            />
            <Form.Field
              label={intl.formatMessage(messages.company)}
              control={Input}
              name="company"
              placeholder={intl.formatMessage(messages.company)}
              value={formData.company || ''}
              onChange={({ target: { value } }) => {
                dispatch(cartForm({ company: value }));
              }}
            />
            <Form.Field
              required
              error={
                invalidAddressLine1Helper
                  ? {
                      content: invalidAddressLine1Helper,
                      pointing: 'above',
                    }
                  : null
              }
              label={intl.formatMessage(messages.addressLine1)}
              control={Input}
              name="addressLine1"
              placeholder={intl.formatMessage(messages.addressLine1)}
              value={formData.addressLine1 || ''}
              onChange={({ target: { value } }) => {
                dispatch(cartForm({ addressLine1: value }));
                const invalid = validateMandatory(value);
                setInvalidAddressLine1(invalid);
                if (!invalid) {
                  setInvalidAddressLine1Helper(invalid);
                }
              }}
              onBlur={() => {
                setInvalidAddressLine1Helper(invalidAddressLine1);
              }}
            />
            <Form.Field
              label={intl.formatMessage(messages.addressLine2)}
              control={Input}
              name="addressLine2"
              placeholder={intl.formatMessage(messages.addressLine2)}
              value={formData.addressLine2 || ''}
              onChange={({ target: { value } }) => {
                dispatch(cartForm({ addressLine2: value }));
              }}
            />
            <Form.Field
              required
              error={
                invalidZipCodeHelper
                  ? {
                      content: invalidZipCodeHelper,
                      pointing: 'above',
                    }
                  : null
              }
              label={intl.formatMessage(messages.zipCode)}
              control={Input}
              name="zipCode"
              placeholder={intl.formatMessage(messages.zipCode)}
              value={formData.zipCode || ''}
              onChange={({ target: { value } }) => {
                dispatch(cartForm({ zipCode: value }));
                const invalid = validateMandatory(value);
                setInvalidZipCode(invalid);
                if (!invalid) {
                  setInvalidZipCodeHelper(invalid);
                }
              }}
              onBlur={(_) => {
                setInvalidZipCodeHelper(invalidZipCode);
              }}
            />
            <Form.Field
              required
              error={
                invalidCityHelper
                  ? {
                      content: invalidCityHelper,
                      pointing: 'above',
                    }
                  : null
              }
              label={intl.formatMessage(messages.city)}
              control={Input}
              name="city"
              placeholder={intl.formatMessage(messages.city)}
              value={formData.city || ''}
              onChange={({ target: { value } }) => {
                dispatch(cartForm({ city: value }));
                const invalid = validateMandatory(value);
                setInvalidCity(invalid);
                if (!invalid) {
                  setInvalidCityHelper(invalid);
                }
              }}
              onBlur={(_) => {
                setInvalidCityHelper(invalidCity);
              }}
            />
            <Form.Select
              required
              fluid
              name="country"
              label={intl.formatMessage(messages.country)}
              error={
                invalidCountryHelper
                  ? {
                      content: invalidCountryHelper,
                      pointing: 'above',
                    }
                  : null
              }
              options={countryOptions}
              placeholder={intl.formatMessage(messages.country)}
              value={formData.country || ''}
              onChange={(_, { value }) => {
                dispatch(cartForm({ country: value }));
                const invalid = validateMandatory(value);
                setInvalidCountry(invalid);
                if (!invalid) {
                  setInvalidCountryHelper(invalid);
                }
              }}
              onBlur={() => {
                setInvalidCountryHelper(invalidCountry);
              }}
            />
            <Form.Field
              required
              error={
                invalidEmailHelper
                  ? {
                      content: invalidEmailHelper,
                      pointing: 'above',
                    }
                  : null
              }
              label={intl.formatMessage(messages.email)}
              control={Input}
              name="email"
              placeholder={intl.formatMessage(messages.email)}
              value={formData.email || ''}
              onChange={({ target: { value } }) => {
                dispatch(cartForm({ email: value }));
                const invalid = validateEmail(value);
                setInvalidEmail(invalid);
                if (!invalid) {
                  setInvalidEmailHelper(invalid);
                }
              }}
              onBlur={() => {
                setInvalidEmailHelper(invalidEmail);
              }}
            />
          </>
        ) : (
          <div className="empty-order">
            {intl.formatMessage(messages.emptyOrder)}
          </div>
        )}
        <ButtonRow />
        <CartSummary order={order} />
        <ButtonRow />
      </Form>
    </Container>
  );
};

export default CartCheckoutForm;
