import { useIntl } from 'react-intl';
import { Icon } from '@plone/volto/components';
import config from '@plone/volto/registry';

const IconForContentType = ({ type }) => {
  const intl = useIntl();
  return (
    <>
      <Icon
        className="itemIcon"
        size="30px"
        name={
          config.settings.contentTypeSearchResultIcons[type] ||
          config.settings.contentTypeSearchResultDefaultIcon
        }
      />
      <div className="itemIconTitle">
        {intl.formatMessage({
          id: type,
          defaultMessage: type,
        })}
      </div>
    </>
  );
};

export default IconForContentType;
