/**
 * Document view component.
 * @module components/theme/View/DefaultView
 */

import React from 'react';
import PropTypes from 'prop-types';
import { defineMessages, injectIntl } from 'react-intl';

import { Container, Image, Grid } from 'semantic-ui-react';
import { map } from 'lodash';
import { useSelector } from 'react-redux';
import { format, parse } from 'date-fns';

import { UniversalLink, Icon } from '@plone/volto/components';
import {
  getBlocksFieldname,
  getBlocksLayoutFieldname,
  hasBlocksData,
  getBaseUrl,
  expandToBackendURL,
} from '@plone/volto/helpers';

import calendarSVG from '@plone/volto/icons/calendar.svg';

import config from '@plone/volto/registry';

const messages = defineMessages({
  unknownBlock: {
    id: 'Unknown Block',
    defaultMessage: 'Unknown Block {block}',
  },
  start: {
    id: 'start',
    defaultMessage: 'start',
  },
  end: {
    id: 'end',
    defaultMessage: 'end',
  },
  location: {
    id: 'location',
    defaultMessage: 'location',
  },
  contact: {
    id: 'contact',
    defaultMessage: 'contact',
  },
  registerForEvent: {
    id: 'Register for Event',
    defaultMessage: 'Register for Event',
  },
  downloadEvent: {
    id: 'Download Event',
    defaultMessage: 'Save to calendar (ICS)',
  },
});

/**
 * Component to display the default view.
 * @function DefaultView
 * @param {Object} content Content object.
 * @returns {string} Markup of the component.
 */
const DefaultView = ({ content, intl, location }) => {
  const blocksFieldname = getBlocksFieldname(content);
  const blocksLayoutFieldname = getBlocksLayoutFieldname(content);
  const language = useSelector((state) => state.intl.locale);

  var deLocale = require('date-fns/locale/de');
  var enLocale = require('date-fns/locale/en');

  const icsDownloadLink = (
    <div className="download-event">
      <Icon name={calendarSVG} />
      <a
        className="ics-download"
        target="_blank"
        rel="noreferrer"
        href={`${expandToBackendURL(content['@id'])}/ics_view`}
      >
        {intl.formatMessage(messages.downloadEvent)}
      </a>
    </div>
  );

  return hasBlocksData(content) ? (
    <Container id="page-document" className="EventView">
      {map(content[blocksLayoutFieldname].items, (block) => {
        const Block =
          config.blocks.blocksConfig[
            content[blocksFieldname]?.[block]?.['@type']
          ]?.['view'] || null;
        if (
          config.blocks.blocksConfig[
            content[blocksFieldname]?.[block]?.['@type']
          ]?.['id'] === 'title'
        ) {
          return (
            <>
              <Block
                key={block}
                id={block}
                properties={content}
                data={content[blocksFieldname][block]}
                path={getBaseUrl(location?.pathname || '')}
              />
              {content.description && (
                <p className="description">{content.description}</p>
              )}
              <div className="event-details">
                <Grid columns={2}>
                  <Grid.Column>
                    {content?.start && (
                      <div>
                        <div>{intl.formatMessage(messages.start)}</div>
                        <div>
                          <strong>
                            {language === 'de'
                              ? format(
                                  parse(content?.start),
                                  'DD.MM.YYYY HH:mm',
                                  {
                                    locale: deLocale,
                                  },
                                )
                              : format(
                                  parse(content?.start),
                                  'Do MMMM YYYY HH:mm A',
                                  {
                                    locale: enLocale,
                                  },
                                )}
                            {intl.locale === 'de' ? ' Uhr' : ''}
                          </strong>
                        </div>
                      </div>
                    )}
                    {content?.end && (
                      <div>
                        <div>{intl.formatMessage(messages.end)}</div>
                        <div>
                          <strong>
                            {language === 'de'
                              ? format(
                                  parse(content?.end),
                                  'DD.MM.YYYY HH:mm',
                                  {
                                    locale: deLocale,
                                  },
                                )
                              : format(
                                  parse(content?.end),
                                  'Do MMMM YYYY HH:mm A',
                                  {
                                    locale: enLocale,
                                  },
                                )}
                            {intl.locale === 'de' ? ' Uhr' : ''}
                          </strong>
                        </div>
                      </div>
                    )}
                    {content?.location && (
                      <div>
                        <div>{intl.formatMessage(messages.location)}</div>
                        <div>
                          <strong>{content.location}</strong>
                        </div>
                      </div>
                    )}
                  </Grid.Column>
                  <Grid.Column>
                    {(content?.contact_name ||
                      content?.contact_email ||
                      content?.contact_phone) && (
                      <div>
                        <div>{intl.formatMessage(messages.contact)}</div>
                        <div>
                          {content?.contact_name && (
                            <p>
                              <strong>{content.contact_name}</strong>
                            </p>
                          )}
                          {content?.contact_email && (
                            <p>
                              <a href={`mailto:${content.contact_email}`}>
                                {content.contact_email}
                              </a>
                            </p>
                          )}
                          {content?.contact_phone && (
                            <p>
                              Tel.{' '}
                              <a href={`tel:${content.contact_phone}`}>
                                {content.contact_phone}
                              </a>
                            </p>
                          )}
                        </div>
                      </div>
                    )}
                    {content?.event_url && (
                      <div>
                        <UniversalLink
                          className="event-url"
                          href={content.event_url}
                        >
                          {intl.formatMessage(messages.registerForEvent)}
                        </UniversalLink>
                      </div>
                    )}
                  </Grid.Column>
                </Grid>
              </div>
            </>
          );
        }
        return Block !== null ? (
          <Block
            key={block}
            id={block}
            properties={content}
            data={content[blocksFieldname][block]}
            path={getBaseUrl(location?.pathname || '')}
          />
        ) : (
          <div key={block}>
            {intl.formatMessage(messages.unknownBlock, {
              block: content[blocksFieldname]?.[block]?.['@type'],
            })}
          </div>
        );
      })}
      {icsDownloadLink}
    </Container>
  ) : (
    <Container id="page-document" className="EventView">
      <h1 className="documentFirstHeading">{content.title}</h1>
      {content.description && (
        <p className="documentDescription">{content.description}</p>
      )}
      {content.image && (
        <Image
          className="document-image"
          src={content.image.scales.thumb.download}
          floated="right"
        />
      )}
      {content.remoteUrl && (
        <span>
          The link address is:
          <a href={content.remoteUrl}>{content.remoteUrl}</a>
        </span>
      )}
      {content.text && (
        <div
          dangerouslySetInnerHTML={{
            __html: content.text.data,
          }}
        />
      )}
      {icsDownloadLink}
    </Container>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
DefaultView.propTypes = {
  /**
   * Content of the object
   */
  content: PropTypes.shape({
    /**
     * Title of the object
     */
    title: PropTypes.string,
    /**
     * Description of the object
     */
    description: PropTypes.string,
    /**
     * Text of the object
     */
    text: PropTypes.shape({
      /**
       * Data of the text of the object
       */
      data: PropTypes.string,
    }),
  }).isRequired,
};

export default injectIntl(DefaultView);
