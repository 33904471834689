/**
 * Root reducer.
 * @module reducers/root
 */

import defaultReducers from '@plone/volto/reducers';
import nearestinstitut from './nearestinstitut/nearestinstitut';
import fzjsettings from './fzjsettings/fzjsettings';
import contactform from './contactform/contactform';
import unlockform from './unlockform/unlockform';
import solrsearch from './solrsearch/solrsearch';
import institutlist from './institutlist/institutlist';
import cart from './cart/cart';
import jobOfferSearch from './job-offer/job-offer-search';
import relations from './relations/relations';
import searchSuggestions from './searchSuggestions/searchSuggestions';

/**
 * Root reducer.
 * @function
 * @param {Object} state Current state.
 * @param {Object} action Action to be handled.
 * @returns {Object} New state.
 */
const reducers = {
  ...defaultReducers,
  nearestinstitut,
  fzjsettings,
  contactform,
  unlockform,
  solrsearch,
  institutlist,
  cart,
  jobOfferSearch,
  relations,
  searchSuggestions,
};

export default reducers;
