import { matchPath, useLocation } from 'react-router-dom';
import config from '@plone/volto/registry';

export default function useSlots(slotName) {
  const { slots } = config;
  const pathname = useLocation().pathname;

  if (!slots[slotName]) {
    return [null, null];
  }

  const currentSlot = slots[slotName];
  const active = currentSlot.filter((slot) =>
    matchPath(pathname, { path: slot.path, exact: slot.exact }),
  );

  return [true, active];
}
