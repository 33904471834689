/**
 * OVERRIDE ScrollToTop.jsx
 * REASON: Improve ghost content loading, add scroll to top delay.
 * FILE: https://github.com/plone/volto/blob/master/src/helpers/ScrollToTop/ScrollToTop.jsx
 * FILE VERSION: Volto 14.6.0
 * PULL REQUEST: https://github.com/kitconcept/fzj-internet/pull/1024
 * TICKET: https://jugit.fz-juelich.de/fzj-internet/internet/-/issues/776
 * DATE: 2022-02-09
 * DEVELOPER: @reebalazs
 * CHANGELOG:
 * - Improve ghost content loading, add scroll to top delay (#776) @reebalazs
 *
 * Every change is marked with a JSX comment at the beginning and end of the change:
 *
 *   START CUSTOMIZATION
 *   <CUSTOMIZATION>
 *   END CUSTOMIZATION
 */

import React from 'react';
import PropTypes from 'prop-types';
// START CUSTOMIZATION
import { connect } from 'react-redux';
// END CUSTOMIZATION

/**
 *
 *
 * @class ScrollToTop
 * @extends {Component}
 */
class ScrollToTop extends React.Component {
  /**
   * Property types.
   * @property {Object} propTypes Property types.
   * @static
   */
  static propTypes = {
    location: PropTypes.shape({
      pathname: PropTypes.string,
    }),
    children: PropTypes.node.isRequired,
  };

  /**
   * @param {*} prevProps Previous Props
   * @returns {null} Null
   * @memberof ScrollToTop
   */
  componentDidUpdate(prevProps) {
    // START CUSTOMIZATION
    const { location } = this.props;
    const isHash = location?.hash || location?.pathname.hash;
    if (
      !isHash &&
      this.props.location?.pathname !== prevProps.location?.pathname
    ) {
      // END CUSTOMIZATION
      window.scrollTo(0, 0);
    }
  }

  /**
   * @returns {node} Children
   * @memberof ScrollToTop
   */
  render() {
    return this.props.children;
  }
}

// START CUSTOMIZATION
export default connect(
  (state, props) => ({
    location: state.loadProtector.location,
  }),
  {},
)(ScrollToTop);
// END CUSTOMIZATION
