import { useEffect } from 'react';
import { Image } from 'semantic-ui-react';
import { searchContent } from '@plone/volto/actions';
import { useSelector, useDispatch } from 'react-redux';
import { flattenToAppURL } from '@plone/volto/helpers';
import { defineMessages, useIntl } from 'react-intl';

const messages = defineMessages({
  amountColumn: {
    id: 'amountColumn',
    defaultMessage: 'Amount', // Menge
  },
  previewColumn: {
    id: 'previewColumn',
    defaultMessage: 'Preview', // Ansicht
  },
  descriptionColumn: {
    id: 'descriptionColumn',
    defaultMessage: 'Description', // Beschreibung
  },
  summaryOfOrder: {
    id: 'summaryOfOrder',
    defaultMessage: 'Summary of your order:',
  },
});

const CartSummary = ({ order }) => {
  const items = useSelector((state) =>
    state.search.subrequests.cartitems?.items.filter(
      (item) => item.parent['@type'] === 'Cart',
    ),
  );
  const intl = useIntl();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      searchContent(
        '/',
        {
          portal_type: ['CartItem'],
          fullobjects: true,
          b_size: 100000000,
        },
        'cartitems',
      ),
    );
  }, [dispatch]);

  return (
    <div>
      <h1 className="summary-of-order">
        {intl.formatMessage(messages.summaryOfOrder)}
      </h1>
      <table className="ui celled table inverted cart-table">
        <thead>
          <tr>
            <th>{intl.formatMessage(messages.amountColumn)}</th>
            <th>{intl.formatMessage(messages.previewColumn)}</th>
            <th>{intl.formatMessage(messages.descriptionColumn)}</th>
          </tr>
        </thead>
        <tbody>
          {order ? (
            order.map((chartItem) => {
              const item = items
                ? items.find((item) => item['@id'] === chartItem.id)
                : undefined;
              return (
                <tr key={chartItem.id}>
                  <td className="amount-col">{chartItem.amount}</td>
                  <td>
                    <Image
                      src={flattenToAppURL(
                        item?.preview_image?.scales?.preview?.download,
                      )}
                      size="small"
                      floated="right"
                      alt={item?.image_caption}
                      avatar
                    />
                  </td>
                  <td
                    dangerouslySetInnerHTML={{ __html: item?.text?.data }}
                  ></td>
                </tr>
              );
            })
          ) : (
            <tr></tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default CartSummary;
