import React from 'react';
import { FormFieldWrapper } from '@plone/volto/components';
import { ChromePicker } from 'react-color';

const ColorChooserWidget = (props) => {
  const { id, value } = props;

  const decimalToHex = (alpha) =>
    alpha === 0 ? '00' : Math.round(255 * alpha).toString(16);

  function onChange(color, event) {
    props.onChange(id, `${color.hex}${decimalToHex(color.rgb.a)}`);
  }

  return (
    <FormFieldWrapper {...props}>
      <ChromePicker color={value || {}} onChangeComplete={onChange} />
    </FormFieldWrapper>
  );
};

export default ColorChooserWidget;
