export const schemaJobFilter = (props) => {
  return {
    required: [],
    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: ['title', 'jobsLocation'],
      },
    ],
    properties: {
      title: {
        title: 'Headline',
        widget: 'text',
      },
      jobsLocation: {
        title: 'Jobs Location',
        widget: 'object_browser',
        mode: 'link',
        allowExternals: true,
      },
    },
  };
};

export default schemaJobFilter;
