import { defineMessages } from 'react-intl';

const messages = defineMessages({
  Source: {
    id: 'Source',
    defaultMessage: 'Source',
  },
  title: {
    id: 'Title',
    defaultMessage: 'Title',
  },
  description: {
    id: 'Description',
    defaultMessage: 'Description',
  },
  buttonLink: {
    id: 'Button Link',
    defaultMessage: 'Button Link',
  },
  buttonTitle: {
    id: 'Button Title',
    defaultMessage: 'Button Title',
  },
  imageOverride: {
    id: 'Image override',
    defaultMessage: 'Image override',
  },
  width: {
    id: 'Width',
    defaultMessage: 'Width',
  },
  height: {
    id: 'Height',
    defaultMessage: 'Height',
  },
  small: {
    id: 'Small',
    defaultMessage: 'Small',
  },
  medium: {
    id: 'Medium',
    defaultMessage: 'Medium',
  },
  large: {
    id: 'Large',
    defaultMessage: 'Large',
  },
  default: {
    id: 'Default',
    defaultMessage: 'Default',
  },
  narrow: {
    id: 'Narrow',
    defaultMessage: 'Narrow',
  },
});

export function HighlightTeaserSchema(props) {
  const { intl } = props;

  return {
    block: 'highlightTeaser',
    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: [
          'href',
          'title',
          'description',
          'preview_image',
          'linkTitle',
          'link',
          'width',
          'height',
        ],
      },
    ],
    properties: {
      href: {
        title: intl.formatMessage(messages.Source),
        widget: 'object_browser',
        mode: 'link',
        selectedItemAttrs: ['Title', 'Description', 'hasPreviewImage'],
        allowExternals: true,
      },
      title: {
        title: intl.formatMessage(messages.title),
      },
      description: {
        title: intl.formatMessage(messages.description),
        widget: 'textarea',
      },
      preview_image: {
        title: intl.formatMessage(messages.imageOverride),
        widget: 'object_browser',
        mode: 'image',
        allowExternals: true,
      },
      width: {
        title: intl.formatMessage(messages.width),
        choices: [
          ['100%', '100%'],
          ['default', `${intl.formatMessage(messages.default)}`],
          ['narrow', `${intl.formatMessage(messages.narrow)}`],
        ],
        default: 'default',
      },
      height: {
        title: intl.formatMessage(messages.height),
        choices: [
          ['325px', `${intl.formatMessage(messages.small)}`],
          ['600px', `${intl.formatMessage(messages.medium)}`],
          ['800px', `${intl.formatMessage(messages.large)}`],
        ],
        default: '800px',
      },
      link: {
        title: intl.formatMessage(messages.buttonLink),
        widget: 'object_browser',
        mode: 'link',
        allowExternals: true,
      },
      linkTitle: {
        title: intl.formatMessage(messages.buttonTitle),
      },
    },
    required: [],
  };
}
