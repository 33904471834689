import React from 'react';
import { SchemaRenderer } from '@kitconcept/volto-blocks/components';
import { iconBlockSchema } from './schema';
import { useIntl, defineMessages } from 'react-intl';

const messages = defineMessages({
  Icon: {
    id: 'Icon',
    defaultMessage: 'Icon',
  },
});

const IconBlockData = (props) => {
  const { data, block, onChangeBlock } = props;
  const intl = useIntl();

  const schema = iconBlockSchema(props);

  return (
    <SchemaRenderer
      schema={schema}
      title={intl.formatMessage(messages.Icon)}
      onChangeField={(id, value) => {
        onChangeBlock(block, {
          ...data,
          [id]: value,
        });
      }}
      formData={data}
      fieldIndex={data.index}
      basic
      unwrapped
      block={block}
    />
  );
};

export default IconBlockData;
