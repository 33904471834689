import React, { useEffect } from 'react';
import config from '@plone/volto/registry';
import { flattenToAppURL } from '@plone/volto/helpers';
import { DetachedTextBlockEditor } from 'volto-slate/blocks/Text/DetachedTextBlockEditor';
import { TextBlockView } from 'volto-slate/blocks/Text';
import { useIntl } from 'react-intl';
import cx from 'classnames';
import { Icon } from '@plone/volto/components';
import quoteSVG from '@package/icons/quote.svg';

const View = (props) => {
  const { data, isEditMode } = props;
  const intl = useIntl();

  // Remove padding from grandparent element for all Quote blocks in document.
  // This could be implemented CSS-only using :has(), but it is not yet supported by latest Firefox ESR (115.12.0)
  useEffect(() => {
    const quoteBlockContainer = document.querySelectorAll('.block.quote');
    quoteBlockContainer.forEach(
      (item) => (item.parentElement.parentElement.style.padding = 0),
    );
  }, []);

  const customSlateSettings = {
    ...props,
    slateSettings: {
      ...config.settings.slate,
      toolbarButtons: config.settings.slate.toolbarButtons.filter(
        (index) => index - config.settings.slate.toolbarButtons,
      ),
    },
  };

  const style = {
    marginTop: data?.image?.length > 0 ? '150px' : '0px',
  };

  return (
    <div className="block quote">
      <div className="inner-wrapper" style={style}>
        {config.blocks?.blocksConfig?.quote?.showImageField && data.image?.[0] && (
          <div className={cx('image-wrapper', `align-${data?.alignment}`)}>
            <img
              src={`${flattenToAppURL(
                data.image?.[0]?.['@id'],
              )}/@@images/image/preview`}
              alt={data.image?.[0]?.title}
              className="image"
              loading="lazy"
            />
          </div>
        )}
        <blockquote
          className="not-inline"
          cite={data.cite}
          lang={
            data.quotationLanguage !== intl.locale && data.quotationLanguage
          }
        >
          <Icon name={quoteSVG} size="64px" />
          {!isEditMode ? (
            <TextBlockView {...props} />
          ) : (
            <DetachedTextBlockEditor {...customSlateSettings} />
          )}
          <footer>
            {data.author && data.additional_information
              ? data.author + ', '
              : data.author}
            {data.additional_information && data.cite ? (
              <cite>{data.additional_information}</cite>
            ) : (
              data.additional_information && (
                <span>{data.additional_information}</span>
              )
            )}
          </footer>
        </blockquote>
      </div>
    </div>
  );
};

export default View;
