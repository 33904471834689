import { defineMessages } from 'react-intl';

const messages = defineMessages({
  Source: {
    id: 'Source',
    defaultMessage: 'Source',
  },
  openLinkInNewTab: {
    id: 'Open in a new tab',
    defaultMessage: 'Open in a new tab',
  },
  addLink: {
    id: 'Add Link',
    defaultMessage: 'Add Link',
  },
  title: {
    id: 'Title',
    defaultMessage: 'Title',
  },
  description: {
    id: 'Description',
    defaultMessage: 'Description',
  },
  imageOverride: {
    id: 'Image override',
    defaultMessage: 'Image override',
  },
  button: {
    id: 'Button Text',
    defaultMessage: 'Button Text',
  },
  item: {
    id: 'Item',
    defaultMessage: 'Item',
  },
  link: {
    id: 'Link',
    defaultMessage: 'Link',
  },
  internalLink: {
    id: 'Internal Link',
    defaultMessage: 'Internal Link',
  },
  externalLink: {
    id: 'External Link',
    defaultMessage: 'External Link',
  },
});

const LinkSchema = (props) => {
  const { intl } = props;

  return {
    title: intl.formatMessage(messages.link),
    addMessage: intl.formatMessage(messages.addLink),
    fieldsets: [
      {
        id: 'default',
        title: 'default',
        fields: ['href', 'title'],
      },
    ],
    properties: {
      href: {
        title: intl.formatMessage(messages.link),
        widget: 'object_browser',
        mode: 'link',
        selectedItemAttrs: ['Title', 'Description', 'hasPreviewImage'],
        allowExternals: true,
      },
      title: {
        title: intl.formatMessage(messages.title),
      },
    },
  };
};

export const TeaserWithLinkSchema = (props) => {
  const { intl } = props;

  return {
    title: intl.formatMessage(messages.item),
    block: 'teaserWithLink',
    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: [
          'href',
          'title',
          'description',
          'button',
          'preview_image',
          'openLinkInNewTab',
          'links',
        ],
      },
    ],

    properties: {
      href: {
        title: intl.formatMessage(messages.Source),
        widget: 'object_browser',
        mode: 'link',
        selectedItemAttrs: ['Title', 'Description', 'hasPreviewImage'],
        allowExternals: true,
      },
      title: {
        title: intl.formatMessage(messages.title),
      },
      description: {
        title: intl.formatMessage(messages.description),
        widget: 'textarea',
      },
      button: {
        title: intl.formatMessage(messages.button),
      },
      preview_image: {
        title: intl.formatMessage(messages.imageOverride),
        widget: 'object_browser',
        mode: 'image',
        allowExternals: true,
      },
      openLinkInNewTab: {
        title: intl.formatMessage(messages.openLinkInNewTab),
        type: 'boolean',
      },
      links: {
        widget: 'object_list',
        title: intl.formatMessage(messages.addLink),
        schema: LinkSchema,
      },
    },
    required: [],
  };
};
