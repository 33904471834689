import React from 'react';
import PropTypes from 'prop-types';
import { Message } from 'semantic-ui-react';
import { defineMessages, useIntl } from 'react-intl';
import imageBlockSVG from '@plone/volto/components/manage/Blocks/Image/block-image.svg';
import { flattenToAppURL } from '@plone/volto/helpers';
import { getTeaserImageURL } from './utils';
import rightSVG from '@plone/volto/icons/right-key.svg';
import { ConditionalLink, Icon, UniversalLink } from '@plone/volto/components';

const messages = defineMessages({
  PleaseChooseContent: {
    id: 'Please choose an existing content as source for this element',
    defaultMessage:
      'Please choose an existing content as source for this element',
  },
});

const TeaserWithLinksView = (props) => {
  const { data, isEditMode } = props;
  const intl = useIntl();
  const href = data.href?.[0];
  const image = data.preview_image?.[0];
  const moreMessage =
    flattenToAppURL(props.path || props.pathname)?.split('/')[1] === 'de'
      ? 'Mehr'
      : 'More';

  return (
    <>
      {!href && isEditMode && (
        <Message>
          <div className="grid-teaser-item default">
            <img src={imageBlockSVG} alt="" />
            <p>{intl.formatMessage(messages.PleaseChooseContent)}</p>
          </div>
        </Message>
      )}
      {href && (
        <div className="grid-teaser-item-with-link top">
          {(href.hasPreviewImage || image) && (
            <div className="grid-image-wrapper">
              <img
                src={flattenToAppURL(getTeaserImageURL(href, image))}
                alt="a"
                loading="lazy"
              />
            </div>
          )}
          <div className="content">
            <h2>{data?.title}</h2>
            {!data.hide_description && <p>{data?.description}</p>}
            <ConditionalLink
              condition={!isEditMode}
              to={flattenToAppURL(href['@id'])}
              target={data.openLinkInNewTab ? '_blank' : null}
            >
              <div className="more-button">
                <Icon className="more-right-icon" name={rightSVG} size="18px" />
                {data?.button ? data.button : moreMessage}
              </div>
            </ConditionalLink>
            <div className="link">
              {data.links
                ? data.links.map((item, index) => (
                    <div>
                      {item?.href?.[0]?.['@id'] && (
                        <UniversalLink href={item.href[0]['@id'] || '#'}>
                          <Icon name={rightSVG} size="24px" />

                          {item.title || item.href[0].title}
                        </UniversalLink>
                      )}
                    </div>
                  ))
                : null}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

TeaserWithLinksView.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  isEditMode: PropTypes.bool,
};

export default TeaserWithLinksView;
