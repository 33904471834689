import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { flattenToAppURL } from '@plone/volto/helpers';
import { Icon } from '@plone/volto/components';
import { mapMonth } from '@package/helpers';

import memberSVG from '@package/icons/fallback-avatar-darkblue.svg';
import globeSVG from '@package/icons/globe.svg';
import awardSVG from '@package/icons/award.svg';

export const AwardTopline = (item) => {
  const { award } = item;
  const intl = useIntl();

  return (
    <div className="award-topline">
      {award?.location && <span className="location">{award.location},</span>}
      {award?.award_date_month && (
        <span className="month">
          <FormattedMessage id={mapMonth(award.award_date_month)} />
        </span>
      )}
      {award?.award_date_year && (
        <span className="year">{award.award_date_year}</span>
      )}
      {award?.award_type &&
        (award?.location ||
          award?.award_date_month ||
          award?.award_date_year) && <span className="divider">|</span>}
      {award?.award_type && (
        <span className="type">
          {intl.locale === 'de' && award.award_type.title === 'Misc'
            ? 'Sonstige'
            : award.award_type.title}
        </span>
      )}
    </div>
  );
};

export const AwardOverlay = (props) => {
  const { award, onClose } = props;

  return (
    <div className="award-overlay">
      <span
        className="exit"
        onClick={onClose}
        role="button"
        onKeyDown={onClose}
        tabIndex={0}
      >
        &#x2715;
      </span>
      <div className="inner">
        <div className="icon-wrapper">
          <Icon name={awardSVG} size="143px" />
        </div>
        <AwardTopline award={award} />
        <h2>{award.title}</h2>
        <div className="member-container">
          {award.members.map((member) => (
            <div className="member">
              <div className="icon-wrapper">
                {member.image ? (
                  <img
                    src={flattenToAppURL(
                      `${member['@id']}/@@images/image/mini`,
                    )}
                    alt={`${member?.firstname} ${member?.name}`}
                  />
                ) : (
                  <Icon name={memberSVG} size="80px" />
                )}
              </div>
              <Link to={flattenToAppURL(member?.['@id'])} className="name">
                {member?.firstname || member?.name
                  ? `${member?.academic ? member.academic + ' ' : ''} ${
                      member?.firstname ? member.firstname + ' ' : ''
                    } ${member?.name ? member.name : ''}`
                  : member?.title}
              </Link>
            </div>
          ))}
        </div>

        <div className="description">
          <p>{award?.description}</p>
        </div>
        {award?.website_link && (
          <div className="website">
            <div className="icon-wrapper">
              <a href={flattenToAppURL(award?.website_link)}>
                <Icon name={globeSVG} size="30px" />
              </a>
            </div>
            <a className="visit" href={flattenToAppURL(award?.website_link)}>
              <FormattedMessage
                id="Find more information here"
                defaultMessage="Find more information here"
              />
            </a>
          </div>
        )}
      </div>
    </div>
  );
};
