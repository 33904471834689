import React from 'react';
import { Message, Grid } from 'semantic-ui-react';
import { defineMessages, useIntl } from 'react-intl';
import MemberBody from './Body';
import GridVariation from './gridBody';
import teaserHeroTopTemplate from '@kitconcept/volto-blocks/components/TeaserHero/teaserhero-top-template.svg';
import { withBlockExtensions } from '@plone/volto/helpers';

const messages = defineMessages({
  PleaseChooseContent: {
    id: 'Please choose an existing content as source for this element',
    defaultMessage:
      'Please choose an existing content as source for this element',
  },
});

const MemberListView = (props) => {
  const { data, isEditMode, variation } = props;
  const intl = useIntl();
  const isGridVariation =
    variation.id === 'personsGrid' ||
    variation.id === 'personsGridWithoutImage';
  return (
    <div className="member-list">
      {(data.columns?.length === 0 || !data.columns) && isEditMode && (
        <Message>
          <div className="teaser-item default">
            <img src={teaserHeroTopTemplate} alt="" />
            <p>{intl.formatMessage(messages.PleaseChooseContent)}</p>
          </div>
        </Message>
      )}
      {isGridVariation ? (
        <GridVariation {...props} />
      ) : (
        data.columns?.length > 0 && (
          <div>
            {data.headline && <h2 className="headline">{data.headline}</h2>}
            <Grid columns="1">
              <Grid.Row>
                {data.columns &&
                  data.columns.map((item, index) => (
                    <Grid.Column key={index}>
                      <MemberBody
                        key={item.id}
                        data={item}
                        isEditMode={isEditMode}
                        variation={variation.id}
                      />
                    </Grid.Column>
                  ))}
              </Grid.Row>
            </Grid>
          </div>
        )
      )}
    </div>
  );
};

export default withBlockExtensions(MemberListView);
