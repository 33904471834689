import React from 'react';
// import { defineMessages, useIntl } from 'react-intl';
// import imageBlockSVG from '@plone/volto/components/manage/Blocks/Image/block-image.svg';
import { flattenToAppURL } from '@plone/volto/helpers';
import { MaybeWrap, UniversalLink } from '@plone/volto/components';
import dummyImage from '@package/icons/dummy-image.png';

// const messages = defineMessages({
//   PleaseChooseContent: {
//     id: 'Please choose an existing content as source for this element',
//     defaultMessage:
//       'Please choose an existing content as source for this element',
//   },
// });

// defineMessages({
//   PleaseChooseContent: {
//     id: 'Please choose an existing content as source for this element',
//     defaultMessage:
//       'Please choose an existing content as source for this element',
//   },
//   moreInfo: {
//     id: 'moreInfo',
//     defaultMessage: 'More info',
//   },
// });

const CardsListing = ({ items, isEditMode }) => {
  // const intl = useIntl();
  // const href = data.href?.[0];
  // const image = data.preview_image?.[0];

  return (
    <div className="cards-listing">
      {items?.map((item) => (
        <div className="grid-teaser-item top" key={item.id}>
          <MaybeWrap
            condition={!isEditMode}
            as={UniversalLink}
            href={item['@id']}
          >
            <>
              <div className="grid-image-wrapper">
                <img
                  src={
                    item.image_field
                      ? flattenToAppURL(
                          `${item['@id']}/@@images/${item.image_field}/preview`,
                        )
                      : dummyImage
                  }
                  alt={item.title}
                />
              </div>

              <h3>{item?.title}</h3>
              <p>{item?.description}</p>
            </>
          </MaybeWrap>
        </div>
      ))}
    </div>
  );
};

export default CardsListing;
