import React from 'react';
import PropTypes from 'prop-types';
import { Message } from 'semantic-ui-react';
import { defineMessages, useIntl } from 'react-intl';
import imageBlockSVG from '@plone/volto/components/manage/Blocks/Image/block-image.svg';
import { flattenToAppURL } from '@plone/volto/helpers';
import { MaybeWrap, UniversalLink } from '@plone/volto/components';
import { getTeaserImageURL } from './utils';

const messages = defineMessages({
  PleaseChooseContent: {
    id: 'Please choose an existing content as source for this element',
    defaultMessage:
      'Please choose an existing content as source for this element',
  },
});

const TeaserDefaultTemplate = ({ data, isEditMode }) => {
  const intl = useIntl();

  return (
    <>
      {!data.href && isEditMode && (
        <Message>
          <div className="grid-teaser-item default">
            <img src={imageBlockSVG} alt="" />
            <p>{intl.formatMessage(messages.PleaseChooseContent)}</p>
          </div>
        </Message>
      )}
      {data.href && (
        <div className="grid-teaser-item content-container top">
          <MaybeWrap
            condition={!isEditMode}
            as={UniversalLink}
            href={data.href['@id']}
            target={data.openLinkInNewTab ? '_blank' : null}
          >
            {(data.href.hasPreviewImage || data.image) && (
              <div
                className="grid-image-wrapper"
                style={{ marginBottom: '0px' }}
              >
                <img
                  src={flattenToAppURL(
                    getTeaserImageURL(data.href, data.image),
                  )}
                  alt=""
                  loading="lazy"
                />
              </div>
            )}
            <div className="content">
              {data?.head_title && (
                <h2 className="headtitle">{data?.head_title}</h2>
              )}
              <h2>{data?.title}</h2>
              <p>{data?.description}</p>
            </div>
          </MaybeWrap>
        </div>
      )}
    </>
  );
};

TeaserDefaultTemplate.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  isEditMode: PropTypes.bool,
};

export default TeaserDefaultTemplate;
