import { useState, useEffect, useRef } from 'react';
import { Container } from 'semantic-ui-react';
import { UniversalLink } from '@plone/volto/components';
import cx from 'classnames';
import { useDispatch } from 'react-redux';
import { Icon } from '@plone/volto/components';
import { FormattedMessage, useIntl } from 'react-intl';
import { getInstitutList } from '@package/actions';
import { flattenToAppURL } from '@plone/volto/helpers';
// import { ContactForm } from '@package/components';
import { MailTo } from '@package/helpers';

import globeSVG from '@package/icons/globe.svg';
import externalLinkSVG from '@package/icons/external-link.svg';
import avatarSVG from '@package/icons/fallback-avatar-grey-background.svg';

const mapMonth = (month) =>
  ({
    ['01' || '1' || 'Januar']: 'January',
    ['02' || '2' || 'Februar']: 'February',
    ['03' || '3' || 'März']: 'March',
    ['04' || '4']: 'April',
    ['05' || '5' || 'Mai']: 'May',
    ['06' || '6' || 'Juni']: 'June',
    ['07' || '7' || 'Juli']: 'July',
    ['08' || '8']: 'August',
    ['09' || '9']: 'September',
    ['10' || 'Oktober']: 'October',
    '11': 'November',
    ['12' || 'Dezember']: 'December',
  }[month] || month);

const SponsorItem = (props) => {
  const { partner, logo, link } = props;

  return (
    partner && (
      <div className="item">
        {logo ? (
          link ? (
            <UniversalLink href={link} alt="">
              <img src={logo.scales.preview.download} alt=""></img>
            </UniversalLink>
          ) : (
            <img src={logo.scales.preview.download} alt=""></img>
          )
        ) : link ? (
          <>
            <div className="icon-wrapper">
              <Icon name={externalLinkSVG} size="14px" />
            </div>
            <UniversalLink href={link} alt="">
              {partner}
            </UniversalLink>
          </>
        ) : (
          <p>{partner}</p>
        )}
      </div>
    )
  );
};

const Contact = (props) => {
  const { contact } = props;
  // const [showContactForm, setShowContactForm] = useState(false);

  return contact && Object.keys(contact).length > 0 ? (
    <>
      {/* {showContactForm && (
        <ContactForm
          onClose={() => setShowContactForm(false)}
          memberURL={flattenToAppURL(contact?.['@id'])}
          content={contact}
          image_field={contact.image ? 'image' : null}
        />
      )} */}
      <div className="image-wrapper">
        {contact.image ? (
          <img
            src={`${flattenToAppURL(contact['@id'])}/@@images/image/preview`}
            alt="contact"
          />
        ) : (
          <Icon name={avatarSVG} size="160px" />
        )}
      </div>
      <div className="details">
        <p className="name">
          {contact?.academic} {contact?.firstname} {contact?.name}
        </p>
        <p className="positions">{contact?.positions}</p>
        <p className="room building">
          <FormattedMessage id="Building" defaultMessage="Building" />{' '}
          {contact?.building}
          {' / '}
          <FormattedMessage id="Room" defaultMessage="Room" /> {contact?.room}
        </p>
        <p>{contact?.phone}</p>
        {contact?.email && (
          <MailTo email={contact.email} className="mail" />
          // <span
          //   onClick={() => {
          //     setShowContactForm(true);
          //   }}
          //   onKeyDown={(e) => {
          //     if (e.key === 'Enter') {
          //       setShowContactForm(true);
          //     }
          //   }}
          //   className="mail"
          //   role="button"
          //   tabIndex={0}
          // >
          //   E-Mail
          // </span>
        )}
      </div>
    </>
  ) : null;
};

const ProjectView = (props) => {
  const { content, location } = props;
  const [instituteList, setInstituteList] = useState([]);

  const intl = useIntl();
  const dispatch = useDispatch();
  const involvedInstitutes = useRef([]);

  const mapInstitutes = (instituteVocab) => {
    let instituteArr = [];
    instituteVocab.map((item) => instituteArr.push(item.token));
    return instituteArr;
  };

  useEffect(() => {
    if (content.involved_institutes && content.involved_institutes.length > 0) {
      involvedInstitutes.current = mapInstitutes(content.involved_institutes);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, content.involved_institutes]);

  useEffect(() => {
    if (involvedInstitutes.current && involvedInstitutes.current.length > 0) {
      dispatch(getInstitutList(involvedInstitutes.current)).then((resp) => {
        if (resp) {
          setInstituteList(resp.items);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const hasStart = content?.project_start_year ? true : false;
  const hasEnd = content?.project_end_year ? true : false;

  const start = hasStart
    ? mapMonth(content?.project_start_month) +
      ' ' +
      content?.project_start_year +
      ' '
    : ' ';
  const end = hasEnd
    ? ' ' +
      mapMonth(content?.project_end_month) +
      ' ' +
      content?.project_end_year
    : ' ';

  return (
    <div className="project contenttype view">
      <Container className="project">
        <div className="project view">
          <div className="left column">
            {content?.image && (
              <div className="logo">
                <img src={content?.image.scales.preview.download} alt="logo" />
              </div>
            )}
            {hasStart && (
              <div className="duration">
                <h3>
                  <FormattedMessage id="Duration" defaultMessage="Duration" />
                </h3>
                <p>
                  <FormattedMessage id={start} />
                  {intl.locale === 'en' ? 'to' : 'bis'}
                  <FormattedMessage id={end} />
                </p>
              </div>
            )}
            {content.members &&
              content.members.length > 0 &&
              Object.keys(content.members).length > 0 && (
                <div className="contact">
                  <h3>
                    <FormattedMessage id="Contact" defaultMessage="Contact" />
                  </h3>
                  {content.members.slice(0, 3).map((contact) => (
                    <Contact contact={contact} />
                  ))}
                </div>
              )}
          </div>
          <div className="right column">
            <span className="type">
              {content.project_type && content.project_type.title}
            </span>
            <h1 className="documentFirstHeading">{content.title}</h1>
            <h2 className="short-description">{content?.description}</h2>
            {content.detailed_description && (
              <div
                className="description"
                dangerouslySetInnerHTML={{
                  __html: content.detailed_description.data,
                }}
              />
            )}
            <div className="more">
              {content.link_further_information && (
                <div className="more information">
                  <div className="icon-wrapper">
                    <Icon name={globeSVG} size="30px" />
                  </div>
                  <UniversalLink
                    href={content.link_further_information}
                    alt="more information"
                  >
                    {content?.link_further_information_text}
                  </UniversalLink>
                </div>
              )}
            </div>
          </div>
        </div>
      </Container>
      <div className="sponsors footer">
        <Container
          className={cx({
            missingColumn:
              content.involved_institutes?.length === 0 ||
              (!content.project_partner_1 &&
                !content.project_partner_2 &&
                !content.project_partner_3) ||
              (!content.external_funding_provider_1 &&
                !content.external_funding_provider_2 &&
                !content.external_funding_provider_3),
          })}
        >
          {content.involved_institutes &&
            content.involved_institutes.length > 0 && (
              <div className="left">
                <h3>
                  <FormattedMessage
                    id="Institutes involved"
                    defaultMessage="Institutes involved"
                  />
                </h3>
                <div className="involved institutes">
                  {content.involved_institutes &&
                    content.involved_institutes.length > 0 &&
                    content.involved_institutes.map((item) => {
                      const institut = instituteList.find(
                        (o) =>
                          (o['@id'].includes(item.token) &&
                            o['@id'].split('/').includes(intl.locale)) ||
                          o['@id'].includes(item.token),
                      );
                      return (
                        <div className="item">
                          <div className="icon-wrapper">
                            <Icon name={externalLinkSVG} size="14px" />
                          </div>
                          {institut ? (
                            <UniversalLink
                              href={flattenToAppURL(institut['@id'])}
                              alt=""
                            >
                              {item.title}
                            </UniversalLink>
                          ) : (
                            <p>{item.title}</p>
                          )}
                        </div>
                      );
                    })}
                </div>
              </div>
            )}
          {(content.project_partner_1 ||
            content.project_partner_2 ||
            content.project_partner_3 ||
            content.project_partner_4 ||
            content.project_partner_5 ||
            content.project_partner_6 ||
            content.project_partner_7 ||
            content.project_partner_8 ||
            content.project_partner_9 ||
            content.project_partner_10) && (
            <div className="mid">
              <h3>
                <FormattedMessage id="Partners" defaultMessage="Partners" />
              </h3>

              <div className="partners">
                {content.project_partner_1 && (
                  <SponsorItem
                    partner={content.project_partner_1}
                    logo={content.project_partner_1_logo}
                    link={content.project_partner_1_link}
                  />
                )}
                {content.project_partner_2 && (
                  <SponsorItem
                    partner={content.project_partner_2}
                    logo={content.project_partner_2_logo}
                    link={content.project_partner_2_link}
                  />
                )}
                {content.project_partner_3 && (
                  <SponsorItem
                    partner={content.project_partner_3}
                    logo={content.project_partner_3_logo}
                    link={content.project_partner_3_link}
                  />
                )}
                {content.project_partner_4 && (
                  <SponsorItem
                    partner={content.project_partner_4}
                    logo={content.project_partner_4_logo}
                    link={content.project_partner_4_link}
                  />
                )}
                {content.project_partner_5 && (
                  <SponsorItem
                    partner={content.project_partner_5}
                    logo={content.project_partner_5_logo}
                    link={content.project_partner_5_link}
                  />
                )}
                {content.project_partner_6 && (
                  <SponsorItem
                    partner={content.project_partner_6}
                    logo={content.project_partner_6_logo}
                    link={content.project_partner_6_link}
                  />
                )}
                {content.project_partner_7 && (
                  <SponsorItem
                    partner={content.project_partner_7}
                    logo={content.project_partner_7_logo}
                    link={content.project_partner_7_link}
                  />
                )}
                {content.project_partner_8 && (
                  <SponsorItem
                    partner={content.project_partner_8}
                    logo={content.project_partner_8_logo}
                    link={content.project_partner_8_link}
                  />
                )}
                {content.project_partner_9 && (
                  <SponsorItem
                    partner={content.project_partner_9}
                    logo={content.project_partner_9_logo}
                    link={content.project_partner_9_link}
                  />
                )}
                {content.project_partner_10 && (
                  <SponsorItem
                    partner={content.project_partner_10}
                    logo={content.project_partner_10_logo}
                    link={content.project_partner_10_link}
                  />
                )}
              </div>
            </div>
          )}
          {(content.external_funding_provider_1 ||
            content.external_funding_provider_2 ||
            content.external_funding_provider_3) && (
            <div className="right">
              <h3>
                <FormattedMessage id="Funding" defaultMessage="Funding" />
              </h3>

              <div className="partners">
                {content.external_funding_provider_1 && (
                  <SponsorItem
                    partner={content.external_funding_provider_1}
                    logo={content.external_funding_provider_1_logo}
                    link={content.external_funding_provider_1_link}
                  />
                )}
                {content.external_funding_provider_2 && (
                  <SponsorItem
                    partner={content.external_funding_provider_2}
                    logo={content.external_funding_provider_2_logo}
                    link={content.external_funding_provider_2_link}
                  />
                )}
                {content.external_funding_provider_3 && (
                  <SponsorItem
                    partner={content.external_funding_provider_3}
                    logo={content.external_funding_provider_3_logo}
                    link={content.external_funding_provider_3_link}
                  />
                )}
              </div>
            </div>
          )}
        </Container>
      </div>
    </div>
  );
};

export default ProjectView;
