const STORAGE_KEY = 'cart';

export const rawLoad = () =>
  sessionStorage.getItem(STORAGE_KEY) ||
  sessionStorage.getItem(STORAGE_KEY) ||
  '[]';

export const onLoad = () => {
  const json = rawLoad();
  try {
    return JSON.parse(json);
  } catch (e) {
    // eslint-disable-next-line
    console.error(
      `Failed to load from storage, key=${STORAGE_KEY}, value=${json}`,
    );
  }
};

export const onDump = (items) => {
  const json = JSON.stringify(items);
  localStorage.setItem(STORAGE_KEY, json);
  sessionStorage.setItem(STORAGE_KEY, json);
};
