import React from 'react';
// import React, {useState} from 'react';
import { Grid, Image } from 'semantic-ui-react';
import { Icon } from '@plone/volto/components';
import UniversalLink from '@plone/volto/components/manage/UniversalLink/UniversalLink';

import {
  useIntl,
  defineMessages,
  injectIntl,
  FormattedMessage,
} from 'react-intl';
import TextBlockView from 'volto-slate/blocks/Text/TextBlockView.jsx';
import { flattenToAppURL } from '@plone/volto/helpers';
import { MailTo } from '@package/helpers';
// import { ContactForm } from '@package/components';

import phoneSVG from '../../../icons/phone.svg';
import avatarPlaceholderSVG from '../../../icons/fallback-avatar.svg';
import mapSVG from '../../../icons/map.svg';
import emailSVG from '@plone/volto/icons/email.svg';

const messages = defineMessages({
  room: {
    id: 'Room',
    defaultMessage: 'Room',
  },
  building: {
    id: 'Building',
    defaultMessage: 'Building',
  },
  pleaseEnter: {
    id: 'Please enter',
    defaultMessage: 'Please enter',
  },
});

const View = (props) => {
  const { data } = props;
  // const [showContactForm, setShowContactForm] = useState(false);
  const intl = useIntl();

  let academic = data.academic ? data.academic + ' ' : '';
  let name =
    data.title || data.firstname
      ? academic +
        (data.firstname && data.name
          ? data.firstname + ' ' + data.name
          : data.title)
      : undefined;

  let building = data.building
    ? data?.building?.includes('building' || 'Building' || 'Gebäude')
      ? data.building
      : intl.formatMessage(messages.building) + ' ' + data.building
    : null;

  let room = data.room
    ? data?.room?.includes('Room' || 'room' || 'Raum')
      ? data.room
      : intl.formatMessage(messages.room) + ' ' + data.room
    : null;

  return (
    <>
      {data && (
        <>
          {/* {showContactForm && (
            <ContactForm
              {...props}
              onClose={() => setShowContactForm(false)}
              memberURL={flattenToAppURL(data.href?.[0]['@id'])}
            />
          )} */}
          <div className="block introduction">
            <div className="wrapper">
              <div className="headline-wrapper">
                {data?.heading && (
                  <h2 className="blockFirstHeading">{data?.heading}</h2>
                )}
              </div>
              <Grid stackable>
                <Grid.Column width={8} className="left">
                  <h3 className="bordered">
                    <FormattedMessage id="about" defaultMessage="About" />
                  </h3>
                  <div className="text-body">
                    {data.about && (
                      <TextBlockView {...props} data={data.about} />
                    )}
                  </div>
                  <div className="topics">
                    <h3 className="bordered">
                      <FormattedMessage
                        id="research topics"
                        defaultMessage="Research Topics"
                      />
                    </h3>
                    {data.topics && (
                      <TextBlockView {...props} data={data.topics} />
                    )}
                  </div>
                </Grid.Column>
                <Grid.Column width={4}>
                  <h3 className="bordered">
                    <FormattedMessage id="contact" defaultMessage="Contact" />
                  </h3>
                  {data.href &&
                    data.href.length > 0 &&
                    (data.image ? (
                      <div className="image-wrapper">
                        <UniversalLink
                          href={data?.href?.[0]?.['@id']}
                          title={data?.firstname + ' ' + data?.name}
                          tabIndex={-1}
                        >
                          <Image
                            src={`${flattenToAppURL(
                              data?.href?.[0]?.['@id'],
                            )}/@@images/image/preview`}
                            className="leader image"
                            alt=""
                          />
                        </UniversalLink>
                      </div>
                    ) : (
                      <div className="image-wrapper">
                        <UniversalLink href={data?.href?.[0]?.['@id']}>
                          <Icon
                            name={avatarPlaceholderSVG}
                            size="220px"
                            className="placeholder"
                          />
                        </UniversalLink>
                      </div>
                    ))}
                  {data.href && data.href.length > 0 && (
                    <div className="leader details">
                      {name && (
                        <UniversalLink href={data?.href?.[0]?.['@id']}>
                          <p className="name">{name}</p>
                        </UniversalLink>
                      )}
                      {data.institute && (
                        <p className="institute">{data.institute}</p>
                      )}
                      <div className="additionals">
                        {(building || room) && (
                          <div className="building">
                            <div className="icon-wrapper building">
                              <Icon
                                name={mapSVG}
                                size="24px"
                                color={
                                  data.bg_color === '#023d6b'
                                    ? '#fff'
                                    : '#023d6b'
                                }
                              ></Icon>
                            </div>
                            <p className="building">
                              {building} {room ? '/' : ''} {room}
                            </p>
                          </div>
                        )}
                        {(data.phone || data.mobile) && (
                          <div className="phone">
                            <div className="icon-wrapper phone">
                              <Icon
                                name={phoneSVG}
                                size="20px"
                                color={
                                  data.bg_color === '#023d6b'
                                    ? '#fff'
                                    : '#023d6b'
                                }
                              ></Icon>
                            </div>
                            <p className="phone">
                              {data.phone ? data.phone : data.mobile}
                            </p>
                          </div>
                        )}
                        {data.email && (
                          <div className="mail">
                            <div className="icon-wrapper mail">
                              <Icon
                                name={emailSVG}
                                color={
                                  data.bg_color === '#023d6b'
                                    ? '#fff'
                                    : '#023d6b'
                                }
                                size="24px"
                              ></Icon>
                            </div>
                            <MailTo email={data.email} className="mail" />
                            {/* <span
                              onClick={() => setShowContactForm(true)}
                              onKeyDown={() => {
                                setShowContactForm(true);
                              }}
                              className="mail"
                              role="button"
                              tabIndex={0}
                              style={{
                                color: data.bg_color === '#023d6b' && '#fff',
                              }}
                            >
                              E-Mail
                            </span> */}
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </Grid.Column>
              </Grid>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default injectIntl(View);
