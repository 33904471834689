import React from 'react';
import MemberListView from '../MembersList/View';

const PersonsListingsAdapter = ({ items, isEditMode, variation }) => {
  return (
    <MemberListView
      variation={{ id: variation }}
      data={{ columns: items, '@type': 'listing' }}
      isEditMode={isEditMode}
    />
  );
};

export default PersonsListingsAdapter;
