import React from 'react';
import { ConditionalLink } from '@plone/volto/components';
import { format, parse } from 'date-fns';
import { useSelector } from 'react-redux';

const AktuellesListing = ({ items, isEditMode }) => {
  var deLocale = require('date-fns/locale/de');
  var enLocale = require('date-fns/locale/en');
  const language = useSelector((state) => state.intl.locale);

  return (
    <div className="aktuelleslisting">
      {items.map((item) => (
        <div className="listing-item" key={item['@id']}>
          <ConditionalLink item={item} condition={!isEditMode}>
            <div className="content">
              {item.head_title && <h2>{item.head_title}</h2>}
              <h3>
                {item.title ? item.title : item.id}&nbsp;
                {item?.start ? (
                  <span className="day">
                    (
                    {language === 'de'
                      ? format(parse(item?.start), 'DD.MM.YYYY', {
                          locale: deLocale,
                        })
                      : format(parse(item?.start), 'DD/MM/YYYY', {
                          locale: enLocale,
                        })}
                    )
                  </span>
                ) : item?.effective ? (
                  <span className="day">
                    (
                    {language === 'de'
                      ? format(parse(item?.effective), 'DD.MM.YYYY', {
                          locale: deLocale,
                        })
                      : format(parse(item?.effective), 'DD/MM/YYYY', {
                          locale: enLocale,
                        })}
                    )
                  </span>
                ) : (
                  <span className="day"></span>
                )}
              </h3>
            </div>
          </ConditionalLink>
        </div>
      ))}
    </div>
  );
};

export default AktuellesListing;
