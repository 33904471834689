import { defineMessages } from 'react-intl';

const messages = defineMessages({
  Source: {
    id: 'Source',
    defaultMessage: 'Source',
  },
  TeaserTab: {
    id: 'Teaser Tab',
    defaultMessage: 'Teaser Tab',
  },
  openLinkInNewTab: {
    id: 'Open in a new tab',
    defaultMessage: 'Open in a new tab',
  },
  title: {
    id: 'Title',
    defaultMessage: 'Title',
  },
  description: {
    id: 'Description',
    defaultMessage: 'Description',
  },
  item: {
    id: 'Item',
    defaultMessage: 'Item',
  },
  items: {
    id: 'Items',
    defaultMessage: 'Items',
  },
  addItem: {
    id: 'Add item',
    defaultMessage: 'Add item',
  },
  imageOverride: {
    id: 'Image override',
    defaultMessage: 'Image override',
  },
  settings: {
    id: 'Settings',
    defaultMessage: 'Settings',
  },
  headline: {
    id: 'Headline',
    defaultMessage: 'Headline',
  },
});

const itemSchema = (props) => {
  const { intl } = props;

  return {
    title: intl.formatMessage(messages.item),
    addMessage: intl.formatMessage(messages.addItem),
    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: [
          'href',
          'title',
          'description',
          'preview_image',
          'openLinkInNewTab',
        ],
      },
    ],

    properties: {
      href: {
        title: intl.formatMessage(messages.Source),
        widget: 'object_browser',
        mode: 'link',
        selectedItemAttrs: [
          'Title',
          'Description',
          'hasPreviewImage',
          'image',
          'institutes',
          'mobile',
          'room',
          'building',
          'phone',
          'email',
          'name',
          'firstname',
        ],
        allowExternals: true,
      },
      title: {
        title: intl.formatMessage(messages.title),
      },
      description: {
        title: intl.formatMessage(messages.description),
      },
      preview_image: {
        title: intl.formatMessage(messages.imageOverride),
        widget: 'object_browser',
        mode: 'image',
        allowExternals: true,
      },
      openLinkInNewTab: {
        title: intl.formatMessage(messages.openLinkInNewTab),
        type: 'boolean',
      },
    },
    required: [],
  };
};
export const TeaserTabSchema = (props) => ({
  title: props.intl.formatMessage(messages.TeaserTab),
  block: 'teaserTab',
  fieldsets: [
    {
      id: 'default',
      title: 'Default',
      fields: ['columns'],
    },
    {
      id: 'settings',
      title: props.intl.formatMessage(messages.settings),
      fields: ['headline'],
    },
  ],
  properties: {
    columns: {
      widget: 'object_list',
      title: props.intl.formatMessage(messages.items),
      schema: itemSchema,
    },
    headline: {
      title: props.intl.formatMessage(messages.headline),
    },
  },
  required: [],
});
