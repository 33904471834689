import { POST_CONTACT_EMAIL } from '@package/constants/ActionTypes';

export default function postContactForm(url, email, subject, message) {
  return {
    type: POST_CONTACT_EMAIL,
    request: {
      op: 'post',
      path: `${url}/@contact-form`,
      data: {
        email: email,
        subject: subject,
        message: message,
      },
    },
  };
}
