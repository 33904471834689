/**
 * Navigation reducer.
 * @module reducers/navigation/navigation
 */

import { GET_INSTITUT_LIST } from '@package/constants/ActionTypes';

const initialState = {
  error: null,
  institutlist: [],
  loaded: false,
  loading: false,
};

export default function institutlist(state = initialState, action = {}) {
  switch (action.type) {
    case `${GET_INSTITUT_LIST}_PENDING`:
      return {
        ...state,
        error: null,
        loaded: false,
        loading: true,
      };
    case `${GET_INSTITUT_LIST}_SUCCESS`:
      return {
        ...state,
        error: null,
        loaded: true,
        loading: false,
      };
    case `${GET_INSTITUT_LIST}_FAIL`:
      return {
        ...state,
        error: action.error,
        loaded: false,
        loading: false,
      };
    default:
      return state;
  }
}
