import config from '@plone/volto/registry';

export const JOB_OFFER_SEARCH_CONTENT = 'JOB_OFFER_SEARCH_CONTENT';
export const RESET_JOB_OFFER_SEARCH_CONTENT = 'RESET_JOB_OFFER_SEARCH_CONTENT';

/**
 * Search content function.
 * @function solrSearchContent
 * @param {string} url Url to use as base.
 * @param {Object} options Search options.
 * @param {string} subrequest Key of the subrequest.
 * @returns {Object} Search content action.
 */
export function jobOfferSearchContent(url, options) {
  const { settings } = config;
  options = options || {};

  let queryArray = [
    // q is inserted in all cases
    `q=${options.searchText !== undefined ? options.searchText : ''}`,
    // Default batch size is injected here
    `rows=${
      options.bSize !== undefined ? options.bSize : settings.defaultPageSize
    }`,
    options.bStart !== undefined ? `start=${options.bStart}` : '',
    options.sortOn !== undefined
      ? 'sort=' +
        encodeURIComponent(
          `${options.sortOn} ${
            options.sortOrder === 'descending' ? 'desc' : 'asc'
          }`,
        )
      : '',
    options.pathPrefix !== undefined ? `path_prefix=${options.pathPrefix}` : '',
  ]
    .concat(
      (options.searchTargetGroup || []).map(
        (searchTargetGroup) => `target_group=${searchTargetGroup}`,
      ),
    )
    .concat(
      (options.searchFieldOfWork || []).map(
        (searchFieldOfWork) => `field_of_work=${searchFieldOfWork}`,
      ),
    )
    .concat(
      (options.searchLocation || []).map(
        (searchLocation) => `location=${searchLocation}`,
      ),
    )
    .concat(
      (options.searchSubject || []).map(
        (searchSubject) => `subject=${searchSubject}`,
      ),
    );

  const queryString = queryArray.filter((item) => item).join('&');

  return {
    type: JOB_OFFER_SEARCH_CONTENT,
    request: {
      op: 'get',
      path: `${url}/@job-offer-search${queryString ? `?${queryString}` : ''}`,
    },
  };
}

/**
 * Reset search content function.
 * @function resetSolrSearchContent
 * @param {string} subrequest Key of the subrequest.
 * @returns {Object} Search content action.
 */
export function resetJobOfferSearchContent() {
  return {
    type: RESET_JOB_OFFER_SEARCH_CONTENT,
  };
}
