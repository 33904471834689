import React from 'react';
import { useCookies } from 'react-cookie';

const ETrackerScript = () => {
  const [cookies] = useCookies();
  const confirmTracking = !!Number(cookies.confirm_etracker);

  return (
    <script
      id="_etLoader"
      type="text/javascript"
      charset="UTF-8"
      data-block-cookies={confirmTracking}
      data-secure-code="5zEpKg"
      src="//code.etracker.com/code/e.js"
      async
    />
  );
};

export default ETrackerScript;
