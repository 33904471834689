import React, { useEffect, useState } from 'react';
import { Container, Grid, Accordion } from 'semantic-ui-react';
import { useDispatch, useSelector } from 'react-redux';
import AnimateHeight from 'react-animate-height';
import {
  useIntl,
  defineMessages,
  injectIntl,
  FormattedMessage,
} from 'react-intl';
import { getQueryStringResults } from '@plone/volto/actions';
import { Icon } from '@plone/volto/components';
import UniversalLink from '@plone/volto/components/manage/UniversalLink/UniversalLink';
import { flattenToAppURL } from '@plone/volto/helpers';
import { MailTo } from '@package/helpers';
import { AwardOverlay } from '@package/components';
// import { ContactForm, AwardOverlay } from '@package/components';

import PhoneSVG from '@package/icons/phone.svg';
import MailSVG from '@package/icons/mail-blue.svg';
import FaxSVG from '@package/icons/printer.svg';
import WorldSVG from '@package/icons/world.svg';
import FingerPrintSVG from '@package/icons/finger-print.svg';
import LocationSVG from '@package/icons/location.svg';
import DownKeySVG from '@plone/volto/icons/down-key.svg';
import personDummyPNG from '@package/icons/person-dummy.png';

const messages = defineMessages({
  field_of_science: {
    id: 'Research Topics',
    defaultMessage: 'Research Topics',
  },
  field_of_competence: {
    id: 'Areas of expertise',
    defaultMessage: 'Areas of expertise',
  },
  room: {
    id: 'Room',
    defaultMessage: 'Room',
  },
  building: {
    id: 'Building',
    defaultMessage: 'Building',
  },
  awards: {
    id: 'Awards',
    defaultMessage: 'Awards',
  },
  team: {
    id: 'Team',
    defaultMessage: 'Team',
  },
  projects: {
    id: 'Projects',
    defaultMessage: 'Projects',
  },
  news: {
    id: 'News',
    defaultMessage: 'News',
  },
  vita: {
    id: 'Vita',
    defaultMessage: 'Vita',
  },
  publications: {
    id: 'Publications',
    defaultMessage: 'Publications',
  },
  pleaseEnter: {
    id: 'Please enter',
    defaultMessage: 'Please enter',
  },
});

const sortOrder = {
  Award: {
    sort_on: 'award_date_year',
    sort_order: 'descending',
  },
  Meldung: {
    sort_on: 'effective',
    sort_order: 'descending',
  },
  Project: {
    sort_on: 'project_start_year',
    sort_order: 'descending',
  },
};

const RelatedItemsAccordion = ({
  member,
  type,
  path,
  title,
  onClick,
  hideDescription,
}) => {
  const dispatch = useDispatch();
  const [active, setActive] = useState(false);
  const [overlay, setOverlay] = useState(null);

  const querystringResults = useSelector(
    (state) => state.querystringsearch.subrequests,
  );
  useEffect(() => {
    dispatch(
      getQueryStringResults(
        path,
        {
          b_size: 25,
          query: [
            {
              i: 'portal_type',
              o: 'plone.app.querystring.operation.selection.any',
              v: [type],
            },
            {
              i: 'members',
              o: 'plone.app.querystring.operation.largeselection.any',
              v: [member],
            },
          ],
          ...sortOrder[type],
          ...(type === 'Award'
            ? { fullobjects: 1 }
            : { metadata_fields: '_all' }),
        },
        title,
      ),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const items = querystringResults[title]?.items;

  return (
    <>
      {items?.length > 0 ? (
        <>
          <Accordion fluid>
            <Accordion.Title active={active} onClick={() => setActive(!active)}>
              {title}
              <Icon name={DownKeySVG} color={active ? '#adbde3' : '#023d6b'} />
            </Accordion.Title>
            <AnimateHeight
              animateOpacity
              duration={500}
              height={active ? 'auto' : 0}
            >
              <Accordion.Content active={active}>
                {type === 'Award'
                  ? items.map((item) => (
                      <>
                        {overlay === item['@id'] && (
                          <AwardOverlay
                            award={item}
                            onClose={() => setOverlay(null)}
                          />
                        )}
                        <div>
                          <p>
                            <span
                              onClick={() => setOverlay(item['@id'])}
                              role="button"
                              className="award"
                              onKeyDown={() => setOverlay(item['@id'])}
                              tabIndex={0}
                            >
                              {item.title}
                            </span>
                          </p>
                          {!hideDescription && <p>{item.description}</p>}
                        </div>
                      </>
                    ))
                  : items.map((item) => (
                      <div>
                        <p>
                          <UniversalLink href={flattenToAppURL(item['@id'])}>
                            {item.title}
                          </UniversalLink>
                        </p>
                        {!hideDescription && <p>{item.description}</p>}
                      </div>
                    ))}
              </Accordion.Content>
            </AnimateHeight>
          </Accordion>
          <div className="seperator-line"></div>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

const RichTextAccordion = ({ text, title, onClick }) => {
  const [active, setActive] = useState(false);
  return (
    <>
      <Accordion fluid>
        <Accordion.Title active={active} onClick={() => setActive(!active)}>
          {title}
          <Icon name={DownKeySVG} color={active ? '#adbde3' : '#023d6b'} />
        </Accordion.Title>
        <AnimateHeight
          animateOpacity
          duration={500}
          height={active ? 'auto' : 0}
        >
          <Accordion.Content active={active}>
            <div dangerouslySetInnerHTML={{ __html: text.data }} />
          </Accordion.Content>
        </AnimateHeight>
      </Accordion>
      <div className="seperator-line"></div>
    </>
  );
};

const MemberView = (props) => {
  const intl = useIntl();
  const { content, location } = props;
  const [activeAccordion, setActiveAccordion] = useState(0);

  return (
    <>
      {/* {showContactForm && (
        <ContactForm {...props} onClose={() => setShowContactForm(false)} />
      )} */}
      <div id="page-document">
        <div className="top-section">
          <Container>
            <Grid>
              <Grid.Row className="title" columns={2}>
                <Grid.Column tablet={4} computer={4} mobile={12}>
                  {content.image ? (
                    <img
                      src={flattenToAppURL(
                        content.image.scales.teaser.download,
                      )}
                      alt={content.title}
                    />
                  ) : (
                    <img src={personDummyPNG} alt={content.title} />
                  )}
                </Grid.Column>
                <Grid.Column tablet={8} computer={8} mobile={12}>
                  <h1 className="documentFirstHeading">{`${
                    content.academic ? content.academic : ''
                  } ${content.firstname ? content.firstname : ''} ${
                    content.name ? content.name : ''
                  }`}</h1>
                  {content.positions &&
                    content.positions
                      .split('\n')
                      .map((term) => <p className="function">{term}</p>)}
                </Grid.Column>
              </Grid.Row>
              <Grid.Row className="basic-infos" columns={3}>
                <Grid.Column tablet={4} computer={4} mobile={12}>
                  {content.fields_of_competence.length > 0 && (
                    <>
                      {content.competence_type ? (
                        <h2>
                          {content.competence_type?.token === 'science'
                            ? intl.formatMessage(messages.field_of_science)
                            : intl.formatMessage(messages.field_of_competence)}
                        </h2>
                      ) : (
                        <div className="noH2">
                          <br />
                        </div>
                      )}
                      <div>
                        {content.fields_of_competence.map((term, index) =>
                          index < content.fields_of_competence.length - 1
                            ? `${term}, `
                            : term,
                        )}
                      </div>
                    </>
                  )}
                </Grid.Column>
                <Grid.Column tablet={4} computer={4} mobile={12}>
                  <h2>
                    <FormattedMessage id="Contact" defaultMessage="Contact" />
                  </h2>
                  {content.phone && (
                    <p>
                      <Icon
                        className="phone"
                        color="#023d6b"
                        size="18px"
                        name={PhoneSVG}
                      />
                      {content.phone}
                    </p>
                  )}
                  {content.fax && (
                    <p>
                      <Icon
                        className="fax"
                        color="#023d6b"
                        size="18px"
                        name={FaxSVG}
                      />
                      {content.fax}
                    </p>
                  )}
                  {content.email && (
                    <p>
                      <Icon
                        className="mail"
                        color="#023d6b"
                        size="16px"
                        name={MailSVG}
                      />
                      <MailTo email={content.email} className="mail" />
                    </p>
                  )}
                  {content.website && (
                    <p>
                      <Icon
                        className="web"
                        color="#023d6b"
                        size="18px"
                        name={WorldSVG}
                      />
                      <UniversalLink
                        href={content.website}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {content.website}
                      </UniversalLink>
                    </p>
                  )}
                  {content.research_url_1 && (
                    <p>
                      <Icon
                        className="research-id"
                        color="#023d6b"
                        size="18px"
                        name={FingerPrintSVG}
                      />
                      <UniversalLink href={content.research_url_1}>
                        {content.research_url_label_1 || content.research_url_1}
                      </UniversalLink>
                    </p>
                  )}
                  {content.research_url_2 && (
                    <p>
                      <Icon
                        className="research-id"
                        color="#023d6b"
                        size="18px"
                        name={FingerPrintSVG}
                      />
                      <UniversalLink href={content.research_url_2}>
                        {content.research_url_label_2 || content.research_url_2}
                      </UniversalLink>
                    </p>
                  )}
                  {content.research_url_3 && (
                    <p>
                      <Icon
                        className="research-id"
                        color="#023d6b"
                        size="18px"
                        name={FingerPrintSVG}
                      />
                      <UniversalLink href={content.research_url_3}>
                        {content.research_url_label_3 || content.research_url_3}
                      </UniversalLink>
                    </p>
                  )}
                  {content.research_url_4 && (
                    <p>
                      <Icon
                        className="research-id"
                        color="#023d6b"
                        size="18px"
                        name={FingerPrintSVG}
                      />
                      <UniversalLink href={content.research_url_4}>
                        {content.research_url_label_4 || content.research_url_4}
                      </UniversalLink>
                    </p>
                  )}
                  {content.research_url_5 && (
                    <p>
                      <Icon
                        className="research-id"
                        color="#023d6b"
                        size="18px"
                        name={FingerPrintSVG}
                      />
                      <UniversalLink href={content.research_url_5}>
                        {content.research_url_label_5 || content.research_url_5}
                      </UniversalLink>
                    </p>
                  )}
                </Grid.Column>
                <Grid.Column tablet={4} computer={4} mobile={12}>
                  <h2>
                    <FormattedMessage id="Address" defaultMessage="Address" />
                  </h2>
                  <p>
                    {content.address ? (
                      <>
                        {content.address.split(/\n|,/g).map((address_line) => (
                          <>
                            {address_line} <br />
                          </>
                        ))}
                      </>
                    ) : (
                      <>
                        <b>Forschungszentrum Jülich GmbH</b>
                        <br />
                        Wilhelm-Johnen-Straße
                        <br />
                        52428 Jülich
                      </>
                    )}
                  </p>
                  {content.administrational_units &&
                    content.administrational_units.map((unit) => (
                      <p>{unit.title}</p>
                    ))}
                  {content.institutes &&
                    content.institutes.map((institute) => (
                      <p>{institute.title}</p>
                    ))}
                  {content.institutsbereiche &&
                    content.institutsbereiche.map((institutsbereich) => (
                      <p>{institutsbereich.title}</p>
                    ))}
                  <p>
                    {content.building &&
                      content.room &&
                      `${intl.formatMessage(messages.building)} ${
                        content.building
                      } / ${intl.formatMessage(messages.room)} ${content.room}`}
                    <br />
                  </p>
                  {content.location_link && (
                    <>
                      <Icon
                        className="location"
                        color="#023d6b"
                        size="18px"
                        name={LocationSVG}
                      />
                      <UniversalLink href={content.location_link}>
                        <FormattedMessage
                          id="You can find us here"
                          defaultMessage="You can find us here"
                        />
                      </UniversalLink>
                    </>
                  )}
                </Grid.Column>
              </Grid.Row>
            </Grid>
            <div className="seperator-line"></div>
            {content.about_me && content.about_me_shown && (
              <div className="about-me">
                <h2>
                  {content.about_me_headline || (
                    <FormattedMessage id="About me" defaultMessage="About me" />
                  )}
                </h2>
                <div
                  className="about-me-inner"
                  dangerouslySetInnerHTML={{ __html: content.about_me.data }}
                />
                <div className="text-bubble-bottom"></div>
              </div>
            )}
          </Container>
        </div>
        <div className="bottom-section">
          <Container>
            {content.vita_shown && (
              <RichTextAccordion
                title={intl.formatMessage(messages.vita)}
                text={content.vita ?? '<p/>'}
                onClick={() => setActiveAccordion(1)}
              />
            )}
            {content.publications_shown && (
              <RichTextAccordion
                title={intl.formatMessage(messages.publications)}
                text={content.publications ?? '<p/>'}
                onClick={() => setActiveAccordion(1)}
              />
            )}
            {content.awards_shown && (
              <RelatedItemsAccordion
                member={content.id}
                path={location.pathname}
                active={activeAccordion === 1}
                type="Award"
                title={intl.formatMessage(messages.awards)}
                onClick={() => setActiveAccordion(2)}
                hideDescription
              />
            )}
            {content.projects_shown && (
              <RelatedItemsAccordion
                member={content.id}
                path={location.pathname}
                active={activeAccordion === 1}
                type="Project"
                title={intl.formatMessage(messages.projects)}
                onClick={() => setActiveAccordion(3)}
              />
            )}
            {/* <RelatedItemsAccordion
              member={content.id}
              path={location.pathname}
              active={activeAccordion === 1}
              type="Member"
              title={intl.formatMessage(messages.team)}
              onClick={() => setActiveAccordion(4)}
            /> */}
            {content.news_shown && (
              <RelatedItemsAccordion
                member={content.id}
                path={location.pathname}
                active={activeAccordion === 1}
                type="Meldung"
                title={intl.formatMessage(messages.news)}
                onClick={() => setActiveAccordion(5)}
              />
            )}
          </Container>
        </div>
      </div>
    </>
  );
};

export default injectIntl(MemberView);
