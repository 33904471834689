import React, { useState, useEffect } from 'react';
import { Button } from 'semantic-ui-react';
import {
  useIntl,
  defineMessages,
  injectIntl,
  FormattedMessage,
} from 'react-intl';
import { useDispatch } from 'react-redux';
import cx from 'classnames';

import { postContactForm } from '@package/actions';
import { Icon } from '@plone/volto/components';
import { flattenToAppURL } from '@plone/volto/helpers';

import successSVG from '@package/icons/check-circle.svg';

const messages = defineMessages({
  publications: {
    id: 'Publications',
    defaultMessage: 'Publications',
  },
  pleaseEnter: {
    id: 'Please enter',
    defaultMessage: 'Please enter',
  },
});

const ContactForm = (props) => {
  const {
    content,
    data,
    onClose,
    hideSubjectField,
    memberURL,
    hideImage,
    subject,
  } = props;
  const contactData = content || data;
  const image_field = props?.image_field || contactData?.image_field;

  const url = contactData?.['@id'] || memberURL;
  const intl = useIntl();
  const dispatch = useDispatch();

  const [filledOut, setFilledOut] = useState(false);
  const [submit, setSubmit] = useState(false);
  const [errorLength, setErrorLength] = useState(false);
  const [errorEmail, setErrorEmail] = useState(false);
  const [contactEmail, setContactEmail] = useState('');
  const [contactSubject, setContactSubject] = useState('');
  const [contactMessage, setContactMessage] = useState('');
  // Honeypot (not visible for users)
  const [contactName, setcontactName] = useState('');

  const onSubmit = () => {
    setSubmit(true);
    if (validateEmail(contactEmail) && contactMessage.length > 0) {
      if (contactName.length > 0) {
        setFilledOut(true);
        setSubmit(false);
      } else {
        setErrorEmail(false);
        setErrorLength(false);
        // send in contact form
        dispatch(
          postContactForm(
            flattenToAppURL(url),
            contactEmail,
            subject || contactSubject,
            contactMessage,
          ),
        ).then((resp) => {
          setFilledOut(true);
          setSubmit(false);
        });
      }
    } else {
      setSubmit(false);
      if (!validateEmail(contactEmail)) {
        setErrorEmail(true);
      } else {
        setErrorEmail(false);
      }
      if (contactMessage.length <= 0) {
        setErrorLength(true);
      } else {
        setErrorLength(false);
      }
    }
  };

  const validateEmail = (email) => {
    //eslint-disable-next-line
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const error = re.test(String(email).toLowerCase());
    return error;
  };

  const clearEntries = () => {
    setContactSubject('');
    setContactEmail('');
    setContactMessage('');
  };

  const disableScroll = () => {
    // Get the current page scroll position
    let scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    let scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
    // if any scroll is attempted, set this to the previous value
    window.onscroll = function () {
      window.scrollTo(scrollLeft, scrollTop);
    };
  };

  const enableScroll = () => {
    window.onscroll = function () {};
  };

  useEffect(() => {
    disableScroll();
  }, []);

  return (
    <div className="overlay-wrapper contactform">
      <div className="layer">
        <span
          onClick={() => {
            onClose();
            enableScroll();
            filledOut && clearEntries();
            filledOut && setFilledOut(false);
          }}
          onKeyDown={() => {
            onClose();
            enableScroll();
            filledOut && clearEntries();
            filledOut && setFilledOut(false);
          }}
          className="exit overlay"
          role="button"
          tabIndex={0}
        >
          &#x2715;
        </span>
        <div className="contact">
          <div className="image-wrapper contactoverlay">
            {!hideImage &&
              (contactData?.image && contactData?.image?.scales ? (
                <img
                  src={flattenToAppURL(
                    contactData.image.scales.teaser.download,
                  )}
                  alt=""
                />
              ) : image_field ? (
                <img
                  src={`${flattenToAppURL(
                    url,
                  )}/@@images/${image_field}/preview`}
                  alt=""
                />
              ) : null)}
          </div>
          <div className="inner">
            {contactData?.firstname ? (
              <p className="big">
                <FormattedMessage id="Message to" defaultMessage="Message to" />{' '}
                {contactData?.firstname} {contactData?.name}
              </p>
            ) : (
              <p className="big">
                <FormattedMessage
                  id="Contact form"
                  defaultMessage="Contact form"
                />
              </p>
            )}
            <p className="extras">
              {contactData?.positions &&
                contactData.positions
                  .split('\n')
                  .slice(0, 2)
                  .map((term) => <p>{term}</p>)}
            </p>
          </div>
        </div>
        <div className="overlay">
          <div className="inner">
            {!filledOut ? (
              <form>
                <div className="mail">
                  <p>
                    <FormattedMessage
                      id="My E-Mail address"
                      defaultMessage="My E-Mail address"
                    />
                    *:
                  </p>
                  {errorEmail && (
                    <p className="right error">
                      <FormattedMessage
                        id="Required input is missing"
                        defaultMessage="Required input is missing"
                      />
                    </p>
                  )}
                </div>
                <input
                  placeholder={intl.formatMessage(messages.pleaseEnter)}
                  value={contactEmail}
                  onChange={(e) => {
                    setContactEmail(e.target.value);
                    setErrorEmail(false);
                  }}
                  className={cx({ missing: errorEmail })}
                />
                {!hideSubjectField && (
                  <>
                    <div className="subject">
                      <p>
                        <FormattedMessage
                          id="Subject"
                          defaultMessage="Subject"
                        />
                        :
                      </p>
                    </div>
                    <input
                      placeholder={intl.formatMessage(messages.pleaseEnter)}
                      value={contactSubject}
                      onChange={(e) => setContactSubject(e.target.value)}
                    />
                  </>
                )}
                <div className="message">
                  <p>
                    <FormattedMessage
                      id="My message"
                      defaultMessage="My message"
                    />
                    *:
                  </p>
                  {errorLength && (
                    <p className="right error">
                      <FormattedMessage
                        id="Please enter a message"
                        defaultMessage="Please enter a message"
                      />
                    </p>
                  )}
                </div>
                <textarea
                  placeholder={intl.formatMessage(messages.pleaseEnter)}
                  value={contactMessage}
                  onChange={(e) => {
                    setContactMessage(e.target.value);
                    setErrorLength(false);
                  }}
                  className={cx({ missing: errorLength })}
                ></textarea>
                <input
                  placeholder={intl.formatMessage(messages.pleaseEnter)}
                  className="name"
                  value={contactName}
                  onChange={(e) => setcontactName(e.target.value)}
                />
                <p className="right">
                  *
                  <FormattedMessage id="Mandatory" defaultMessage="Mandatory" />
                </p>
              </form>
            ) : (
              <div className="success">
                <div className="icon-wrapper">
                  <Icon name={successSVG} size="73px" color="#ffffff" />
                </div>
                <p>
                  <FormattedMessage
                    id="Your message has been successfully submitted"
                    defaultMessage="Your message has been successfully submitted"
                  />
                  .
                </p>
              </div>
            )}
            {!filledOut ? (
              <div className="button-wrapper">
                <Button
                  id="send"
                  className="send"
                  onClick={() => {
                    onSubmit();
                  }}
                  loading={submit}
                >
                  <FormattedMessage id="Submit" defaultMessage="Submit" />
                </Button>
              </div>
            ) : (
              <div className="button-wrapper">
                <Button
                  id="close"
                  className="close"
                  onClick={() => {
                    onClose();
                    setFilledOut(false);
                    clearEntries();
                    enableScroll();
                  }}
                >
                  <FormattedMessage
                    id="Close window"
                    defaultMessage="Close window"
                  />
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default injectIntl(ContactForm);
