import React from 'react';
import PropTypes from 'prop-types';
import { ConditionalLink } from '@plone/volto/components';
import { flattenToAppURL } from '@plone/volto/helpers';
import { Grid } from 'semantic-ui-react';
import dummyImage from '@package/icons/dummy-image.png';

const GridListingTwoColumns = ({ items, linkMore, isEditMode }) => {
  return (
    <>
      <div className="grid-listing-twocolumns">
        <div className="items">
          <Grid>
            <Grid.Row columns={12}>
              {items.map((item) => (
                <Grid.Column
                  computer={6}
                  tablet={6}
                  width={12}
                  className="container-column"
                >
                  <div key={item.UID} className="event-item">
                    <div className="content">
                      <div className="listing-item" key={item['@id']}>
                        <div className="text">
                          <ConditionalLink
                            to={flattenToAppURL(item['@id'])}
                            condition={!isEditMode}
                          >
                            <div className="grid-image-wrapper">
                              <img
                                src={
                                  item.image_field
                                    ? flattenToAppURL(
                                        `${item['@id']}/@@images/${item.image_field}/preview`,
                                      )
                                    : dummyImage
                                }
                                alt={item.title}
                              />
                            </div>
                            <div className="listing-body">
                              <h2>{item.title ? item.title : item.id}</h2>
                              <p className="description">{item?.description}</p>
                            </div>
                          </ConditionalLink>
                        </div>
                      </div>
                    </div>
                  </div>
                </Grid.Column>
              ))}
            </Grid.Row>
          </Grid>
        </div>
      </div>
    </>
  );
};
GridListingTwoColumns.propTypes = {
  items: PropTypes.arrayOf(PropTypes.any).isRequired,
  linkMore: PropTypes.any,
  isEditMode: PropTypes.bool,
};
export default GridListingTwoColumns;
