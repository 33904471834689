import { Container } from 'semantic-ui-react';
import { useIntl, defineMessages } from 'react-intl';

const messages = defineMessages({
  tenderRssFailure: {
    // Fehler beim RSS-Feed.
    id: 'tenderRssFailure',
    defaultMessage: 'Error reading the RSS feed.',
  },
  tenderRssInactive: {
    // Dieser RSS-Feed ist nicht aktiviert.
    id: 'tenderRssInactive',
    defaultMessage: 'Inactive (missing) RSS feed.',
  },
  tenderRssEmpty: {
    // Im Moment liegen keine Ausschreibungen vor.
    id: 'tenderRssEmpty',
    defaultMessage: 'No tenders are available at the moment.',
  },
});

const TenderView = ({ content }) => {
  const { title, elvisData } = content;
  const intl = useIntl();
  let { isInactive, items } = elvisData || {};
  items = items instanceof Array ? items : [];
  return (
    <Container id="page-document">
      <h1 className="documentFirstHeading">{title}</h1>
      {!elvisData && (
        <p className="documentDescription">
          {intl.formatMessage(messages.tenderRssFailure)}
        </p>
      )}
      {isInactive && (
        <p className="documentDescription">
          {intl.formatMessage(messages.tenderRssInactive)}
        </p>
      )}
      {elvisData && !isInactive && items.length === 0 && (
        <p className="documentDescription">
          {intl.formatMessage(messages.tenderRssEmpty)}
        </p>
      )}
      {items.map((table, index) => (
        <>
          <h2 key={'K' + index}>
            {table?.title ? (
              table?.href ? (
                <a href={table.href}>{table.title}</a>
              ) : (
                table.title
              )
            ) : (
              ''
            )}
          </h2>
          <table key={'T' + index} className="ui celled table tender-table">
            <tbody className="">
              {table?.rows &&
                table.rows.map(([key, value], index) => (
                  <tr key={index}>
                    <td>{key}</td>
                    <td>
                      {value?.href ? (
                        <a href={value.href}>{value.text}</a>
                      ) : (
                        value?.text || ''
                      )}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </>
      ))}
    </Container>
  );
};

export default TenderView;
