import React, { useState, useEffect } from 'react';
import { Helmet } from '@plone/volto/helpers';
import { useDispatch, useSelector } from 'react-redux';
import { defineMessages, injectIntl } from 'react-intl';
import { Container } from 'semantic-ui-react';
import { parse } from 'date-fns';
import { Link } from 'react-router-dom';
import IframeResizer from 'iframe-resizer-react';
import { Icon } from '@plone/volto/components';
import { flattenToAppURL } from '@plone/volto/helpers';
import { ContactForm } from '@package/components';
import { getContent } from '@plone/volto/actions';

import pdfSVG from '@package/icons/pdf.svg';
import mailSVG from '@package/icons/apply.svg';

const messages = defineMessages({
  unknownBlock: {
    id: 'Unknown Block',
    defaultMessage: 'Unknown Block {block}',
  },
  offer_departement: {
    id: 'Institute issuing the offer',
    defaultMessage: 'Institute issuing the offer',
  },
  external_id: {
    id: 'external id',
    defaultMessage: 'external id',
  },
  offer: {
    id: 'offer',
    defaultMessage: 'offer',
  },
  submission_text_date: {
    id: 'We look forward to receiving your application by',
    defaultMessage: 'We look forward to receiving your application by ',
  },
  submission_text_no_date: {
    id:
      'We look forward to receiving your application. The job will be advertised until the position has been successfully filled. You should therefore submit your application as soon as possible.',
    defaultMessage:
      'We look forward to receiving your application. The job will be advertised until the position has been successfully filled. You should therefore submit your application as soon as possible.',
  },
  on_our: {
    id: 'on our',
    defaultMessage: 'on our',
  },
  application_portal: {
    id: 'application portal',
    defaultMessage: 'application portal',
  },
  questions: {
    id: 'Questions about the offer',
    defaultMessage: 'Questions about the offer?',
  },
  contact_us: {
    id: 'Please feel free to contact us via our',
    defaultMessage: 'Please feel free to contact us via our',
  },
  contact_form: {
    id: 'contact form',
    defaultMessage: 'contact form',
  },
  contact_form_capital: {
    id: 'Contact form',
    defaultMessage: 'Contact form',
  },
  contact_mail: {
    id:
      'Please note that for technical reasons we cannot accept applications by e-mail.',
    defaultMessage:
      'Please note that for technical reasons we cannot accept applications by e-mail.',
  },
  dateFormat: {
    id: 'dateFormat',
    defaultMessage: 'de-DE',
  },
  applyNow: {
    id: 'Apply now',
    defaultMessage: 'Apply now',
  },
  apply: {
    id: 'Apply',
    defaultMessage: 'Apply',
  },
  info_application_process: {
    id:
      'You can find helpful information on the application and selection process',
    defaultMessage:
      'You can find helpful information on the application and selection process',
  },
  info_application_process_link: {
    id: '/en/careers/application-information',
    defaultMessage: 'https://go.fzj.de/InformationApplication',
  },
  info_application_process_here: {
    id: 'here',
    defaultMessage: 'here',
  },
  application_faq: {
    id: 'You can also find answers to frequently asked questions in our',
    defaultMessage:
      'You can also find answers to frequently asked questions in our',
  },
  application_faq_link: {
    id: '/en/careers/application-information/faq',
    defaultMessage: 'https://go.fzj.de/FAQ_eng',
  },
  info_faq_heading: {
    id: 'Application tips & FAQs',
    defaultMessage: 'Application tips & FAQs',
  },
  info_faq_text: {
    id:
      'Information on the application process and an overview of FAQs can be found',
    defaultMessage:
      'Information on the application process and an overview of FAQs can be found',
  },
  info_faq_link: {
    id: 'https://www.fz-juelich.de/en/careers/application-information',
    defaultMessage:
      'https://www.fz-juelich.de/en/careers/application-information',
  },
  contact_block_1: {
    id: 'If your questions have not yet been answered via our',
    defaultMessage: 'If your questions have not yet been answered via our',
  },
  contact_block_2: {
    id: ', please send us a message via our',
    defaultMessage: ', please send us a message via our',
  },
});

const urlify = (text) => {
  var urlRegex = /(https?:\/\/[^\s^<]+)/g;
  return text.replace(urlRegex, function (url) {
    return '<a href="' + url + '">' + url + '</a>';
  });
};

const JobOfferView = (props) => {
  const dispatch = useDispatch();
  const { content, intl, location } = props;
  const [showContactForm, setShowContactForm] = useState(
    location.search === '?contact',
  );
  const dateOptions = { month: 'numeric', year: 'numeric', day: 'numeric' };
  const imagesLocation = '/de/karriere/bilder-stellenangebote';

  useEffect(() => {
    dispatch(
      getContent(`${imagesLocation}/${content.image}`, null, content.id),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const image = useSelector(
    (state) =>
      state.content.subrequests[content.id]?.data?.image.scales.huge.download,
  );

  /* Create Json Metadata for Google Jobs */
  let JobPostingData = {
    '@context': 'https://schema.org/',
    '@type': 'JobPosting',
    hiringOrganization: {
      '@type': 'Organization',
      name: 'Forschungszentrum Jülich GmbH',
      sameAs: 'https://www.fz-juelich.de',
    },
    title: content.title,
    description: content.description + content.task_description.data,
    identifier: {
      '@type': 'PropertyValue',
      name: 'Forschungszentrum Jülich GmbH',
      value: content.external_id,
    },
    datePosted: content.date_of_issue,
    jobLocation: {
      '@type': 'Place',
      address: {
        '@type': 'PostalAddress',
        addressCountry: 'Germany',
        addressLocality: content.job_offer_location,
      },
    },
    directApply: true,
  };

  /* Add conditional data to Google Jobs Data */
  function finalize_JobPostingData() {
    if (content.submission_date) {
      JobPostingData['validThrough'] = content.submission_date;
    } else {
      JobPostingData['description'] =
        JobPostingData['description'] +
        `<p>${intl.formatMessage(messages.submission_text_no_date)}</p>`;
    }
    if (content.job_offer_location === 'Oak Ridge') {
      JobPostingData['jobLocation']['address']['addressCountry'] = 'USA';
    } else if (content.job_offer_location === 'Genua') {
      JobPostingData['jobLocation']['address']['addressCountry'] = 'Italy';
    }
    return JobPostingData;
  }

  return (
    <>
      {showContactForm && (
        <ContactForm
          {...props}
          subject={content.external_id}
          hideSubjectField={true}
          onClose={() => {
            setShowContactForm(false);
            props.history.push({
              search: '',
            });
          }}
        />
      )}
      {location.search === '?apply' ? (
        <Container>
          <IframeResizer
            className="application-form"
            id="application-iframe"
            src={content.offer_url}
            title="Application Form"
          />
        </Container>
      ) : (
        <div>
          <Helmet title={content.title}>
            <script type="application/ld+json">
              {JSON.stringify(finalize_JobPostingData())}
            </script>
          </Helmet>
          <div className="job-offer-top-wrapper">
            <Container className="preview">
              {content.short_header_text && (
                <h2>{content.short_header_text}</h2>
              )}
              {content['@type'] !== 'LRF' && (
                <h1 className="documentFirstHeading">{content.title}</h1>
              )}
              <div className="preview-body">
                {content.image && <img src={image} alt="titel" />}
                {content.description && (
                  <div className="preview-text">
                    <Container id="modified-container-description">
                      <p>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: urlify(content?.description),
                          }}
                        />
                      </p>
                    </Container>
                  </div>
                )}
              </div>
              <div className="preview-header">
                <div className="preview-fields">
                  <span className="meta-header">
                    {intl.formatMessage(messages.info_faq_heading)}
                  </span>
                  <br />
                  <span>{intl.formatMessage(messages.info_faq_text)} </span>
                  <a href={intl.formatMessage(messages.info_faq_link)}>
                    {intl.formatMessage(messages.info_application_process_here)}
                  </a>
                </div>
                <div className="icon-section">
                  <Link
                    className="apply-link"
                    to={`${location.pathname}?apply`}
                    onClick={() => window.scrollTo(0, 0)}
                  >
                    <Icon className="fileSVG" size="30px" name={mailSVG} />{' '}
                    {intl.formatMessage(messages.apply)}
                  </Link>
                  {content.file && (
                    <a
                      href={flattenToAppURL(content.file.download)}
                      className="offer-pdf"
                    >
                      <Icon className="fileSVG" size="30px" name={pdfSVG} />{' '}
                      {intl.formatMessage(messages.offer)}
                    </a>
                  )}
                </div>
              </div>
            </Container>
          </div>
          <div id="page-document">
            <Container
              id="modified-container-bottom"
              className="job-offer-bottom-wrapper"
            >
              <div>
                {content.task_description && (
                  <div className="task-description">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: urlify(content?.task_description?.data),
                      }}
                    />
                  </div>
                )}

                <div className="portal-box">
                  <h2 className="submission-text">
                    {content.submission_date ? (
                      <>
                        {intl.formatMessage(messages.submission_text_date)}{' '}
                        {parse(content.submission_date).toLocaleDateString(
                          intl.formatMessage(messages.dateFormat),
                          dateOptions,
                        )}
                      </>
                    ) : (
                      <>
                        {intl.formatMessage(messages.submission_text_no_date)}
                      </>
                    )}
                    <br />
                  </h2>
                  <Link
                    className="ui button blue"
                    to={`${location.pathname}?apply`}
                    onClick={() => window.scrollTo(0, 0)}
                  >
                    {intl.formatMessage(messages.applyNow)}
                  </Link>
                </div>

                <div className="questions">
                  <h3>{intl.formatMessage(messages.contact_form_capital)}</h3>
                  <p>
                    {intl.formatMessage(messages.contact_block_1)}{' '}
                    <a href={intl.formatMessage(messages.application_faq_link)}>
                      FAQs
                    </a>{' '}
                    {intl.formatMessage(messages.contact_block_2)}{' '}
                    <Link
                      role="button"
                      className="contact-button"
                      to={`${location.pathname}?contact`}
                      onClick={() => setShowContactForm(true)}
                    >
                      {intl.formatMessage(messages.contact_form)}
                    </Link>
                    {'.'}
                  </p>
                  <p>{intl.formatMessage(messages.contact_mail)}</p>
                </div>
              </div>
            </Container>
          </div>
        </div>
      )}
    </>
  );
};

export default injectIntl(JobOfferView);
