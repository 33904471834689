/**
 * Home container.
 * @module components/theme/NotFound/NotFound
 */

import React from 'react';
// import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { defineMessages, useIntl } from 'react-intl';
import { Container } from 'semantic-ui-react';
import { withServerErrorCode } from '@plone/volto/helpers/Utils/Utils';
import { useSelector } from 'react-redux';

const messages = defineMessages({
  notFound: {
    id: 'Page not found',
    defaultMessage: 'Seite nicht gefunden',
  },
  pageNotAvailable: {
    id:
      'The page you were looking for is unfortunately not available (anymore).',
    defaultMessage:
      'The page you were looking for is unfortunately not available (anymore).',
  },
  useSearchPrompt: {
    id:
      'Please use the search function or alternative entry points to reach the desired information.',
    defaultMessage:
      'Please use the search function or alternative entry points to reach the desired information.',
  },
  goToSearch: {
    id: 'Go to search',
    defaultMessage: 'Go to',
  },
  goToInstitutes: {
    id: 'Go to institutes',
    defaultMessage: 'Go to',
  },
  goToContact: {
    id: 'Go to contact',
    defaultMessage: 'Go to',
  },
  search: {
    id: 'Search',
    defaultMessage: 'Search',
  },
  institutes: {
    id: 'institutes',
    defaultMessage: 'institutes',
  },
  contactVisitor: {
    id: 'Contact & Visitor information',
    defaultMessage: 'Contact & Visitor information',
  },
  thankYou: {
    id: 'Thank you',
    defaultMessage: 'Thank you',
  },
});

/**
 * Not found function.
 * @function NotFound
 * @returns {string} Markup of the not found page.
 */
const NotFound = () => {
  const intl = useIntl();
  const language = useSelector((state) => state.intl.locale);
  const instlink = language === 'de' ? '/de/institute' : '/en/institutes';
  const contactlink =
    language === 'de'
      ? '/de/ueber-uns/kontakt'
      : '/en/about-us/contact-visitor-information';
  return (
    <Container className="view-wrapper">
      <h1>{intl.formatMessage(messages.notFound)}</h1>
      <p className="description">
        {intl.formatMessage(messages.pageNotAvailable)}
      </p>
      <p>{intl.formatMessage(messages.useSearchPrompt)}</p>
      <p>
        {intl.formatMessage(messages.goToSearch)}{' '}
        <Link to="/@@search">{intl.formatMessage(messages.search)}</Link>
      </p>

      <p>
        {intl.formatMessage(messages.goToInstitutes)}{' '}
        <Link to={instlink}>{intl.formatMessage(messages.institutes)}</Link>
      </p>

      <p>
        {intl.formatMessage(messages.goToContact)}{' '}
        <Link to={contactlink}>
          {intl.formatMessage(messages.contactVisitor)}
        </Link>
      </p>
      <p>{intl.formatMessage(messages.thankYou)}</p>
    </Container>
  );
};

export default withServerErrorCode(404)(NotFound);
