/**
 * Navigation actions.
 * @module actions/navigation/navigation
 */

import { GET_NEAREST_INSTITUT } from '@package/constants/ActionTypes';

export default function getNearestInstitut(url) {
  return {
    type: GET_NEAREST_INSTITUT,
    request: {
      op: 'get',
      path: `${url}/@nearest-institut`,
    },
  };
}
