/**
 * Research Group view component.
 * @module components/theme/View/ResearchGroupView
 */

import React from 'react';
import PropTypes from 'prop-types';
import { defineMessages, injectIntl } from 'react-intl';
import cx from 'classnames';

import { Container } from 'semantic-ui-react';
import { map } from 'lodash';
import config from '@plone/volto/registry';

import {
  getBlocksFieldname,
  getBlocksLayoutFieldname,
  hasBlocksData,
  getBaseUrl,
  flattenToAppURL,
} from '@plone/volto/helpers';

const messages = defineMessages({
  unknownBlock: {
    id: 'Unknown Block',
    defaultMessage: 'Unknown Block {block}',
  },
});

/**
 * Component to display the default view.
 * @function ResearchGroupView
 * @param {Object} content Content object.
 * @returns {string} Markup of the component.
 */
const ResearchGroupView = ({ content, intl, location }) => {
  const blocksFieldname = getBlocksFieldname(content);
  const blocksLayoutFieldname = getBlocksLayoutFieldname(content);

  return hasBlocksData(content) ? (
    <div
      className={cx('view researchgroup', {
        image: content?.banner_background_image,
      })}
    >
      {map(content[blocksLayoutFieldname].items, (block) => {
        const Block =
          config.blocks.blocksConfig[
            content[blocksFieldname]?.[block]?.['@type']
          ]?.['view'] || null;
        if (
          config.blocks.blocksConfig[
            content[blocksFieldname]?.[block]?.['@type']
          ]?.['id'] === 'title'
        ) {
          return (
            <>
              {content.banner_background_image ? (
                <div
                  className="title-area"
                  style={{
                    backgroundImage: `url(${flattenToAppURL(
                      content?.banner_background_image?.scales?.great?.download,
                    )})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                  }}
                >
                  <Container className="title researchgroup">
                    <div className="titles-wrapper">
                      <div className="titles">
                        <Block
                          key={block}
                          id={block}
                          properties={content}
                          data={content[blocksFieldname][block]}
                          path={getBaseUrl(location?.pathname || '')}
                        />
                        <h2 className="subtitle">{content.subtitle}</h2>
                      </div>
                    </div>
                  </Container>
                  {/* <div>{content.description}</div> */}
                </div>
              ) : (
                <Container className="page-researchgroup">
                  <h1 className="documentFirstHeading">{content.title}</h1>
                  <h3 className="subtitle">{content.subtitle}</h3>
                </Container>
              )}
            </>
          );
        }
        return Block !== null ? (
          <Container className="page-researchgroup">
            <Block
              key={block}
              id={block}
              properties={content}
              data={content[blocksFieldname][block]}
              path={getBaseUrl(location?.pathname || '')}
            />
          </Container>
        ) : (
          <Container className="page-researchgroup">
            <div key={block}>
              {intl.formatMessage(messages.unknownBlock, {
                block: content[blocksFieldname]?.[block]?.['@type'],
              })}
            </div>
          </Container>
        );
      })}
    </div>
  ) : (
    <div className="ui container page-researchgroup">
      <h1 className="documentFirstHeading">{content.title}</h1>
      <h3 className="subtitle">{content.subtitle}</h3>
    </div>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
ResearchGroupView.propTypes = {
  /**
   * Content of the object
   */
  content: PropTypes.shape({
    /**
     * Title of the object
     */
    title: PropTypes.string,
    /**
     * Subtitle of the object
     */
    subtitle: PropTypes.string,
    /**
     * Description of the object
     */
    description: PropTypes.string,
    /**
     * Text of the object
     */
    banner_background_image: PropTypes.object,
    text: PropTypes.shape({
      /**
       * Data of the text of the object
       */
      data: PropTypes.string,
    }),
  }).isRequired,
};

export default injectIntl(ResearchGroupView);
