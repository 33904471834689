import { defineMessages } from 'react-intl';

const messages = defineMessages({
  Source: {
    id: 'Source',
    defaultMessage: 'Source',
  },
  openLinkInNewTab: {
    id: 'Open in a new tab',
    defaultMessage: 'Open in a new tab',
  },
  SortOn: {
    id: 'Sort on',
    defaultMessage: 'Sort on',
  },
  title: {
    id: 'Title',
    defaultMessage: 'Title',
  },
  description: {
    id: 'Description',
    defaultMessage: 'Description',
  },
  imageOverride: {
    id: 'Image override',
    defaultMessage: 'Image override',
  },
  reversedOrder: {
    id: 'Reversed order',
    defaultMessage: 'Reversed order',
  },
  limit: {
    id: 'Results limit',
    defaultMessage: 'Results limit',
  },
  itemBatchSize: {
    id: 'Item batch size',
    defaultMessage: 'Item batch size',
  },
  headline: {
    id: 'Headline',
    defaultMessage: 'Headline',
  },
  head_title: {
    id: 'Headtitle',
    defaultMessage: 'Headtitle',
  },
});

export const AktuellesSchema = (props) => {
  const { intl } = props;

  return {
    title: 'Aktuelles',
    block: 'aktuelles',
    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: ['headline'],
      },
      {
        id: 'Teaser1',
        title: 'Teaser 1',
        fields: [
          'href',
          'title',
          'head_title',
          'description',
          'preview_image',
          'openLinkInNewTab',
        ],
      },
      {
        id: 'Teaser2',
        title: 'Teaser 2',
        fields: [
          'thref',
          'ttitle',
          'thead_title',
          'tdescription',
          'tpreview_image',
          'topenLinkInNewTab',
        ],
      },
      {
        id: 'listing',
        title: 'Listing',
        fields: ['querystring', 'sort_on', 'reversedOrder', 'limit'],
      },
    ],
    properties: {
      headline: {
        title: intl.formatMessage(messages.headline),
      },
      href: {
        title: intl.formatMessage(messages.Source),
        widget: 'object_browser',
        mode: 'link',
        selectedItemAttrs: [
          'Title',
          'Description',
          'hasPreviewImage',
          'headtitle',
        ],
        allowExternals: true,
      },
      title: {
        title: intl.formatMessage(messages.title),
      },
      head_title: {
        title: intl.formatMessage(messages.head_title),
      },
      description: {
        title: intl.formatMessage(messages.description),
        widget: 'textarea',
      },
      preview_image: {
        title: intl.formatMessage(messages.imageOverride),
        widget: 'object_browser',
        mode: 'image',
        allowExternals: true,
      },
      openLinkInNewTab: {
        title: intl.formatMessage(messages.openLinkInNewTab),
        type: 'boolean',
      },
      thref: {
        title: intl.formatMessage(messages.Source),
        widget: 'object_browser',
        mode: 'link',
        selectedItemAttrs: [
          'Title',
          'Description',
          'hasPreviewImage',
          'headtitle',
        ],
        allowExternals: true,
      },
      ttitle: {
        title: intl.formatMessage(messages.title),
      },
      thead_title: {
        title: intl.formatMessage(messages.head_title),
      },
      tdescription: {
        title: intl.formatMessage(messages.description),
        widget: 'textarea',
      },
      tpreview_image: {
        title: intl.formatMessage(messages.imageOverride),
        widget: 'object_browser',
        mode: 'image',
        allowExternals: true,
      },
      topenLinkInNewTab: {
        title: intl.formatMessage(messages.openLinkInNewTab),
        type: 'boolean',
      },
      querystring: {
        title: 'Listing',
        widget: 'query',
      },
      sort_on: {
        title: intl.formatMessage(messages.SortOn),
        widget: 'query_sort_on',
      },
      reversedOrder: {
        title: intl.formatMessage(messages.reversedOrder),
        type: 'boolean',
      },
      limit: {
        title: intl.formatMessage(messages.limit),
      },
      itemBatchSize: {
        title: intl.formatMessage(messages.itemBatchSize),
      },
    },
    required: [],
  };
};
