import React from 'react';
import { Grid, Message } from 'semantic-ui-react';
import { isInternalURL } from '@plone/volto/helpers';
import { UniversalLink } from '@plone/volto/components';
import { defineMessages, useIntl } from 'react-intl';
import teaserHeroTopTemplate from '@kitconcept/volto-blocks/components/TeaserHero/teaserhero-top-template.svg';

import imageBlockSVG from '@plone/volto/components/manage/Blocks/Image/block-image.svg';
const messages = defineMessages({
  PleaseChooseIcon: {
    id: 'Please choose an Icon',
    defaultMessage: 'Please choose an Icon',
  },
  PleaseChooseContent: {
    id: 'Please choose an existing content as source for this element',
    defaultMessage:
      'Please choose an existing content as source for this element',
  },
});

const TeaserIconView = (props) => {
  const { data, isEditMode } = props;
  const intl = useIntl();

  return (
    <div className="teaser-icon-view full-width">
      {(data.columns?.length === 0 || !data.columns) && isEditMode ? (
        <Message>
          <div className="teaser-item default">
            <img src={teaserHeroTopTemplate} alt="" />
            <p>{intl.formatMessage(messages.PleaseChooseContent)}</p>
          </div>
        </Message>
      ) : (
        <>
          <h2 className="headline">INFORMATIONEN FÜR...</h2>
          <Grid stackable className="container" columns={data?.columns?.length}>
            <Grid.Row>
              {data?.columns?.length > 0
                ? data.columns.map((item, index) => {
                    return (
                      <Grid.Column key={index}>
                        <UniversalLink
                          className="linktag"
                          href={
                            item.href
                              ? item?.href[0]?.['@id']
                                ? item?.href[0]?.['@id']
                                : '#'
                              : '#'
                          }
                        >
                          <>
                            {' '}
                            <div className="image-wrapper">
                              <img
                                className="image"
                                src={
                                  item.icon
                                    ? item.icon[0]?.['@id']
                                      ? isInternalURL(item?.icon[0]?.['@id'])
                                        ? `${item?.icon[0]?.['@id']}/@@images/image/preview`
                                        : item?.icon[0]?.['@id']
                                      : imageBlockSVG
                                    : imageBlockSVG
                                }
                                alt=""
                              />
                            </div>
                            <div className="content">
                              {item.title
                                ? item.title
                                : intl.formatMessage(messages.PleaseChooseIcon)}
                            </div>
                          </>
                        </UniversalLink>
                      </Grid.Column>
                    );
                  })
                : null}
            </Grid.Row>
          </Grid>
        </>
      )}
    </div>
  );
};

export default TeaserIconView;
