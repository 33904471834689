import { defineMessages } from 'react-intl';

const messages = defineMessages({
  AZListBlock: {
    id: 'AZListBlock',
    defaultMessage: 'A-Z List',
  },
  headline: {
    id: 'Headline',
    defaultMessage: 'Headline',
  },
  description: {
    id: 'description',
    defaultMessage: 'Description',
  },
  unit: {
    id: 'Unit',
    defaultMessage: 'Unit',
  },
  subjects: {
    id: 'Tags',
    defaultMessage: 'Tags',
  },
});

export const AZListSchema = (props) => ({
  title: props.intl.formatMessage(messages.AZListBlock),
  block: 'azlist',
  fieldsets: [
    {
      id: 'default',
      title: 'Default',
      fields: ['title', 'description', 'href', 'subjects'],
    },
  ],

  properties: {
    title: {
      title: props.intl.formatMessage(messages.headline),
    },
    description: {
      title: props.intl.formatMessage(messages.description),
    },
    href: {
      title: props.intl.formatMessage(messages.unit),
      widget: 'object_browser',
      mode: 'link',
      allowExternals: false,
    },
    subjects: {
      title: props.intl.formatMessage(messages.subjects),
      vocabulary: { '@id': 'plone.app.vocabularies.Keywords' },
    },
  },
  required: [],
});
