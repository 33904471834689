/**
 * OVERRIDE APIResourceWithAuth.js
 * REASON: Pass __rf cookie from original request to the backend (as this cookie is used to
 *         allow access to restricted files)
 * FILE: https://github.com/plone/volto/blob/master/src/helpers/Api/APIResourceWithAuth.js
 * FILE VERSION: Volto 15.0.0-alpha.2
 * PULL REQUEST: https://github.com/kitconcept/fzj-internet/pull/1034
 * TICKET: https://jugit.fz-juelich.de/fzj-internet/internet/-/issues/539
 * DATE: 2022-02-11
 * DEVELOPER: @ericof
 * CHANGELOG:
 *  - Implement Password Protection folders (#539) @ericof
 *
 * Every change is marked with a JSX comment at the beginning and end of the change:
 *
 *   START CUSTOMIZATION
 *   <CUSTOMIZATION>
 *   END CUSTOMIZATION
 */
/**
 * Sitemap helper.
 * @module helpers/Api/APIResourceWithAuth
 */

import superagent from 'superagent';
import config from '@plone/volto/registry';
import { baseAPIPath } from '@package/helpers';

/**
 * Get a resource image/file with authenticated (if token exist) API headers
 * @function getAPIResourceWithAuth
 * @param {Object} req Request object
 * @return {string} The response with the image
 */
export const getAPIResourceWithAuth = (req) =>
  new Promise((resolve, reject) => {
    const { settings } = config;
    const request = superagent
      .get(`${baseAPIPath()}${req.path}`)
      .maxResponseSize(settings.maxResponseSize)
      .responseType('blob');
    const authToken = req.universalCookies.get('auth_token');
    if (authToken) {
      request.set('Authorization', `Bearer ${authToken}`);
    }
    /* START CUSTOMIZATION */
    const rfCookie = req.universalCookies.get('__rf');
    if (rfCookie) {
      // Copy restricted folder cookie from incoming request
      request.cookies = `__rf=${JSON.stringify(rfCookie)}`;
    }
    /* END CUSTOMIZATION */
    request.then(resolve).catch(reject);
  });
