import { defineMessages } from 'react-intl';

const messages = defineMessages({
  Image: {
    id: 'Image',
    defaultMessage: 'Image',
  },
  title: {
    id: 'Title',
    defaultMessage: 'Title',
  },
  description: {
    id: 'Description',
    defaultMessage: 'Description',
  },
  buttonLink: {
    id: 'Button Link',
    defaultMessage: 'Button Link',
  },
  buttonTitle: {
    id: 'Button Title',
    defaultMessage: 'Button Title',
  },
  width: {
    id: 'Width',
    defaultMessage: 'Width',
  },
  height: {
    id: 'Height',
    defaultMessage: 'Height',
  },
  small: {
    id: 'Small',
    defaultMessage: 'Small',
  },
  medium: {
    id: 'Medium',
    defaultMessage: 'Medium',
  },
  large: {
    id: 'Large',
    defaultMessage: 'Large',
  },
  default: {
    id: 'Default',
    defaultMessage: 'Default',
  },
  narrow: {
    id: 'Narrow',
    defaultMessage: 'Narrow',
  },
  hideButton: {
    id: 'Hide Button',
    defaultMessage: 'Hide Button',
  },
  left: {
    id: 'Left',
    defaultMessage: 'Left',
  },
  right: {
    id: 'Right',
    defaultMessage: 'Right',
  },
  center: {
    id: 'Center',
    defaultMessage: 'Center',
  },
  flagAlignTitle: {
    id: 'Text Align',
    defaultMessage: 'Text Align',
  },
});

export function HighlightTeaserSchema(props) {
  const { intl } = props;

  return {
    block: 'highlightTeaser',
    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: [
          'href',
          'title',
          'description',
          'linkTitle',
          'link',
          'hideButton',
          'width',
          'height',
          'flagAlign',
        ],
      },
    ],
    properties: {
      href: {
        title: intl.formatMessage(messages.Image),
        widget: 'object_browser',
        mode: 'image',
        selectedItemAttrs: [
          'Title',
          'Description',
          'hasPreviewImage',
          'head_title',
        ],
        allowExternals: true,
      },
      title: {
        title: intl.formatMessage(messages.title),
      },
      description: {
        title: intl.formatMessage(messages.description),
        widget: 'textarea',
      },
      width: {
        title: intl.formatMessage(messages.width),
        choices: [
          ['100%', '100%'],
          ['default', `${intl.formatMessage(messages.default)}`],
          ['narrow', `${intl.formatMessage(messages.narrow)}`],
        ],
        default: 'default',
      },
      height: {
        title: intl.formatMessage(messages.height),
        choices: [
          ['600px', `${intl.formatMessage(messages.medium)}`],
          ['800px', `${intl.formatMessage(messages.large)}`],
        ],
        default: '800px',
      },
      link: {
        title: intl.formatMessage(messages.buttonLink),
        widget: 'object_browser',
        mode: 'link',
        allowExternals: true,
      },
      linkTitle: {
        title: intl.formatMessage(messages.buttonTitle),
      },
      hideButton: {
        title: intl.formatMessage(messages.hideButton),
        type: 'boolean',
      },
      flagAlign: {
        title: intl.formatMessage(messages.flagAlignTitle),
        choices: [
          ['left', `${intl.formatMessage(messages.left)}`],
          ['right', `${intl.formatMessage(messages.right)}`],
          ['center', `${intl.formatMessage(messages.center)}`],
        ],
        default: 'center',
      },
    },
    required: [],
  };
}
