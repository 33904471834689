import React from 'react';
import { Message } from 'semantic-ui-react';
import { defineMessages, useIntl } from 'react-intl';
import { ParallaxBanner, ParallaxBannerLayer } from 'react-scroll-parallax';

import { getTeaserImageURL } from './utils';
import { flattenToAppURL } from '@plone/volto/helpers';
import { UniversalLink } from '@plone/volto/components';
import teaserHeroTopTemplate from '@kitconcept/volto-blocks/components/TeaserHero/teaserhero-top-template.svg';
import cx from 'classnames';

const messages = defineMessages({
  PleaseChooseContent: {
    id: 'Browse the site, drop an image, or type an URL',
    defaultMessage: 'Browse the site, drop an image, or type an URL',
  },
});

const HighlightTeaserView = (props) => {
  const { data } = props;
  const intl = useIntl();
  const href = data.href?.[0];
  const link = data.link?.[0]?.['@id'];

  let fullWidth = true;
  let viewPortWidth = false;
  if (data.width === 'default') {
    fullWidth = true;
  }
  if (data.width === 'narrow') {
    fullWidth = false;
  }
  if (data.width === '100%') {
    fullWidth = false;
    viewPortWidth = true;
  }

  return (
    <div
      className={cx('block highlightTeaser parallax', {
        'ui container': viewPortWidth || fullWidth,
      })}
    >
      {!href && (
        <Message>
          <div className="teaser-item default">
            <img src={teaserHeroTopTemplate} alt="" />
            <p>{intl.formatMessage(messages.PleaseChooseContent)}</p>
          </div>
        </Message>
      )}
      {href && (
        <div className="parallax-container">
          <ParallaxBanner
            className={cx('parallax-banner-container', {
              fullWidth: fullWidth,
              viewPortWidth: viewPortWidth,
            })}
            style={{ height: data.height }}
          >
            <ParallaxBannerLayer className="image-container" speed={-50}>
              <img
                src={flattenToAppURL(
                  getTeaserImageURL(
                    href,
                    `${viewPortWidth ? 'huge' : 'great'}`,
                  ),
                )}
                alt=""
              />
            </ParallaxBannerLayer>
            <ParallaxBannerLayer>
              <div className={`overlay ${data.flagAlign ?? 'center'}`}></div>
            </ParallaxBannerLayer>

            <ParallaxBannerLayer
              className={cx(`content-banner-layer ${data.flagAlign}`, {
                viewPortWidth: viewPortWidth,
                center: !data.flagAlign,
                fullWidth: fullWidth,
              })}
              speed={20}
            >
              <div className="content-container">
                {data.title && (
                  <div className="title">
                    <h3> {data?.title}</h3>
                  </div>
                )}
                {data?.description && <p>{data?.description}</p>}
                {!data.hideButton && link && (
                  <div className="more-button">
                    <UniversalLink href={flattenToAppURL(link)}>
                      {data.linkTitle ?? 'Mehr Infos'}
                    </UniversalLink>
                  </div>
                )}
              </div>
            </ParallaxBannerLayer>
          </ParallaxBanner>
        </div>
      )}

      <div className="mobile-view-content-container">
        {data.title && (
          <div className="title">
            <h3> {data?.title}</h3>
          </div>
        )}
        {data?.description && <p>{data?.description}</p>}
        {!data.hideButton && link && (
          <div className="more-button">
            <UniversalLink href={flattenToAppURL(link)}>
              {data.linkTitle ?? 'Mehr Infos'}
            </UniversalLink>
          </div>
        )}
      </div>
    </div>
  );
};

export default HighlightTeaserView;
