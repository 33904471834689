import React from 'react';
import { Message } from 'semantic-ui-react';
import { defineMessages, useIntl } from 'react-intl';
import imageBlockSVG from '@plone/volto/components/manage/Blocks/Image/block-image.svg';
import { flattenToAppURL } from '@plone/volto/helpers';
import { getTeaserImageURL } from '@kitconcept/volto-blocks-grid/components/Teaser/utils';
import { MaybeWrap, UniversalLink } from '@plone/volto/components';

const messages = defineMessages({
  PleaseChooseContent: {
    id: 'Please choose an existing content as source for this element',
    defaultMessage:
      'Please choose an existing content as source for this element',
  },
});

const TeaserTransparent = ({ data, isEditMode }) => {
  const intl = useIntl();
  const href = data.href?.[0];
  const image = data.preview_image?.[0];

  return (
    <>
      {!href && isEditMode && (
        <Message>
          <div className="grid-teaser-item default">
            <img src={imageBlockSVG} alt="" />
            <p>{intl.formatMessage(messages.PleaseChooseContent)}</p>
          </div>
        </Message>
      )}
      {href && (
        <MaybeWrap
          condition={!isEditMode}
          as={UniversalLink}
          href={href['@id']}
          target={data.openLinkInNewTab ? '_blank' : null}
        >
          <div
            className="grid-teaser-item transparent"
            style={
              href.hasPreviewImage || image
                ? {
                    backgroundImage: `linear-gradient(rgba(2, 61, 107, 0.8), rgba(2, 61, 107, 0.8) ), url(${flattenToAppURL(
                      getTeaserImageURL(href, image),
                    )})`,
                  }
                : null
            }
          >
            <h3>{data?.title}</h3>
          </div>
        </MaybeWrap>
      )}
    </>
  );
};

export default TeaserTransparent;
