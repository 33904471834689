import { defineMessages } from 'react-intl';

const messages = defineMessages({
  Source: {
    id: 'Source',
    defaultMessage: 'Source',
  },
  memberList: {
    id: 'Persons List',
    defaultMessage: 'Persons List',
  },
  item: {
    id: 'Person',
    defaultMessage: 'Person',
  },
  items: {
    id: 'Items',
    defaultMessage: 'Items',
  },
  addItem: {
    id: 'Add Person',
    defaultMessage: 'Add Person',
  },
  headline: {
    id: 'Headline',
    defaultMessage: 'Headline',
  },
});

const itemSchema = (props) => {
  const { intl } = props;

  return {
    title: intl.formatMessage(messages.item),
    addMessage: intl.formatMessage(messages.addItem),
    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: ['href'],
      },
    ],

    properties: {
      href: {
        title: intl.formatMessage(messages.Source),
        widget: 'object_browser',
        mode: 'link',
        widgetOptions: {
          pattern_options: { selectableTypes: ['Member'] },
        },
        selectedItemAttrs: [
          'Title',
          'Description',
          'image_field',
          'email',
          'room',
          'firstname',
          'name',
          'phone',
          'building',
          'institutes',
          'positions',
          'institutsbereiche',
          'academic',
          'administrational_units',
        ],
        allowExternals: true,
      },
    },
    required: [],
  };
};

export const MemberListSchema = (props) => {
  const { intl } = props;

  return {
    title: intl.formatMessage(messages.memberList),
    block: 'memberList',
    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: ['headline', 'columns'],
      },
    ],

    properties: {
      headline: {
        title: intl.formatMessage(messages.headline),
      },
      columns: {
        widget: 'object_list',
        title: intl.formatMessage(messages.items),
        schema: itemSchema,
      },
    },
    required: [],
  };
};
