/**
 * Unlock Form reducer.
 * @module reducers/unlockform/unlockform
 */

import { POST_UNLOCK_FORM } from '@package/constants/ActionTypes';

const initialState = {
  error: null,
  loaded: false,
  loading: false,
  token: null,
};

export default function unlockform(state = initialState, action = {}) {
  switch (action.type) {
    case `${POST_UNLOCK_FORM}_PENDING`:
      return {
        ...state,
        error: null,
        loaded: false,
        loading: true,
        token: null,
      };
    case `${POST_UNLOCK_FORM}_SUCCESS`:
      let token = '';
      if (action.result?.['token']) {
        token = action.result?.['token'];
      }
      return {
        ...state,
        error: null,
        loaded: true,
        loading: false,
        token: token,
      };
    case `${POST_UNLOCK_FORM}_FAIL`:
      return {
        ...state,
        error: action.error,
        loaded: false,
        loading: false,
        token: null,
      };
    default:
      return state;
  }
}
