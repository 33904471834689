import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import jwtDecode from 'jwt-decode';
import { useHistory } from 'react-router-dom';
import convertUserId from './convertUserId';

const CentralProfile = (props) => {
  const userId = useSelector((state) =>
    state.userSession.token ? jwtDecode(state.userSession.token).sub : '',
  );
  const history = useHistory();
  const [redirect, setRedirect] = useState();
  const doRedirect = (url) => {
    history.push(url);
    setRedirect(url);
  };
  useEffect(() => {
    if (!userId) {
      doRedirect('/login?return_url=/profil');
    } else {
      doRedirect(`/profile/${convertUserId(userId)}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  if (redirect) {
    return (
      <div>
        <p>
          You are being redirected. If the page does not redirect you, please
          click <a href={redirect}>here</a>.
        </p>
      </div>
    );
  } else {
    return (
      <div>
        <p>Loading...</p>
      </div>
    );
  }
};

export default CentralProfile;
