export const CART_ORDER = 'CART_ORDER';
export const CLEAR_CART_ORDER = 'CLEAR_CART_ORDER';
export const CART_FORM = 'CART_FORM';
export const CLEAR_CART_FORM = 'CLEAR_CART_FORM';

export function cartOrder(data) {
  return {
    type: CART_ORDER,
    request: {
      op: 'post',
      path: `/@cart-order`,
      data,
    },
  };
}

export function clearCartOrder() {
  return {
    type: CLEAR_CART_ORDER,
  };
}

export function cartForm(formData) {
  return {
    type: CART_FORM,
    formData,
  };
}

export function clearCartForm() {
  return {
    type: CLEAR_CART_FORM,
  };
}
