/**
 * Navigation reducer.
 * @module reducers/navigation/navigation
 */

import { GET_NEAREST_INSTITUT } from '@package/constants/ActionTypes';
import { GET_CONTENT } from '@plone/volto/constants/ActionTypes';

const initialState = {
  error: null,
  institut: {},
  institutsbereich: {},
  subsite: {},
  lrf: {},
  loaded: false,
  loading: false,
};

export default function nearestinstitut(state = initialState, action = {}) {
  switch (action.type) {
    case `${GET_NEAREST_INSTITUT}_PENDING`:
    case `${GET_CONTENT}_PENDING`:
      return {
        ...state,
        error: null,
        loaded: false,
        loading: true,
      };
    case `${GET_NEAREST_INSTITUT}_SUCCESS`:
    case `${GET_CONTENT}_SUCCESS`:
      let institut, institutsbereich, subsite, lrf;
      if (action.result?.['@components']?.nearest_institut?.institut) {
        institut = action.result['@components'].nearest_institut.institut;
        institutsbereich =
          action.result['@components'].nearest_institut.institutsbereich;
        subsite = action.result['@components'].nearest_institut.subsite;
        lrf = action.result['@components'].nearest_institut.lrf;
      } else {
        institut = action.result.institut || {};
        institutsbereich = action.result.institutsbereich || {};
        subsite = action.result.subsite || {};
        lrf = action.result.lrf || {};
      }

      return {
        ...state,
        error: null,
        institut: institut,
        institutsbereich: institutsbereich,
        subsite: subsite,
        lrf: lrf,
        loaded: true,
        loading: false,
      };
    case `${GET_NEAREST_INSTITUT}_FAIL`:
    case `${GET_CONTENT}_FAIL`:
      return {
        ...state,
        error: action.error,
        institut: {},
        institutsbereich: {},
        subsite: {},
        lrf: {},
        loaded: false,
        loading: false,
      };
    default:
      return state;
  }
}
