import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { ConditionalLink } from '@plone/volto/components';
import { flattenToAppURL } from '@plone/volto/helpers';
import { format, parse } from 'date-fns';

import { isInternalURL } from '@plone/volto/helpers/Url/Url';

const EventListingTemplate = ({ items, linkMore, isEditMode }) => {
  let link = null;
  let href = linkMore?.href || '';

  const language = useSelector((state) => state.intl.locale);

  var deLocale = require('date-fns/locale/de');
  var enLocale = require('date-fns/locale/en');

  if (isInternalURL(href)) {
    link = (
      <ConditionalLink
        to={flattenToAppURL(href)}
        condition={!isEditMode}
        className="ui button"
      >
        {linkMore?.title || href}
      </ConditionalLink>
    );
  } else if (href) {
    link = <a href={href}>{linkMore?.title || href}</a>;
  }

  return (
    <>
      <div className="news-listing-template">
        <div className="items">
          {items.map((item) => (
            <div key={item.UID} className="event-item">
              <div className="content">
                <div className="listing-item" key={item['@id']}>
                  <div className="text">
                    <ConditionalLink
                      to={flattenToAppURL(item['@id'])}
                      condition={!isEditMode}
                    >
                      <div className="listing-body">
                        <div className="dates">
                          {item?.start ? (
                            <span className="day">
                              {language === 'de'
                                ? format(parse(item.start), 'DD.MM.YYYY', {
                                    locale: deLocale,
                                  })
                                : format(parse(item.start), 'Do MMMM YYYY', {
                                    locale: enLocale,
                                  })}
                            </span>
                          ) : (
                            <span className="day">No date</span>
                          )}{' '}
                          &mdash;&nbsp;
                          {item?.end ? (
                            <span className="day">
                              {language === 'de'
                                ? format(parse(item?.end), 'DD.MM.YYYY', {
                                    locale: deLocale,
                                  })
                                : format(parse(item?.end), 'Do MMMM YYYY', {
                                    locale: enLocale,
                                  })}
                            </span>
                          ) : (
                            <span className="day">No date</span>
                          )}
                          {/* Can be uncommented and exchanged with topics when we have an additional field for topics
                           {item?.subjects.length >= 1 ? (
                            <span className="topics">
                              {item.subjects.slice(0, 3).map((item, i) => {
                                return i <= 1 ? item + ', ' : item;
                              })}
                            </span>
                          ) : (
                            <span></span>
                          )} */}
                        </div>
                        <h3>{item.title ? item.title : item.id}</h3>
                      </div>
                    </ConditionalLink>
                    {item?.subjects && item.subjects?.length >= 1 ? (
                      <div className="tags">
                        {item.subjects.map((item, i) => {
                          return (
                            <ConditionalLink
                              to={`/search?Subject=${item}`}
                              condition={!isEditMode}
                            >
                              {item}
                            </ConditionalLink>
                          );
                        })}
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>

        {link && <div className="block-footer">{link}</div>}
      </div>
    </>
  );
};
EventListingTemplate.propTypes = {
  items: PropTypes.arrayOf(PropTypes.any).isRequired,
  linkMore: PropTypes.any,
  isEditMode: PropTypes.bool,
};
export default EventListingTemplate;
