import { GET_INSTITUT_LIST } from '@package/constants/ActionTypes';

export default function getInstitutList(institutes = [], locale) {
  const setParams = (institutes) => {
    let instituteQuery = '';
    if (institutes && institutes.length > 0) {
      institutes.map(
        (institute, index) =>
          (instituteQuery =
            instituteQuery +
            institute +
            (index < institutes.length - 1 ? '%2C' : '')),
      );
    }
    return instituteQuery;
  };

  let paramInstitutes = `?institutes=${setParams(institutes)}`;
  let paramLocale = `&locale=${locale ? locale : 'all'}`;
  let query = `/@institutlist${paramInstitutes}&${paramLocale}`;

  return {
    type: GET_INSTITUT_LIST,
    request: {
      op: 'get',
      path: query,
    },
  };
}
