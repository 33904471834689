import { defineMessages } from 'react-intl';

defineMessages({
  documents: {
    id: 'Documents',
    defaultMessage: 'Documents',
  },
  images: {
    id: 'Images',
    defaultMessage: 'Images',
  },
  scrollTop: {
    id: 'scroll to top',
    defaultMessage: 'scroll to top',
  },
});

const applyConfig = (config) => {
  return config;
};

export default applyConfig;
