import React, { useState } from 'react';
import { Button } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { defineMessages, injectIntl } from 'react-intl';

import VocabularyMultiSelect from '@package/components/Theme/Job-Listing/VocabularyMultiSelect';
import { Icon } from '@plone/volto/components';
import { flattenToAppURL } from '@plone/volto/helpers';

import searchSVG from '@package/icons/search-icon-white.svg';

const messages = defineMessages({
  Targetgroup: {
    id: 'Career level',
    defaultMessage: 'Career level',
  },
  Location: {
    id: 'Place of work',
    defaultMessage: 'Place of work',
  },
  JobSubject: {
    id: 'Discipline',
    defaultMessage: 'Discipline',
  },
  FieldOfWork: {
    id: 'Field of work',
    defaultMessage: 'Field of work',
  },
});

const JobFilterBlockView = (props) => {
  const { data } = props;
  const [searchTargetGroup, setSearchTargetGroup] = useState([]);
  const [searchFieldOfWork, SetSearchFieldOfWork] = useState([]);
  const [searchLocation, setSearchLocation] = useState([]);

  const SearchParams = () => {
    const paramsObj = {};
    if (searchTargetGroup?.length > 0) {
      paramsObj.targetGroup = searchTargetGroup;
    }
    if (searchFieldOfWork?.length > 0) {
      paramsObj.fieldOfWork = searchFieldOfWork;
    }
    if (searchLocation?.length > 0) {
      paramsObj.location = searchLocation;
    }

    const queryparams = new URLSearchParams(paramsObj);
    const query = queryparams.toString();
    return query;
  };

  return (
    <div className="full-width">
      <div className="block job-filter">
        <div className="full-width">
          {data.title && (
            <div className="headline-wrapper">
              <h2>{data.title}</h2>
            </div>
          )}
          <div className="select-container">
            <VocabularyMultiSelect
              id="job-offer-target"
              value={searchTargetGroup}
              onChange={(id, label, value) => {
                setSearchTargetGroup(value);
              }}
              placeholder={props.intl.formatMessage(messages.Targetgroup)}
              items={{
                vocabulary: {
                  '@id': 'fzj.internet.vocabulary.job_offer_target_groups',
                },
              }}
            />
            <VocabularyMultiSelect
              id="job-offer-target"
              value={searchFieldOfWork}
              onChange={(id, label, value) => {
                SetSearchFieldOfWork(value);
              }}
              placeholder={props.intl.formatMessage(messages.FieldOfWork)}
              items={{
                vocabulary: {
                  '@id': 'fzj.internet.vocabulary.job_offer_fields_of_work',
                },
              }}
            />
            <VocabularyMultiSelect
              id="job-offer-locations"
              value={searchLocation}
              onChange={(id, label, value) => {
                setSearchLocation(value);
              }}
              placeholder={props.intl.formatMessage(messages.Location)}
              items={{
                vocabulary: {
                  '@id': 'fzj.internet.vocabulary.job_offer_locations',
                },
              }}
            />
            {data.jobsLocation?.length > 0 ? (
              <Link
                aria-label="search"
                className="ui button search-button"
                to={`${flattenToAppURL(
                  data.jobsLocation?.[0]['@id'],
                )}?${SearchParams()}`}
              >
                <Icon name={searchSVG} color="#fff" size="40px" />
              </Link>
            ) : (
              <Button className="search-button" aria-label="search">
                <Icon name={searchSVG} color="#fff" size="40px" />
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default injectIntl(JobFilterBlockView);
