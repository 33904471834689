import React from 'react';
import { Grid, Embed } from 'semantic-ui-react';
import { Icon } from '@plone/volto/components';

import xSVG from '@package/icons/x.svg';
import mastodonSVG from '@package/icons/mastodon.svg';
import threadsSVG from '@package/icons/threads.svg';
import youtubeSVG from '@package/icons/youtube.svg';
import newsletterSvg from '@package/icons/newsletter.svg';
import termineSvg from '@package/icons/termine.svg';
import magazineSVG from '@package/icons/magazine.svg';
import juelichIcon from '@package/icons/Juelich_Blogs.png';
import linkedinSVG from '@package/icons/linkedin.svg';
import instagramSVG from '@package/icons/instagram.svg';

import imageBlockSVG from '@plone/volto/components/manage/Blocks/Image/block-image.svg';
import { getTeaserImageURL } from './utils';
import UniversalLink from '@plone/volto/components/manage/UniversalLink/UniversalLink';

const SocialMediaView = (props) => {
  const { data } = props;
  return (
    <>
      <div className="socialMedia container">
        {data.headline && <h2 className="headline">{data.headline}</h2>}
        <Grid
          stackable
          style={{ marginLeft: '0px' }}
          className="socialMedia"
          columns={2}
        >
          <Grid.Row>
            <Grid.Column className="social-column">
              <UniversalLink href={props.data.link} aria-label="visit blog">
                <div className="content">
                  <div className="blog-container">
                    <img
                      src={juelichIcon}
                      style={{ height: '24px' }}
                      alt="Jülich Blogs"
                    />
                    <h2>Jülich Blogs</h2>
                  </div>
                  <p>{props.data.description}</p>
                </div>
                <div className="image-container">
                  {props.data.bild ? (
                    props.data?.bild[0]?.['@id'] ? (
                      <img
                        src={getTeaserImageURL(props.data?.bild[0])}
                        alt=""
                      />
                    ) : (
                      <img src={imageBlockSVG} alt="" />
                    )
                  ) : (
                    <img src={imageBlockSVG} alt="" />
                  )}
                </div>
              </UniversalLink>
            </Grid.Column>
            <Grid.Column className="social-column">
              <div className="content">
                <span>
                  {' '}
                  <Icon name={youtubeSVG} color="#004176" size="32px" />
                </span>
                <h2>YouTube</h2>
                <p>{props.data.ydescription}</p>
              </div>
              <div className="youtube">
                <Embed
                  id={
                    props.data.ylink
                      ? props.data.ylink.match(/.be\//)
                        ? props.data.ylink.match(/^.*\.be\/(.*)/)[1]
                        : props.data.ylink.match(/^.*\?v=(.*)$/)[1]
                      : null
                  }
                  source="youtube"
                  icon="play"
                  placeholder={
                    props.data.ybild
                      ? props.data?.ybild[0]?.['@id']
                        ? getTeaserImageURL(props.data?.ybild[0])
                        : null
                      : null
                  }
                  autoplay
                />
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Grid className="socialMedia">
          <Grid.Row>
            <Grid.Column className="social-media">
              <div className="icon-container">
                <a href={`${props.data.termine}`}>
                  <Icon name={termineSvg} size="32px" />
                  <div>Termine</div>
                </a>
                <a href={`${props.data.mastodon}`}>
                  <Icon name={mastodonSVG} size="32px" />
                  <div>Mastodon</div>
                </a>
                <a href={`${props.data.linkedin}`}>
                  <Icon name={linkedinSVG} size="32px" />
                  <div>Linkedin</div>
                </a>

                <a href={`${props.data.instagram}`}>
                  <Icon name={instagramSVG} size="32px" />
                  <div>INSTAGRAM</div>
                </a>
                <a href={`${props.data.threads}`}>
                  <Icon name={threadsSVG} size="32px" />
                  <div>Threads</div>
                </a>
                <a href={`${props.data.twitter}`}>
                  <Icon name={xSVG} size="32px" />
                  <div>X</div>
                </a>
                <a href={`${props.data.effzet}`}>
                  <Icon name={magazineSVG} size="32px" />
                  <div>EFFZETT</div>
                </a>
                <a href={`${props.data.newsletter}`}>
                  <Icon name={newsletterSvg} size="32px" />
                  <div>Newsletter</div>
                </a>
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    </>
  );
};

export default SocialMediaView;
