import { defineMessages } from 'react-intl';

const messages = defineMessages({
  introductionBlock: {
    id: 'IntroductionBlock',
    defaultMessage: 'Introduction Block',
  },
  title: {
    id: 'title',
    defaultMessage: 'title',
  },
  topics: {
    id: 'ResearchTopics',
    defaultMessage: 'Research Topics',
  },
  leader: {
    id: 'ResearchGroupLeader',
    defaultMessage: 'Research Group Leader Profile',
  },
});

export const IntroductionSchema = (props) => ({
  title: props.intl.formatMessage(messages.introductionBlock),
  block: 'introduction',
  fieldsets: [
    {
      id: 'default',
      title: 'Default',
      fields: ['heading', 'href'],
    },
  ],

  properties: {
    heading: {
      title: props.intl.formatMessage(messages.title),
    },
    href: {
      title: props.intl.formatMessage(messages.leader),
      widget: 'object_browser',
      mode: 'link',
      allowExternals: false,
    },
  },
  required: [],
});
