import React from 'react';
import { Grid } from 'semantic-ui-react';
import TeaserBody from './Body';
import { ListingBlockBody as ListingBody } from '@plone/volto/components';
import { getParentUrl } from '@plone/volto/helpers';

const AktuellesView = (props) => {
  const path = props.isEditMode ? getParentUrl(props.pathname) : props.path;
  const data1 = {
    title: props.data.title,
    href: props.data.href ? props.data.href?.[0] : null,
    description: props.data.description,
    image: props.data.preview_image?.[0],
    openLinkInNewTab: props.data.openLinkInNewTab,
    head_title: props.data.head_title,
  };
  const data2 = {
    title: props.data.ttitle,
    href: props.data.thref ? props.data.thref[0] : null,
    description: props.data.tdescription,
    image: props.data.tpreview_image?.[0],
    openLinkInNewTab: props.data.topenLinkInNewTab,
    head_title: props.data.thead_title,
  };
  const listingData = {
    query: props.data.querystring,
    limit: props.data.limit,
    sort_on: props.data.sort_on,
    sort_order: props.data.reversedOrder,
    path: path,
    block: 'aktuelleslisting',
    template: 'aktuelles',
  };
  return (
    <div className="aktuelles-view full-width">
      {props.data.headline && (
        <h2 className="headline">{props.data.headline}</h2>
      )}
      <div className="ui container">
        <Grid stackable columns={3}>
          <Grid.Row>
            <Grid.Column>
              <TeaserBody data={data1} isEditMode={props.isEditMode} />
            </Grid.Column>
            <Grid.Column>
              <TeaserBody data={data2} isEditMode={props.isEditMode} />
            </Grid.Column>
            <Grid.Column>
              <ListingBody
                data={listingData}
                block={'aktuelleslisting'}
                path={path}
                isEditMode={props.isEditMode}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    </div>
  );
};

export default AktuellesView;
