import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { flattenToAppURL } from '@plone/volto/helpers';
import { Icon, UniversalLink } from '@plone/volto/components';

import calendarSVG from '@package/icons/calendar.svg';
import memberSVG from '@package/icons/fallback-avatar.svg';
import externalLinkSVG from '@package/icons/external-link.svg';

const mapMonth = (month) =>
  ({
    ['01' || '1' || 'Januar']: 'January',
    ['02' || '2' || 'Februar']: 'February',
    ['03' || '3' || 'März']: 'March',
    ['04' || '4']: 'April',
    ['05' || '5' || 'Mai']: 'May',
    ['06' || '6' || 'Juni']: 'June',
    ['07' || '7' || 'Juli']: 'July',
    ['08' || '8']: 'August',
    ['09' || '9']: 'September',
    ['10' || 'Oktober']: 'October',
    '11': 'November',
    ['12' || 'Dezember']: 'December',
  }[month] || month);

const FundingProvider = (props) => {
  const { provider, link } = props;

  return (
    <div className="provider">
      <div className="icon-wrapper">
        <Icon name={externalLinkSVG} size="14px" />
      </div>
      {link ? (
        <UniversalLink href={link} alt="">
          {provider}
        </UniversalLink>
      ) : (
        <span>{provider}</span>
      )}
    </div>
  );
};

const ProjectListing = ({ items, isEditMode }) => {
  const intl = useIntl();
  return (
    <div className="project listing">
      {items.map((item) => {
        const startMonth = item?.project_start_month
          ? mapMonth(item.project_start_month)
          : '';
        const endMonth = item?.project_end_month
          ? mapMonth(item.project_end_month)
          : '';
        const duration = `${startMonth ? startMonth : ''}${startMonth && ' '}${
          item?.project_start_year ? item.project_start_year : ''
        }${
          item?.project_end_year && (endMonth || item?.project_end_year)
            ? intl.locale === 'de'
              ? ' bis'
              : ' until'
            : ''
        }${(item?.project_end_year || endMonth) && ' '}${
          endMonth ? endMonth : ''
        }${endMonth && ' '}${
          item?.project_end_year ? item.project_end_year : ''
        }`;

        return (
          <div className="item">
            <div className="left column logo">
              {item.image_field && (
                <img
                  src={flattenToAppURL(
                    `${item['@id']}/@@images/${item.image_field}/preview`,
                  )}
                  alt=""
                />
              )}
            </div>
            <div className="right column content">
              <div className="top">
                <div className="left">
                  {item?.project_type && (
                    <div className="topline">
                      <span className="type">{item?.project_type}</span>
                    </div>
                  )}
                  {!isEditMode ? (
                    <Link to={item['@id']} alt="">
                      <h2>
                        {`${item.title}${item?.description && ' — '}${
                          item?.description && item.description
                        }`}
                      </h2>
                    </Link>
                  ) : (
                    <h2>
                      {`${item.title}${item?.description && ' — '}${
                        item?.description && item.description
                      }`}
                    </h2>
                  )}
                  <div className="duration">
                    <div className="icon-wrapper calendar">
                      <Icon name={calendarSVG} size="15px" />
                    </div>
                    {duration && (
                      <div className="time">
                        <span>{duration}</span>
                      </div>
                    )}
                  </div>
                  {item?.member_fullnames && item.member_fullnames.length > 0 && (
                    <div className="leader">
                      <div className="icon-wrapper member">
                        <Icon name={memberSVG} size="30px" />
                      </div>
                      <span>
                        {item.member_fullnames.slice(0, 3).map((name, i) => {
                          return `${name}${
                            i < item.member_fullnames.length - 1 ? ', ' : ''
                          }`;
                        })}
                      </span>
                    </div>
                  )}
                </div>
                <div className="right">
                  {(item?.external_funding_provider_1 ||
                    item?.external_funding_provider_2 ||
                    item?.external_funding_provider_3) && (
                    <>
                      <div className="topline">
                        <span className="funded">
                          <FormattedMessage
                            id="Funded by"
                            defaultMessage="Funded by"
                          />
                        </span>
                      </div>
                      <div className="funding">
                        {item?.external_funding_provider_1 && (
                          <FundingProvider
                            provider={item.external_funding_provider_1}
                            link={item?.external_funding_provider_1_link}
                          />
                        )}
                        {item?.external_funding_provider_2 && (
                          <FundingProvider
                            provider={item.external_funding_provider_2}
                            link={item?.external_funding_provider_2_link}
                          />
                        )}
                        {item?.external_funding_provider_3 && (
                          <FundingProvider
                            provider={item.external_funding_provider_3}
                            link={item?.external_funding_provider_3_link}
                          />
                        )}
                      </div>
                    </>
                  )}
                </div>
              </div>
              <div className="bottom">
                <p>{item?.detailed_description}</p>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default ProjectListing;
