/**
 * Add your action types here.
 * @module constants/ActionTypes
 * @example
 * export const UPDATE_CONTENT = 'UPDATE_CONTENT';
 */

export const GET_NEAREST_INSTITUT = 'GET_NEAREST_INSTITUT';
export const POST_CONTACT_EMAIL = 'POST_CONTACT_EMAIL';
export const GET_INSTITUT_LIST = 'GET_INSTITUT_LIST';
export const POST_UNLOCK_FORM = 'POST_UNLOCK_FORM';
export const CREATE_RELATIONS = 'CREATE_RELATIONS';
export const DELETE_RELATIONS = 'DELETE_RELATIONS';
export const LIST_RELATIONS = 'LIST_RELATIONS';
export const STATS_RELATIONS = 'STATS_RELATIONS';
export const REBUILD_RELATIONS = 'REBUILD_RELATIONS';
export const LINK_INTEGRITY_CHECK = 'LINK_INTEGRITY_CHECK';
export const GET_SEARCHSUGGESTIONS = 'GET_SEARCHSUGGESTIONS';
