import { POST_UNLOCK_FORM } from '@package/constants/ActionTypes';

export default function postUnlockForm(url, token) {
  return {
    type: POST_UNLOCK_FORM,
    request: {
      op: 'post',
      path: `${url}/@unlock-form`,
      data: {
        token: token,
      },
    },
  };
}
