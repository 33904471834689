import React from 'react';
import { isEmpty } from 'lodash';
import { Icon, MaybeWrap, UniversalLink } from '@plone/volto/components';
import AnfahrtSVG from '@package/icons/stickymenu/Anfahrt.svg';
import AnsprechpartnerSVG from '@package/icons/stickymenu/Ansprechpartner.svg';
import AZSVG from '@package/icons/stickymenu/AZ.svg';
import DownloadsSVG from '@package/icons/stickymenu/Downloads.svg';
import FacebookSVG from '@package/icons/stickymenu/Facebook.svg';
import InstagramSVG from '@package/icons/stickymenu/Instagram.svg';
import LupeSVG from '@package/icons/stickymenu/Lupe.svg';
import MedienspiegelSVG from '@package/icons/stickymenu/Medienspiegel.svg';
import MitarbeiterSVG from '@package/icons/stickymenu/Mitarbeiter.svg';
import PublikationenSVG from '@package/icons/stickymenu/Publikationen.svg';
import RSSSVG from '@package/icons/stickymenu/RSS.svg';
import TermineSVG from '@package/icons/stickymenu/Termine.svg';
import TwitterSVG from '@package/icons/stickymenu/Twitter.svg';
import WarenkorbSVG from '@package/icons/stickymenu/Warenkorb.svg';
import YouTubeSVG from '@package/icons/stickymenu/YouTube.svg';

const iconMap = {
  anfahrt: AnfahrtSVG,
  ansprechpartner: AnsprechpartnerSVG,
  az: AZSVG,
  downloads: DownloadsSVG,
  facebook: FacebookSVG,
  instagram: InstagramSVG,
  lexicon: AZSVG,
  lupe: LupeSVG,
  medienspiegel: MedienspiegelSVG,
  mitarbeiter: MitarbeiterSVG,
  publikationen: PublikationenSVG,
  rss: RSSSVG,
  termine: TermineSVG,
  twitter: TwitterSVG,
  warenkorb: WarenkorbSVG,
  youtube: YouTubeSVG,
};

const StickyMenu = (props) => {
  const { institut, institutsbereich, subsite } = props;

  const menu = isEmpty(subsite)
    ? isEmpty(institutsbereich)
      ? institut.sticky_menu
      : institutsbereich.sticky_menu
    : subsite.sticky_menu;

  return (
    <>
      {menu && !isEmpty(menu.items) && (
        <div className="sticky-menu">
          <ul>
            {menu.items.map((item) => (
              <React.Fragment key={item['@id']}>
                {item.icon && item.href && (
                  <li key={item['@id']} className="sticky-item">
                    <MaybeWrap
                      condition={item.href?.[0]?.['@id']}
                      href={item.href?.[0]?.['@id']}
                      as={UniversalLink}
                      title={item.title}
                    >
                      {item.icon && iconMap[item.icon] && (
                        <Icon name={iconMap[item.icon]} size="24px" />
                      )}
                    </MaybeWrap>
                  </li>
                )}
              </React.Fragment>
            ))}
          </ul>
        </div>
      )}
    </>
  );
};

export default StickyMenu;
