/**
 * Navigation reducer.
 * @module reducers/navigation/navigation
 */

import { POST_CONTACT_EMAIL } from '@package/constants/ActionTypes';

const initialState = {
  error: null,
  institut: {},
  institutsbereich: {},
  loaded: false,
  loading: false,
};

export default function contactform(state = initialState, action = {}) {
  switch (action.type) {
    case `${POST_CONTACT_EMAIL}_PENDING`:
      return {
        ...state,
        error: null,
        loaded: false,
        loading: true,
      };
    case `${POST_CONTACT_EMAIL}_SUCCESS`:
      return {
        ...state,
        error: null,
        loaded: true,
        loading: false,
      };
    case `${POST_CONTACT_EMAIL}_FAIL`:
      return {
        ...state,
        error: action.error,
        loaded: false,
        loading: false,
      };
    default:
      return state;
  }
}
