export const mapMonth = (month) =>
  ({
    ['01' || '1' || 'Januar']: 'January',
    ['02' || '2' || 'Februar']: 'February',
    ['03' || '3' || 'März']: 'March',
    ['04' || '4']: 'April',
    ['05' || '5' || 'Mai']: 'May',
    ['06' || '6' || 'Juni']: 'June',
    ['07' || '7' || 'Juli']: 'July',
    ['08' || '8']: 'August',
    ['09' || '9']: 'September',
    ['10' || 'Oktober']: 'October',
    '11': 'November',
    ['12' || 'Dezember']: 'December',
  }[month] || month);
