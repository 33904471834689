import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getQueryStringResults } from '@plone/volto/actions';
import { FormattedMessage } from 'react-intl';
import { Dimmer, Loader } from 'semantic-ui-react';
import { Icon } from '@plone/volto/components';
import { Link } from 'react-router-dom';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { flattenToAppURL } from '@plone/volto/helpers';
import circleTopSVG from '@package/icons/circle-top.svg';
import memberSVG from '@package/icons/stickymenu/Mitarbeiter.svg';
import { isEmpty } from 'lodash';

const View = (props) => {
  const { data } = props;

  // Querystring
  const path = props.path;
  const block = props.id;
  const [id, setId] = useState();
  const [type, setType] = useState();
  const [subjects, setSubjects] = useState();
  const dispatch = useDispatch();
  const sort_order = 'ascending';
  const is_geschaeftsbereich_or_stabstelle =
    data.href && data.href[0]?.is_geschaeftsbereich_or_stabstelle;

  const typemap = {
    Institutsbereich: 'institutsbereiche',
    Institut: `${
      is_geschaeftsbereich_or_stabstelle
        ? 'administrational_units'
        : 'institutes'
    }`,
    'Research Group': 'research_group',
  };

  const querystringResults = useSelector(
    (state) => state.querystringsearch.subrequests,
  );

  // Listing
  const alphabet = 'abcdefghijklmnopqrstuvwxyz'.split('');
  const [loaded, setLoaded] = useState(false);
  const [rightPos, setRightPos] = useState(0);

  // sticky scroll to top button positions
  const navPosition = useRef(0);
  const bottomPosition = useRef(0);

  // sort results alphabetically
  const sortResults = (items) => {
    return items?.sort(function (a, b) {
      return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
    });
  };

  // Filter Alphabet for linked letters in navigation bar
  const filterAlphabet = (items) => {
    let filteredAlphabet = [];
    let linkedAlphabet = [];
    let specialCharacters = [];

    let len = 26;

    items.forEach((item) => {
      let initial = item.name[0].toLowerCase();

      // treat umlaute as standart vocals
      if (initial === 'ä') {
        initial = 'a';
      } else if (initial === 'ö') {
        initial = 'o';
      } else if (initial === 'ü') {
        initial = 'u';
      }

      // filter out special characters and add them to nav bar alphabet
      if (alphabet.find((e) => e === initial)) {
        linkedAlphabet.push(initial);
      } else if (specialCharacters.indexOf(initial) === -1) {
        filteredAlphabet.push({ letter: initial, isLinked: true });
        specialCharacters.push(initial);
      }
    });

    // assemble nav bar alphabet
    alphabet.forEach((letter) => {
      if (linkedAlphabet.find((e) => e === letter)) {
        filteredAlphabet.push({ letter: letter, isLinked: true });
      } else if (letter !== 'ä' && letter !== 'ü' && letter !== 'ö') {
        filteredAlphabet.push({ letter: letter, isLinked: false });
      } else {
        len -= 1;
      }
    });

    // shift special characters to back of the list
    if (filteredAlphabet.length > len) {
      for (let i = 0; i < filteredAlphabet.length - len; i++) {
        filteredAlphabet.push(filteredAlphabet.shift());
      }
    }

    return filteredAlphabet;
  };

  // filter results by initial and group together for rendering
  const filterResults = (items) => {
    let filteredResults = [];
    let sortedItems = [];
    let spechialCharacterCount = 0;
    let lastInitial = '';

    items.forEach((item, index) => {
      let initial = item.name[0].toLowerCase();

      // treat umlaute as standart vocals
      if (initial === 'ä') {
        initial = 'a';
      } else if (initial === 'ö') {
        initial = 'o';
      } else if (initial === 'ü') {
        initial = 'u';
      }

      if (lastInitial === '') {
        lastInitial = initial;
      }

      // push group of results whenever inital changes
      if (lastInitial === initial) {
        sortedItems.push(item);
      } else {
        filteredResults.push({ initial: lastInitial, items: sortedItems });
        sortedItems = [];
        sortedItems.push(item);
        if (alphabet.indexOf(lastInitial) === -1) {
          spechialCharacterCount++;
        }
        lastInitial = initial;
      }

      // on last result push remaining object and shift special charakters to back of list
      if (index === items.length - 1) {
        filteredResults.push({ initial: initial, items: sortedItems });
        for (let i = 0; i < spechialCharacterCount; i++) {
          filteredResults.push(filteredResults.shift());
        }
      }
    });

    return filteredResults;
  };

  // querystring search for members
  useEffect(() => {
    if (data.href !== undefined) {
      setType(data.href[0]?.['@type']);
      setId(data.href[0]?.getId);
    }
    if (data.subjects !== undefined) {
      setSubjects(data.subjects);
    }

    if (type !== undefined && id !== undefined) {
      dispatch(
        getQueryStringResults(
          '',
          {
            query: [
              {
                i: 'portal_type',
                o: 'plone.app.querystring.operation.selection.any',
                v: ['Member'],
              },
              ...(type && id
                ? [
                    {
                      i: typemap[type],
                      o: 'plone.app.querystring.operation.selection.any',
                      v: [id],
                    },
                  ]
                : []),
              ...(subjects?.length > 0
                ? [
                    {
                      i: 'Subject',
                      o: 'plone.app.querystring.operation.selection.any',
                      v: subjects,
                    },
                  ]
                : []),
            ],
            sort_order,
            metadata_fields: '_all',
            sort_on: 'sortable_title',
            b_size: 1000,
          },
          block,
        ),
      ).then((response) => {
        // set scrolling boundaries for sticky scroll to top button
        if (response) {
          setLoaded(true);
          if (document.getElementById(block)) {
            navPosition.current =
              window.scrollY +
              document.getElementById(block).getBoundingClientRect().top;
            bottomPosition.current =
              window.scrollY +
              document.getElementById(block).getBoundingClientRect().bottom +
              100;
            setRightPos(
              document.getElementById(block).getBoundingClientRect().right - 77,
            );
          }
        }
      });
    } else {
      setLoaded(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    path,
    block,
    dispatch,
    sort_order,
    data.href,
    data.subjects,
    data.subjects?.length,
    type,
    id,
    subjects,
  ]);

  // fill results after querystring success and sort
  let results = [];
  if (querystringResults?.[block]) {
    results = sortResults(querystringResults?.[block]?.items);
  }

  // filter nav alphabet for links and assemble result objects for render after results got filled
  const filteredAlphabet = filterAlphabet(results);
  const filteredResults = filterResults(results);

  // Sticky scroll to top button handling
  const [scrolled, setScrolled] = useState(false);
  const [visible, setVisible] = useState('');
  const handleScroll = () => {
    if (
      navPosition.current < window.scrollY &&
      window.scrollY < bottomPosition.current - window.innerHeight
    ) {
      setScrolled(true);
      setVisible(' visible');
    } else {
      setScrolled(false);
      setVisible('');
    }
  };

  const handleResize = () => {
    if (document.getElementById(block)) {
      setRightPos(
        document.getElementById(block).getBoundingClientRect().right - 77,
      );
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let toTop = ['scroll-up'];
  if (scrolled) {
    toTop.push('scrolled');
  }

  return (
    <>
      {data && (
        <div id={block} className="block azlist">
          <div className="wrapper">
            <div className="headline-wrapper">
              <h2>{data?.title}</h2>
              <p>{data?.description}</p>
            </div>
            <div className="body-wrapper">
              <div id="azlist" className="alphabet-letters">
                <ul className="alphabet">
                  {filteredAlphabet.map((item, index) => {
                    if (item.isLinked === true) {
                      return (
                        <li>
                          <AnchorLink
                            offset="40"
                            href={'#azlist-' + item.letter}
                          >
                            <div className="linked" key={index}>
                              {item.letter}
                            </div>
                          </AnchorLink>
                        </li>
                      );
                    } else {
                      return (
                        <li key={index}>
                          <div> {item.letter}</div>
                        </li>
                      );
                    }
                  })}
                </ul>
              </div>

              <ul className="listing">
                {!isEmpty(results) &&
                  filteredResults.map((result) => (
                    <li
                      className="letter-listing"
                      key={'azlist-' + result.initial}
                    >
                      {' '}
                      <div className="letterBox">
                        <h2 id={'azlist-' + result.initial}>
                          {result.initial.toUpperCase()}
                        </h2>
                        <div className="letterLine" />
                      </div>
                      <ul className="name-listing">
                        {result.items.map((item, index) => (
                          <li>
                            <Link
                              to={flattenToAppURL(item['@id'])}
                              key={result.initial + index}
                            >
                              <div>
                                <Icon
                                  className="memberSVG"
                                  size="9px"
                                  name={memberSVG}
                                />
                                {`${item.name}, ${item.academic ?? ''} ${
                                  item.firstname
                                }`}
                              </div>
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </li>
                  ))}
              </ul>
              <AnchorLink
                title="to top"
                href={'#' + block}
                id="scroll-up"
                className={toTop.join(' ') + visible}
                style={{ left: rightPos }}
              >
                <Icon
                  className="circleTopSVG"
                  size="55px"
                  name={circleTopSVG}
                />
              </AnchorLink>
            </div>
          </div>
        </div>
      )}
      {!loaded && !props.isEditMode && (
        <div>
          <Dimmer active={!loaded} inverted>
            <Loader indeterminate size="small">
              <FormattedMessage id="loading" defaultMessage="Loading" />
            </Loader>
          </Dimmer>
        </div>
      )}
    </>
  );
};

View.displayName = 'AZList';

export default View;
